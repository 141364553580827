export const HarmonyLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_13668_3374)" />
      <path
        d="M21.3562 6.71521C19.151 6.71344 17.334 8.52939 17.3283 10.7406V15.2038C16.9135 15.2319 16.4893 15.2495 16.0496 15.2495C15.6099 15.2495 15.1879 15.2672 14.771 15.2922V10.7406C14.7359 8.54967 12.9289 6.76648 10.7436 6.76648C8.5584 6.76648 6.75136 8.54967 6.71631 10.7406V21.4077C6.75136 23.5987 8.5584 25.3819 10.7436 25.3819C12.9289 25.3819 14.7359 23.5987 14.771 21.4077V16.9446C15.1858 16.9165 15.6099 16.8988 16.0496 16.8988C16.4893 16.8988 16.9114 16.8811 17.3283 16.8562V21.4077C17.3634 23.5987 19.1704 25.3819 21.3556 25.3819C23.5409 25.3819 25.3479 23.5987 25.383 21.4077V10.7406C25.3773 8.52981 23.5609 6.71407 21.3562 6.71521V6.71521ZM10.7431 8.36447C12.0445 8.36384 13.1166 9.43565 13.12 10.7406V15.4669C11.9417 15.638 10.791 15.9643 9.69779 16.4371C9.22407 16.6456 8.77815 16.9124 8.37038 17.2316V10.7406C8.3738 9.43721 9.4433 8.36613 10.7431 8.36447V8.36447ZM13.12 21.4077C13.12 22.7153 12.0471 23.7912 10.7431 23.7912C9.43915 23.7912 8.36623 22.7153 8.36623 21.4077V20.3377C8.36623 19.3924 9.1129 18.4971 10.3646 17.9397C11.2466 17.5582 12.172 17.2868 13.12 17.1317V21.4077ZM21.3562 23.7839C20.0548 23.7845 18.9827 22.7127 18.9793 21.4077V16.6815C20.1576 16.5103 21.3082 16.1841 22.4015 15.7113C22.8752 15.5028 23.3211 15.2359 23.7289 14.9168V21.4077C23.7255 22.7111 22.656 23.7822 21.3562 23.7839V23.7839ZM21.7347 14.2024C20.8527 14.5839 19.9273 14.8553 18.9793 15.0104V10.7406C18.9793 9.43305 20.0522 8.35719 21.3562 8.35719C22.6601 8.35719 23.733 9.43305 23.733 10.7406V11.8106C23.733 12.7517 22.9864 13.6471 21.7347 14.2024V14.2024Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13668_3374"
          x1="27.3333"
          y1="5.33333"
          x2="6"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8BF0C3" />
          <stop offset="1" stopColor="#4FB3E1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
