export const BitgertLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <g clipPath="url(#clip0_13668_3627)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3835 20.7873C23.4859 20.7275 24.1429 19.9254 24.1429 19.9254V18.3041L23.3749 17.2801L21.7792 16.4097L19.424 17.7665C19.424 17.7665 18.9803 18.0907 19.3045 18.3041C19.6288 18.5174 20.5248 19.0806 20.5248 19.0806L13.4251 23.1766L13.3653 21.7259C13.3653 21.7259 13.2544 21.2481 12.8277 21.4955C12.4011 21.743 10.5579 22.7755 10.5579 22.7755L10.5664 25.4806C10.5664 25.7963 11.4112 26.6155 12.3072 26.6497C13.1947 26.6838 13.4251 26.5558 13.7237 26.4449L23.392 20.7787L23.3835 20.7873Z"
          fill="url(#paint0_linear_13668_3627)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3834 14.7115L24.1429 13.8155V12.015L23.392 11.1446L13.3397 5.33337L11.8037 5.36751C11.8037 5.36751 10.5066 5.64057 10.5152 6.66457L10.5408 9.14777L12.7936 10.4534C12.7936 10.4534 13.3397 10.7179 13.3226 10.1376C13.297 9.56591 13.3653 8.72111 13.3653 8.72111L20.5504 12.8683L13.425 16.9814V15.0016L14.3296 15.4368C14.3296 15.4368 14.6282 15.599 14.7562 15.5222C14.8842 15.4454 16.9237 14.2678 16.9237 14.2678C16.9237 14.2678 17.1797 14.0715 17.1541 13.9094C17.137 13.7472 17.009 13.6022 17.009 13.6022L10.9845 10.1206C10.9845 10.1206 10.7712 9.99257 10.609 10.0864C10.4554 10.1803 10.5578 22.127 10.5578 22.127L23.3834 14.7115Z"
          fill="url(#paint1_linear_13668_3627)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3835 20.7873C23.4859 20.7275 24.1429 19.9254 24.1429 19.9254V18.3041L23.3749 17.2801L21.7792 16.4097L19.424 17.7665C19.424 17.7665 18.9803 18.0907 19.3045 18.3041C19.6288 18.5174 20.5248 19.0806 20.5248 19.0806L13.4251 23.1766L13.3653 21.7259C13.3653 21.7259 13.2544 21.2481 12.8277 21.4955C12.4011 21.743 10.5579 22.7755 10.5579 22.7755L10.5664 25.4806C10.5664 25.7963 11.4112 26.6155 12.3072 26.6497C13.1947 26.6838 13.4251 26.5558 13.7237 26.4449L23.392 20.7787L23.3835 20.7873Z"
          fill="url(#paint2_linear_13668_3627)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3834 14.7115L24.1429 13.8155V12.015L23.392 11.1446L13.3397 5.33337L11.8037 5.36751C11.8037 5.36751 10.5066 5.64057 10.5152 6.66457L10.5408 9.14777L12.7936 10.4534C12.7936 10.4534 13.3397 10.7179 13.3226 10.1376C13.297 9.56591 13.3653 8.72111 13.3653 8.72111L20.5504 12.8683L13.425 16.9814V15.0016L14.3296 15.4368C14.3296 15.4368 14.6282 15.599 14.7562 15.5222C14.8842 15.4454 16.9237 14.2678 16.9237 14.2678C16.9237 14.2678 17.1797 14.0715 17.1541 13.9094C17.137 13.7472 17.009 13.6022 17.009 13.6022L10.9845 10.1206C10.9845 10.1206 10.7712 9.99257 10.609 10.0864C10.4554 10.1803 10.5578 22.127 10.5578 22.127L23.3834 14.7115Z"
          fill="url(#paint3_linear_13668_3627)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_13668_3627"
          x1="10.5564"
          y1="25.5256"
          x2="20.9606"
          y2="14.9684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F0AE8" />
          <stop offset="1" stopColor="#11DBB7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13668_3627"
          x1="10.341"
          y1="13.5484"
          x2="22.1571"
          y2="14.4256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8109EA" />
          <stop offset="0.4392" stopColor="#750AE8" />
          <stop offset="1" stopColor="#3510DD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_13668_3627"
          x1="10.5564"
          y1="25.5256"
          x2="20.9606"
          y2="14.9684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F0AE8" />
          <stop offset="1" stopColor="#11DBB7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_13668_3627"
          x1="10.341"
          y1="13.5484"
          x2="22.1571"
          y2="14.4256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8109EA" />
          <stop offset="0.4392" stopColor="#750AE8" />
          <stop offset="1" stopColor="#3510DD" />
        </linearGradient>
        <clipPath id="clip0_13668_3627">
          <rect
            width="21.3333"
            height="21.3333"
            fill="white"
            transform="translate(6.66675 5.33337)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
