import React, { useState } from "react";
import { css } from "@emotion/css";
import { EmptyPicture } from "../EmptyPicture";

const iconStyles = {
  base: css`
    display: inline-block;
    border-radius: 100%;
    object-fit: cover;
    overflow: hidden;
  `,
  sizes: {
    large: css`
      width: 36px;
      height: 36px;
    `,
    medium: css`
      width: 24px;
      height: 24px;
    `,
    "small-medium": css`
      width: 20px;
      height: 20px;
    `,
    small: css`
      width: 16px;
      height: 16px;
    `,
  },
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
  `,
};

interface ICryptoIconProps {
  src: string;
  size?: "large" | "medium" | "small-medium" | "small";
  networkLogo?: string;
  alt?: string;
  className?: string;
}

export const CryptoIcon = ({
  src,
  size = "large",
  alt = "icon",
  networkLogo = "",
  className = "",
}: ICryptoIconProps) => {
  const [imageError, setImageError] = useState(false);
  const sizeStyle = iconStyles.sizes[size];

  return (
    <div className={`${iconStyles.wrapper} ${sizeStyle} ${className}`}>
      {!imageError ? (
        <img
          src={src}
          alt={alt}
          className={`${iconStyles.base} ${sizeStyle}`}
          onError={() => setImageError(true)}
        />
      ) : (
        <EmptyPicture />
      )}
    </div>
  );
};
