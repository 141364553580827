import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { Layout } from "./components/Layout";
import "./App.css";
import { PortfolioPage } from "./components/pages/PortfolioPage";
import { ManageWalletsPage } from "./components/pages/ManageWalletsPage";
import { AuthorizationPage } from "./components/pages/AuthorizationPage";
import { WalletFilterPage } from "./components/pages/WalletFilterPage";
import { UserWalletProvider } from "./components/context/UserWalletContext";

import {
  AUTHORIZATION,
  MANAGE_WALLETS,
  PORTFOLIO,
  WALLETS_FILTER,
} from "./shared/constants/routes";
import { HyperLiquidPositionDetailsWrapper } from "./components/HyperLiquidPositionDetailsWrapper";
import { SelectNetworksPage } from "./components/pages/SelectNetworkPage";

// globalThis.Buffer = Buffer;

export const App: React.FC = () => {
  return (
    <UserWalletProvider>
      <Router>
        <Routes>
          <Route path={`/${AUTHORIZATION}`} element={<AuthorizationPage />} />
          <Route path={`/${WALLETS_FILTER}`} element={<WalletFilterPage />} />
          <Route path="/select-network" element={<SelectNetworksPage />} />
          <Route path="/" element={<Layout />}>
            <Route
              path={`${PORTFOLIO}/:type/:tab/:subtab`}
              element={<PortfolioPage />}
            />
            <Route path={`${MANAGE_WALLETS}`} element={<ManageWalletsPage />} />
            <Route
              path="position/:type/:name"
              element={<HyperLiquidPositionDetailsWrapper />}
            />
            <Route index element={<Navigate to={`/${AUTHORIZATION}`} />} />
          </Route>
          <Route path="*" element={<Navigate to={`/${AUTHORIZATION}`} />} />
        </Routes>
      </Router>
    </UserWalletProvider>
  );
};
