export const getFormattedPnlLikeValue = (
  value: number,
  usd: boolean,
): string => {
  if (value === 0 || value === null) {
    return usd ? "$0" : "0";
  } else if (value < 1) {
    if (value > 0 && value <= 0.01) {
      return usd ? "$0.01" : "0.01";
    }
    return usd ? "$" + value.toFixed(2) : value.toFixed(2);
  } else if (value >= 1000000000) {
    return usd
      ? "$" + `${(value / 1000000000).toFixed(1)}B`
      : `${(value / 1000000000).toFixed(1)}B`;
  } else if (value >= 1000000) {
    return usd
      ? "$" + `${(value / 1000000).toFixed(1)}M`
      : `${(value / 1000000).toFixed(1)}M`;
  } else {
    return usd
      ? "$" +
          new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(value)
      : new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(value);
  }
};
