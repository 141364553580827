export const DownArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 14.3926C9.73112 14.3926 9.48503 14.2832 9.26172 14.0645L4.11426 8.80078C4.02767 8.70964 3.96159 8.61165 3.91602 8.50684C3.875 8.40202 3.85449 8.28581 3.85449 8.1582C3.85449 7.98958 3.89551 7.83464 3.97754 7.69336C4.05957 7.55208 4.16895 7.44043 4.30566 7.3584C4.44694 7.27637 4.60189 7.23535 4.77051 7.23535C5.02572 7.23535 5.24902 7.32878 5.44043 7.51562L10.3145 12.5195H9.69238L14.5527 7.51562C14.7441 7.32878 14.9674 7.23535 15.2227 7.23535C15.3913 7.23535 15.5439 7.27637 15.6807 7.3584C15.8219 7.44043 15.9336 7.55208 16.0156 7.69336C16.0977 7.83464 16.1387 7.98958 16.1387 8.1582C16.1387 8.40885 16.0498 8.62077 15.8721 8.79395L10.7383 14.0645C10.6289 14.1738 10.5127 14.2559 10.3896 14.3105C10.2712 14.3607 10.1413 14.388 10 14.3926Z"
        fill="#C1C1C8"
        fillOpacity="0.6"
      />
    </svg>
  );
};
