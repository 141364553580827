export const OptimismLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#FF0420" />
      <path
        d="M9.59332 21.7152C8.35615 21.7152 7.34254 21.4213 6.55242 20.8337C5.77268 20.2355 5.38281 19.3856 5.38281 18.2837C5.38281 18.0528 5.40883 17.7694 5.46077 17.4336C5.59599 16.6781 5.78831 15.7704 6.0378 14.7105C6.74473 11.8247 8.56932 10.3818 11.5115 10.3818C12.312 10.3818 13.0293 10.5182 13.6635 10.791C14.2977 11.0534 14.7967 11.4522 15.1606 11.9874C15.5244 12.512 15.7065 13.1417 15.7065 13.8762C15.7065 14.0966 15.6804 14.3747 15.6284 14.7105C15.4725 15.6445 15.2853 16.5522 15.067 17.4336C14.7032 18.8713 14.0742 19.947 13.1801 20.6605C12.286 21.3636 11.0905 21.7152 9.59332 21.7152ZM9.81165 19.4485C10.3939 19.4485 10.8877 19.2753 11.2932 18.929C11.709 18.5828 12.0053 18.0528 12.182 17.3393C12.4211 16.3528 12.6031 15.4923 12.7279 14.7577C12.7694 14.5374 12.7902 14.3118 12.7902 14.0808C12.7902 13.1259 12.2964 12.6484 11.3088 12.6484C10.7265 12.6484 10.2275 12.8216 9.81165 13.1679C9.40624 13.5143 9.1151 14.0442 8.93841 14.7577C8.75125 15.4608 8.56409 16.3214 8.37701 17.3393C8.33537 17.5491 8.31459 17.7694 8.31459 18.0003C8.31459 18.9658 8.81366 19.4485 9.81165 19.4485Z"
        fill="white"
      />
      <path
        d="M16.4248 21.5577C16.3105 21.5577 16.222 21.521 16.1597 21.4475C16.1077 21.3635 16.0921 21.2691 16.1129 21.1642L18.265 10.9326C18.2857 10.8172 18.3429 10.7227 18.4365 10.6492C18.5301 10.5758 18.6288 10.5391 18.7328 10.5391H22.8809C24.0349 10.5391 24.9602 10.7804 25.6567 11.2631C26.3637 11.7459 26.7172 12.4437 26.7172 13.3567C26.7172 13.619 26.686 13.8919 26.6236 14.1752C26.3637 15.382 25.8387 16.274 25.0486 16.8512C24.2689 17.4283 23.198 17.7169 21.8361 17.7169H19.7308L19.0135 21.1642C18.9927 21.2796 18.9355 21.374 18.842 21.4475C18.7484 21.521 18.6496 21.5577 18.5457 21.5577H16.4248ZM21.9453 15.5447C22.3819 15.5447 22.7613 15.4239 23.0836 15.1826C23.4163 14.9412 23.6347 14.595 23.7386 14.1437C23.7698 13.9653 23.7854 13.8079 23.7854 13.6715C23.7854 13.3671 23.6971 13.1363 23.5203 12.9789C23.3435 12.811 23.0421 12.7271 22.6158 12.7271H20.7444L20.1519 15.5447H21.9453Z"
        fill="white"
      />
    </svg>
  );
};
