import React from "react";
import { css } from "@emotion/css";

const tabSumValueStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    margin-bottom: 4px;
  `,
  value: css`
    font-family: "Space Mono", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: #cebcff;
  `,
};

interface TabSumValueProps {
  value: string;
}

export const TabSumValue: React.FC<TabSumValueProps> = ({ value }) => {
  return (
    <div className={tabSumValueStyles.container}>
      <div className={tabSumValueStyles.value}>${value}</div>
    </div>
  );
};
