export const BitcoinLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#E9973D" />
      <path
        d="M24.0051 13.9582C24.338 11.7166 22.6436 10.5116 20.3267 9.70768L21.0783 6.67111L19.2433 6.21047L18.5116 9.16701C18.0292 9.04593 17.5338 8.9317 17.0414 8.81851L17.7784 5.84248L15.9444 5.38184L15.1924 8.41734C14.7931 8.32574 14.4011 8.2352 14.0206 8.13991L14.0227 8.13044L11.4921 7.49395L11.004 9.46814C11.004 9.46814 12.3654 9.78243 12.3367 9.80191C13.0799 9.9888 13.2142 10.4842 13.1917 10.8769L12.3356 14.3362C12.3868 14.3494 12.4532 14.3683 12.5264 14.3978C12.4653 14.3826 12.3999 14.3657 12.3325 14.3494L11.1325 19.1954C11.0416 19.4228 10.8111 19.7639 10.2916 19.6344C10.3099 19.6613 8.95784 19.2991 8.95784 19.2991L8.04688 21.4149L10.4348 22.0145C10.879 22.1266 11.3144 22.244 11.743 22.3546L10.9836 25.4259L12.8165 25.8865L13.5685 22.8479C14.0692 22.9847 14.5553 23.1111 15.0309 23.2301L14.2814 26.2545L16.1164 26.7152L16.8758 23.6497C20.0048 24.2461 22.3577 24.0055 23.3481 21.1548C24.1462 18.8595 23.3084 17.5355 21.6621 16.6721C22.861 16.3936 23.7641 15.5992 24.0051 13.9582V13.9582ZM19.8125 19.8803C19.2454 22.1756 15.4087 20.9347 14.1649 20.6236L15.1725 16.5547C16.4164 16.8674 20.4051 17.4865 19.8125 19.8803ZM20.38 13.9251C19.8626 16.013 16.6693 14.9522 15.6335 14.6921L16.547 11.0017C17.5829 11.2618 20.9189 11.7471 20.38 13.9251Z"
        fill="white"
      />
    </svg>
  );
};
