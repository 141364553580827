import React from "react";
import {WalletDropdown} from "./WalletDropDown";
import {Assets} from "./Assets";
import {IUserWallet} from "../shared/intefaces/IUserWallet";
import {css} from "@emotion/css";

const walletsContentStyles = {
    dropDownContainer: css`
        background: #1C1C1C;
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 24px;
    `,
};

interface WalletsContentProps {
    wallets: IUserWallet[];
    selectedWallet: IUserWallet | null;
}


export const WalletsContent: React.FC<WalletsContentProps> = ({
                                                                  wallets,
                                                                  selectedWallet,
                                                              }) => {

    return (
        <div>
            <div className={walletsContentStyles.dropDownContainer}>
                <WalletDropdown
                    selectedWallet={selectedWallet}
                />
            </div>
            <Assets wallets={wallets}/>
        </div>
    );
};
