import React from "react";
import { css } from "@emotion/css";
import { getNetworkDetails, NetworkKey } from "./networks";

const styles = {
  container: css`
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
  iconWrapper: css`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

interface NetworkInfoProps {
  chain: string;
  isSelector?: boolean;
}

export const NetworkInfo: React.FC<NetworkInfoProps> = ({
  chain,
  isSelector = false,
}) => {
  const network = getNetworkDetails(chain as NetworkKey);

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>{network.icon}</div>
      <span>{isSelector ? network.name : network.symbol}</span>
    </div>
  );
};
