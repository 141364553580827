import React from "react";
import { css } from "@emotion/css";
import { CryptoIcon } from "./uiKit/CryptoIcon";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { EmptyPicture } from "./EmptyPicture";
import { IPosition } from "../shared/intefaces/IProtocol";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: "Open Sans", sans-serif;
    border-radius: 12px;
    padding: 12px;
    border: 1px solid rgba(124, 124, 124, 0.32);
  `,
  header: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  imageWrapper: css`
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
  `,
  tag: css`
    display: flex;
    padding: 2px 4px;
    border-radius: 2px;
    background: #1c1c1c;
    font-size: 10px;
    color: #c1c1c899;
    align-items: center;
    height: 16px;
  `,
  name: css`
    font-size: 16px;
    color: #ffffff;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    text-overflow: ellipsis;
    align-items: center;
    gap: 6px;
  `,
  value: css`
    display: flex;
    color: rgba(193, 193, 200, 0.6);
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
    gap: 8px;
  `,
  valueUsd: css`
    color: #fff;
    font-feature-settings:
      "liga" off,
      "clig" off;
    /* Body/Body2_Bold */
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
  `,
  positionBlock: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  positionTitle: css`
    color: #fff;
    font-feature-settings:
      "liga" off,
      "clig" off;
    /* Body/Body2_Bold */
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
  `,
  innerCard: css`
    display: flex;
    flex-direction: column;
    background: #181818;
    border-radius: 8px;
    padding: 12px;
    gap: 12px;
  `,
  sectionLabel: css`
    color: rgba(193, 193, 200, 0.6);
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
  `,
  tokenRow: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  tokenInfo: css`
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
  `,
  amount: css`
    font-size: 14px;
  `,
  usd: css`
    overflow: hidden;
    color: rgba(193, 193, 200, 0.6);
    text-align: right;
    font-feature-settings:
      "liga" off,
      "clig" off;
    text-overflow: ellipsis;
    /* Body/Body2 */
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
  `,
};

interface IProtocolInfoProps {
  protocolLogo: string;
  networkLogo: string;
  protocolName: string;
  tag: string;
  value: number;
  positions: IPosition[];
}

export const ProtocolInfo: React.FC<IProtocolInfoProps> = ({
  protocolLogo,
  networkLogo,
  protocolName,
  tag,
  value,
  positions,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div style={{ position: "relative" }}>
          {protocolLogo ? (
            <CryptoIcon src={protocolLogo} size="large" />
          ) : (
            <div className={styles.imageWrapper}>
              <EmptyPicture />
            </div>
          )}
          <CryptoIcon
            src={networkLogo}
            size="small-medium"
            className={css`
              position: absolute;
              bottom: -4px;
              right: -4px;
              border: 1px solid #0f0f0f;
            `}
          />
        </div>
        <div className={styles.name}>
          {protocolName} {tag && <span className={styles.tag}>{tag}</span>}
        </div>
      </div>
      <div className={styles.value}>
        Value:
        <div className={styles.valueUsd}>{getFormattedValue(value, true)}</div>
      </div>

      {positions.map((position, index) => (
        <div key={index} className={styles.positionBlock}>
          {position.type && (
            <div className={styles.positionTitle}>{position.type}</div>
          )}

          {(position.tokens.length > 0 ||
            position.loans.length > 0 ||
            position.rewards.length > 0) && (
            <div className={styles.innerCard}>
              {position.tokens.length > 0 && (
                <>
                  <div className={styles.sectionLabel}>Assets</div>
                  {position.tokens.map((token, i) => (
                    <div className={styles.tokenRow} key={`token-${i}`}>
                      <div className={styles.tokenInfo}>
                        <CryptoIcon
                          src={token.logo_url || ""}
                          size="small-medium"
                        />
                        <div className={styles.amount}>
                          {getFormattedValue(token.amount, false)}{" "}
                          {(token.symbol || "").toUpperCase()}
                        </div>
                      </div>
                      <div className={styles.usd}>
                        {getFormattedValue(token.value_usd, true)}
                      </div>
                    </div>
                  ))}
                </>
              )}

              {position.loans.length > 0 && (
                <>
                  <div className={styles.sectionLabel}>Loans</div>
                  {position.loans.map((token, i) => (
                    <div className={styles.tokenRow} key={`loan-${i}`}>
                      <div className={styles.tokenInfo}>
                        <CryptoIcon
                          src={token.logo_url || ""}
                          size="small-medium"
                        />
                        <div className={styles.amount}>
                          {getFormattedValue(token.amount, false)}{" "}
                          {(token.symbol || "").toUpperCase()}
                        </div>
                      </div>
                      <div className={styles.usd}>
                        {getFormattedValue(token.value_usd, true)}
                      </div>
                    </div>
                  ))}
                </>
              )}

              {position.rewards.length > 0 && (
                <>
                  <div className={styles.sectionLabel}>Rewards</div>
                  {position.rewards.map((token, i) => (
                    <div className={styles.tokenRow} key={`reward-${i}`}>
                      <div className={styles.tokenInfo}>
                        <CryptoIcon
                          src={token.logo_url || ""}
                          size="small-medium"
                        />
                        <div className={styles.amount}>
                          {getFormattedValue(token.amount, false)}{" "}
                          {(token.symbol || "").toUpperCase()}
                        </div>
                      </div>
                      <div className={styles.usd}>
                        {getFormattedValue(token.value_usd, true)}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
