import { TonLogo } from "./icons/networks/TonLogo";
import { EvmLogo } from "./icons/networks/EvmLogo";
import { PolygonLogo } from "./icons/networks/PolygonLogo";
import { OptimismLogo } from "./icons/networks/OptimismLogo";
import { AvalancheLogo } from "./icons/networks/AvalancheLogo";
import { BscLogo } from "./icons/networks/BscLogo";
import { ArbitrumLogo } from "./icons/networks/ArbitrumLogo";
import { GnosisLogo } from "./icons/networks/GnosisLogo";
import { FantomLogo } from "./icons/networks/FantomLogo";
import { MoonBeamLogo } from "./icons/networks/MoonBeamLogo";
import { MoonRiverLogo } from "./icons/networks/MoonRiver";
import { ArbtrumNovaLogo } from "./icons/networks/ArbtrumNovaLogo";
import { HarmonyLogo } from "./icons/networks/HarmonyLogo";
import { CeloLogo } from "./icons/networks/CeloLogo";
import { BobaLogo } from "./icons/networks/BobaLogo";
import { CronosLogo } from "./icons/networks/CronosLogo";
import { HecoLogo } from "./icons/networks/HecoLogo";
import { AuroraLogo } from "./icons/networks/AuroraLogo";
import { MetisLogo } from "./icons/networks/MetisLogo";
import { BItTorrentLogo } from "./icons/networks/BItTorrentLogo";
import { RskLogo } from "./icons/networks/RskLogo";
import { WanchainLogo } from "./icons/networks/WanchainLogo";
import { KccLogo } from "./icons/networks/KccLogo";
import { SongBirdLogo } from "./icons/networks/SongBirdLogo";
import { EvmosLogo } from "./icons/networks/EvmosLogo";
import { DfkLogo } from "./icons/networks/DfkLogo";
import { TelosLogo } from "./icons/networks/TelosLogo";
import { SwimmerLogo } from "./icons/networks/SwimmerLogo";
import { CantoLogo } from "./icons/networks/CantoLogo";
import { DogeChainLogo } from "./icons/networks/DogeChainLogo";
import { KavaLogo } from "./icons/networks/KavaLogo";
import { StepLogo } from "./icons/networks/StepLogo";
import { MikomedaLogo } from "./icons/networks/MikomedaLogo";
import { ConfluxLogo } from "./icons/networks/ConfluxLogo";
import { BitgertLogo } from "./icons/networks/BitgertLogo";
import { AstarLogo } from "./icons/networks/AstarLogo";
import { ShidenLogo } from "./icons/networks/ShidenLogo";
import { KlaytnLogo } from "./icons/networks/KlaytnLogo";
import { SmartBchLogo } from "./icons/networks/SmartBchLogo";
import { FuseLogo } from "./icons/networks/FuseLogo";
import { OecLogo } from "./icons/networks/OecLogo";
import { SolanaLogo } from "./icons/networks/SolanaLogo";
import { BitcoinLogo } from "./icons/networks/BitcoinLogo";
import { BaseLogo } from "./icons/networks/BaseLogo";
import { LineaLogo } from "./icons/networks/LineaLogo";
import { OpbnbLogo } from "./icons/networks/OpbnbLogo";
import { PalmLogo } from "./icons/networks/PalmLogo";
import { IotexLogo } from "./icons/networks/IotexLogo";

export type NetworkKey = string;

export const NETWORKS: Record<
  string,
  { name: string; symbol?: string; icon: JSX.Element }
> = {
  evm: {
    name: "EVM",
    symbol: "EVM",
    icon: <EvmLogo />,
  },
  ton: {
    name: "TON",
    symbol: "TON",
    icon: <TonLogo />,
  },
  solana: {
    name: "Solana",
    symbol: "SOL",
    icon: <SolanaLogo />,
  },
  bitcoin: {
    name: "Bitcoin",
    symbol: "BTC",
    icon: <BitcoinLogo />,
  },
  ethereum: {
    name: "Ethereum",
    icon: <EvmLogo />,
  },
  polygon: {
    name: "Polygon",
    icon: <PolygonLogo />,
  },
  bsc: {
    name: "BSC",
    icon: <BscLogo />,
  },
  arbitrum: {
    name: "Arbitrum",
    icon: <ArbitrumLogo />,
  },
  optimism: {
    name: "Optimism",
    icon: <OptimismLogo />,
  },
  base: {
    name: "Base",
    icon: <BaseLogo />,
  },
  avalanche: {
    name: "Avalanche",
    icon: <AvalancheLogo />,
  },
  gnosis: {
    name: "Gnosis",
    icon: <GnosisLogo />,
  },
  fantom: {
    name: "Fantom",
    icon: <FantomLogo />,
  },
  linea: {
    name: "Linea",
    icon: <LineaLogo />,
  },
  opbnb: {
    name: "OpBNB",
    icon: <OpbnbLogo />,
  },
  moonbeam: {
    name: "Moonbeam",
    icon: <MoonBeamLogo />,
  },
  moonriver: {
    name: "Moonriver",
    icon: <MoonRiverLogo />,
  },
  arbitrum_nova: {
    name: "Arbitrum Nova",
    icon: <ArbtrumNovaLogo />,
  },
  harmony: {
    name: "Harmony",
    icon: <HarmonyLogo />,
  },
  celo: {
    name: "Celo",
    icon: <CeloLogo />,
  },
  boba: {
    name: "Boba",
    icon: <BobaLogo />,
  },
  cronos: {
    name: "Cronos",
    icon: <CronosLogo />,
  },
  heco: {
    name: "Heco",
    icon: <HecoLogo />,
  },
  aurora: {
    name: "Aurora",
    icon: <AuroraLogo />,
  },
  metis: {
    name: "Metis",
    icon: <MetisLogo />,
  },
  bittorrent: {
    name: "BitTorrent",
    icon: <BItTorrentLogo />,
  },
  rsk: {
    name: "RSK",
    icon: <RskLogo />,
  },
  wanchain: {
    name: "Wanchain",
    icon: <WanchainLogo />,
  },
  kcc: {
    name: "KCC",
    icon: <KccLogo />,
  },
  songbird: {
    name: "Songbird",
    icon: <SongBirdLogo />,
  },
  evmos: {
    name: "Evmos",
    icon: <EvmosLogo />,
  },
  dfk: {
    name: "DFK",
    icon: <DfkLogo />,
  },
  telos: {
    name: "Telos",
    icon: <TelosLogo />,
  },
  swimmer: {
    name: "Swimmer",
    icon: <SwimmerLogo />,
  },
  canto: {
    name: "Canto",
    icon: <CantoLogo />,
  },
  dogechain: {
    name: "Dogechain",
    icon: <DogeChainLogo />,
  },
  kava: {
    name: "Kava",
    icon: <KavaLogo />,
  },
  step: {
    name: "Step",
    icon: <StepLogo />,
  },
  milkomeda: {
    name: "Milkomeda",
    icon: <MikomedaLogo />,
  },
  conflux: {
    name: "Conflux",
    icon: <ConfluxLogo />,
  },
  bitgert: {
    name: "Bitgert",
    icon: <BitgertLogo />,
  },
  astar: {
    name: "Astar",
    icon: <AstarLogo />,
  },
  shiden: {
    name: "Shiden",
    icon: <ShidenLogo />,
  },
  klaytn: {
    name: "Klaytn",
    icon: <KlaytnLogo />,
  },
  smartbch: {
    name: "SmartBCH",
    icon: <SmartBchLogo />,
  },
  fuse: {
    name: "Fuse",
    icon: <FuseLogo />,
  },
  oec: {
    name: "OEC",
    icon: <OecLogo />,
  },
  palm: {
    name: "Palm",
    icon: <PalmLogo />,
  },
  iotex: {
    name: "IoTeX",
    icon: <IotexLogo />,
  },
};

export const getNetworkDetails = (chainId: NetworkKey) => {
  return NETWORKS[chainId];
};

export const getNetworks = () => {
  return Object.keys(NETWORKS).filter((x) => x !== "evm");
};
