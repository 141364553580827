export const AuroraLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#2C2C2C" />
      <path
        d="M26.586 18.6503L16.3895 26.3017C16.2544 26.4023 16.0563 26.4114 15.9211 26.3109L5.56256 18.8789C5.3644 18.7326 5.31936 18.4401 5.48149 18.2481L15.6689 5.85233C15.8131 5.6695 16.0833 5.6695 16.2364 5.85233L26.64 18.175C26.7571 18.3213 26.7391 18.5407 26.586 18.6503Z"
        fill="white"
      />
      <path
        d="M26.0369 18.3943L16.3449 25.6617L16.2908 18.2572L16.2188 6.62927L25.8117 18.1201L26.0369 18.3943Z"
        fill="#45706B"
      />
      <path
        d="M5.95825 18.5497L15.9025 25.6617L15.6773 6.62927L5.95825 18.5497Z"
        fill="#6AB8B0"
      />
      <path
        d="M15.8214 18.2754L6.03031 18.4674L5.95825 18.5496L15.9025 25.6617L15.8214 18.2754Z"
        fill="#51A0A2"
      />
      <path
        d="M16.2917 18.2572L25.8126 18.1201L26.0378 18.3944L16.3458 25.6618L16.2917 18.2572Z"
        fill="#2C2C2C"
      />
      <path
        d="M23.4787 20.2229C23.5598 20.104 24.5686 18.5957 23.9471 17.1788C23.4877 16.1275 22.3618 15.643 22.1906 15.5699C21.9655 15.4693 21.9565 15.4968 20.9026 15.1494C20.263 14.93 19.9388 14.8294 19.6595 14.7197C19.2272 14.5552 18.3895 14.2261 17.4347 13.6045C17.1194 13.4034 16.7141 13.1108 16.2547 12.7269M16.2908 18.1661L25.8116 18.0289L16.2908 18.1661ZM15.8224 18.1843L6.03125 18.3763L15.8224 18.1843ZM15.7773 14.9391C13.6696 13.3028 12.7778 13.0468 12.4446 13.3211C11.5258 14.0981 14.9306 19.1533 13.5705 20.424C13.1742 20.7896 12.3545 20.8719 10.7782 21.0273C10.2918 21.073 9.88645 21.0913 9.62523 21.0913L15.7773 14.9391ZM19.5064 18.1203L16.2727 15.2499L19.5064 18.1203ZM14.138 8.43042C14.1109 8.87835 14.129 9.76507 14.5793 10.7432C14.9396 11.5294 15.435 12.0413 15.7503 12.3247L14.138 8.43042Z"
        stroke="#A5C9C2"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
