import React from "react";
import { css } from "@emotion/css";
import { NFTInfo } from "./NftInfo";
import { INFT } from "../shared/intefaces/INFT";
import { EmptyState } from "./EmptyState";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { sumArray } from "../shared/utils/sumArray";
import { TotalBalance } from "./TotalBalance";

const nftListStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: white;
    padding-bottom: 60px;
  `,
  valueContainer: css`
    display: flex;
    margin-bottom: 24px;
  `,
  valueText: css`
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: -0.43px;
    color: #c1c1c899;
    margin-right: 8px;
  `,
};

interface NFTListProps {
  data: INFT[];
}

export const NFTList: React.FC<NFTListProps> = ({ data }) => {
  return (
    <div>
      {data.length > 0 && (
        <div className={nftListStyles.valueContainer}>
          <div className={nftListStyles.valueText}>{"Value:"}</div>
          <TotalBalance
            value={getFormattedValue(sumArray(data.map((x) => x.value)), true)}
            size={"small"}
          />
        </div>
      )}
      <div className={nftListStyles.container}>
        {data.map((nft, index) => (
          <NFTInfo
            nftImage={nft.image}
            nftName={nft.name}
            chainImage={nft.chainLogo}
            price={nft.price}
            balance={nft.amount}
            value={nft.value}
            tag={nft.walletName}
            key={index}
          />
        ))}
        {data.length === 0 && <EmptyState />}
      </div>
    </div>
  );
};
