export const AvalancheLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#E84142" />
      <path
        d="M5.98943 22.9378L15.0336 6.45096C15.3025 5.96089 15.9936 5.92654 16.3097 6.38753L18.8433 10.0823C19.4833 11.0156 19.5436 12.2296 18.9994 13.2218L13.2737 23.6592C13.142 23.8993 12.8899 24.0485 12.6161 24.0485H6.64699C6.0769 24.0485 5.71525 23.4376 5.98943 22.9378Z"
        fill="white"
      />
      <path
        d="M20.9436 16.2879L17.1211 22.9242C16.8331 23.4242 17.194 24.0485 17.771 24.0485H25.4159C25.993 24.0485 26.3538 23.4242 26.0658 22.9242L22.2434 16.2879C21.9549 15.787 21.2321 15.787 20.9436 16.2879Z"
        fill="white"
      />
    </svg>
  );
};
