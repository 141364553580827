export const CronosLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13668_3437)">
        <circle cx="16" cy="16" r="16" fill="black" />
        <path
          d="M16.053 5.38184L6.71606 10.717V21.3836L16.053 26.7152L25.3827 21.3836V10.717L16.053 5.38184ZM22.6185 19.8031L16.053 23.5541L9.48391 19.8031V12.2939L16.053 8.54286L22.6185 12.2939V19.8031Z"
          fill="white"
        />
        <path
          d="M20.4078 18.5408L16.0501 21.0296L11.6887 18.5408V13.5597L16.0501 11.0674L20.4078 13.5597L18.5939 14.5967L16.0501 13.1414L13.5062 14.5967V17.5003L16.0501 18.9556L18.5939 17.5003L20.4078 18.5408Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13668_3437">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
