import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { Tabs } from "./uiKit/Tabs";
import { TotalBalance } from "./TotalBalance";
import { fetchWalletData } from "../shared/utils/fetchWalletData";
import { IUserWallet } from "../shared/intefaces/IUserWallet";
import { useUserWallets } from "./context/UserWalletContext";
import { CryptocurrencyList } from "./CryptocurrencyList";
import { ProtocolList } from "./ProtocolList";
import { NFTList } from "./NFTList";
import { getFormattedValue } from "../shared/utils/getFormattedValue";

const assetsStyles = {
  container: css``,
  tabsContainer: css`
    margin-bottom: 24px;
  `,
  loading: css`
    font-family: "Space Mono", sans-serif;
    margin-top: 25vh;
    text-align: center;
  `,
};

interface AssetsProps {
  wallets: IUserWallet[];
}

export const Assets: React.FC<AssetsProps> = ({ wallets }) => {
  const { token } = useUserWallets();
  const [activeTab, setActiveTab] = useState(0);
  const [cryptocurrencyData, setCryptocurrencyData] = useState([]);
  const [protocolsData, setProtocolsData] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [totalValue, setTotalValue] = useState(0); // Суммарное значение totalValue
  const [loading, setLoading] = useState(true);

  const sortByValueDescending = (items: any[]) =>
    items.sort((a, b) => (b.value || 0) - (a.value || 0)); // Учитывает отсутствие поля `value`.

  useEffect(() => {
    const fetchAllAssets = async () => {
      if (token) {
        setLoading(true);
        try {
          const allWalletData = await Promise.all(
            wallets.map((wallet) =>
              fetchWalletData(wallet, wallet.chain, token),
            ),
          );

          const tokens: any = allWalletData.flatMap(
            (data) => data.tokens || [],
          );
          const protocols: any = allWalletData.flatMap(
            (data) => data.protocols || [],
          );
          const nfts: any = allWalletData.flatMap((data) => data.nfts || []);

          const total = allWalletData.reduce(
            (sum, data) => sum + (data.totalValue || 0),
            0,
          );

          setCryptocurrencyData(tokens);
          setProtocolsData(protocols);
          setNftData(nfts);
          setTotalValue(total);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    };

    if (wallets.length > 0) {
      fetchAllAssets();
    }
  }, [wallets, token]);

  return (
    <div className={assetsStyles.container}>
      <TotalBalance value={loading ? "..." : getFormattedValue(totalValue)} />
      <div className={assetsStyles.tabsContainer}>
        <Tabs
          tabs={[
            { label: "Cryptocurrency" },
            { label: "Protocols" },
            { label: "NFT" },
          ]}
          activeIndex={activeTab}
          onTabChange={setActiveTab}
          variant={"secondary"}
        />
      </div>
      {loading && <div className={assetsStyles.loading}>Loading....</div>}
      {!loading && activeTab === 0 && (
        <CryptocurrencyList data={sortByValueDescending(cryptocurrencyData)} />
      )}
      {!loading && activeTab === 1 && (
        <ProtocolList data={sortByValueDescending(protocolsData)} />
      )}
      {!loading && activeTab === 2 && (
        <NFTList data={sortByValueDescending(nftData)} />
      )}
    </div>
  );
};
