export const DropDownCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.68039 15C8.27736 15 7.9415 14.8614 7.67281 14.5843L4.31853 11.073C4.20152 10.9569 4.11918 10.8446 4.07151 10.736C4.02384 10.6273 4 10.515 4 10.3989C4 10.133 4.10184 9.91386 4.30553 9.74157C4.51354 9.56554 4.77356 9.47753 5.08559 9.47753C5.43229 9.47753 5.72048 9.59925 5.95016 9.8427L8.65439 12.736L13.9653 5.48315C14.0997 5.30712 14.2384 5.18352 14.3814 5.11236C14.5287 5.03745 14.7086 5 14.9209 5C15.2329 5 15.4908 5.08614 15.6945 5.25843C15.8982 5.43071 16 5.64794 16 5.91011C16 6.00749 15.9805 6.10861 15.9415 6.21348C15.9025 6.31835 15.8418 6.42697 15.7595 6.53933L9.70748 14.5449C9.46912 14.8483 9.12676 15 8.68039 15Z"
        fill="#CEBCFF"
      />
    </svg>
  );
};
