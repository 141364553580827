export const HecoLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#419148" />
      <path
        d="M11.4877 10.2049C11.5002 10.1973 11.5136 10.1912 11.5275 10.1866C11.675 10.1169 11.8246 10.0509 11.9732 9.99239V7.02717C11.9735 6.76816 11.8721 6.51961 11.6914 6.33611C11.5106 6.15261 11.2653 6.04915 11.0091 6.04846H7.01172V14.5597C7.98788 12.6485 9.56156 11.1175 11.4877 10.2049Z"
        fill="white"
      />
      <path
        d="M15.5589 15.3884H16.4503C16.7578 15.3885 17.0624 15.3274 17.3465 15.2084C17.6306 15.0895 17.8888 14.9151 18.1062 14.6952C18.3237 14.4753 18.4962 14.2142 18.6138 13.9268C18.7314 13.6395 18.7918 13.3315 18.7917 13.0205V10.4851C18.3095 10.4009 16.106 10.3532 15.595 10.3532C13.4165 10.3518 11.3186 11.1864 9.7249 12.6886C8.13124 14.1908 7.16054 16.2486 7.00881 18.4465C6.99443 18.6482 6.98706 18.8495 6.98706 19.0549C6.98706 19.2604 6.99443 19.4617 7.00881 19.6634V25.0737C7.00876 25.2018 7.03367 25.3286 7.0821 25.4469C7.13053 25.5652 7.20154 25.6727 7.29107 25.7633C7.3806 25.8538 7.4869 25.9256 7.60388 25.9746C7.72087 26.0236 7.84625 26.0488 7.97287 26.0487H11.971V19.0061C11.9733 18.0455 12.3524 17.125 13.0252 16.4469C13.698 15.7688 14.6094 15.3885 15.5592 15.3895L15.5589 15.3884Z"
        fill="white"
      />
      <path
        d="M20.6132 21.8902C20.6007 21.8978 20.5873 21.9039 20.5734 21.9085C20.4259 21.9782 20.2763 22.0442 20.1277 22.1027V25.0679C20.1274 25.327 20.2287 25.5755 20.4095 25.759C20.5903 25.9425 20.8356 26.046 21.0917 26.0467H25.0859V17.5358C24.11 19.4459 22.5377 20.9766 20.6132 21.8902V21.8902Z"
        fill="white"
      />
      <path
        d="M25.0876 12.4338V7.02345C25.0876 6.89539 25.0627 6.76859 25.0143 6.65028C24.9659 6.53197 24.8948 6.42447 24.8053 6.33392C24.7158 6.24337 24.6095 6.17156 24.4925 6.12258C24.3755 6.0736 24.2501 6.04841 24.1235 6.04846H20.1257V13.0896C20.1237 14.0496 19.7451 14.9696 19.0731 15.6476C18.401 16.3257 17.4905 16.7064 16.5412 16.7062H15.6461C15.3386 16.706 15.034 16.7672 14.7499 16.8861C14.4658 17.0051 14.2076 17.1795 13.9901 17.3994C13.7727 17.6193 13.6002 17.8804 13.4826 18.1678C13.365 18.4551 13.3045 18.7631 13.3047 19.0741V21.6094C13.7869 21.6937 15.9904 21.7414 16.5014 21.7414C16.5593 21.7414 16.612 21.7414 16.6717 21.7377C17.8703 21.717 19.0512 21.441 20.1371 20.9275L20.1589 20.9167C21.5476 20.2602 22.7373 19.2399 23.6045 17.9616C24.4718 16.6833 24.985 15.1936 25.0909 13.647C25.1053 13.4453 25.1126 13.244 25.1126 13.0385C25.1126 12.8331 25.102 12.6351 25.0876 12.4338Z"
        fill="white"
      />
    </svg>
  );
};
