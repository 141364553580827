export const KlaytnLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13671_3699)">
        <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_13671_3699)" />
        <mask
          id="mask0_13671_3699"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <circle cx="16" cy="16" r="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_13671_3699)">
          <path
            d="M2.66675 27.3334L26.6667 3.33337L28.6667 5.33337L4.66675 29.3334L2.66675 27.3334Z"
            fill="white"
          />
          <path
            d="M2.66675 28L14.0001 -1.33337L16.6667 -0.666707L5.33341 29.3333L2.66675 28Z"
            fill="white"
          />
          <path
            d="M29.3333 27.3333L15.9999 14L13.9999 16L27.3333 29.3333L29.3333 27.3333Z"
            fill="white"
          />
          <path
            d="M15.3333 -0.666731L-0.666748 15.3333C-0.666748 13.3333 0.133252 8.2666 3.33325 3.99994C6.53325 -0.266731 12.6666 -0.888953 15.3333 -0.666731Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_13671_3699"
          x1="25.3333"
          y1="2.66667"
          x2="9.33333"
          y2="29.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC8A04" />
          <stop offset="0.453367" stopColor="#F72E1E" />
          <stop offset="0.692128" stopColor="#D70B3C" />
          <stop offset="1" stopColor="#7D0101" />
        </linearGradient>
        <clipPath id="clip0_13671_3699">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
