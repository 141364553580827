import React, { useState } from "react";
import { css } from "@emotion/css";
import { WalletCard } from "./WalletCard";
import { Button } from "./uiKit/Button";
import { useUserWallets } from "./context/UserWalletContext";
import { walletDefaulValue } from "../shared/constants/walletDefaulValue";
import { detectNetwork, validateWallet } from "../shared/utils/validateWallet";
import {
  ADDRESS_ERROR,
  DUPLICATE_ERROR,
  NAME_EMPTY_ERROR,
  NAME_LENGTH_ERROR,
} from "../shared/constants/errors";
import { useNavigate } from "react-router-dom";
import { SmallButton } from "./uiKit/SmallButton";
import { NAME_MAX_LENGTH } from "../shared/constants/numbers";
import {
  DEFAULT_SUBTAB,
  DEFAULT_TAB,
  MY_WALLETS,
  PORTFOLIO,
  WATCHLIST,
} from "../shared/constants/routes";
import { IUserWallet } from "../shared/intefaces/IUserWallet";
import { NAVIGATION_BAR_HEIGHT } from "./Layout";

const MAX_WALLETS_COUNT = 10;

const walletManagerStyles = {
  container: css`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 240px;
  `,
  walletsList: css`
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
  `,
  fixedSaveButton: (height: number) => css`
    position: fixed;
    width: 100%;
    bottom: ${height}px;
    background: #0a0a0a;
    border-top: 1px solid #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10000;
  `,
  buttonWrapper: (height: number) => css`
    padding: 16px 16px ${height}px 16px;
  `,
};

export const WalletManager = ({ isLayout }: { isLayout: boolean }) => {
  const navigate = useNavigate();
  const {
    userWallets,
    createWallets,
    updateWallets,
    deleteWallets,
    reloadUserWallets,
  } = useUserWallets();

  const [wallets, setWallets] = useState<IUserWallet[]>(
    userWallets.length
      ? userWallets.map((wallet) => ({
          ...wallet,
          id: wallet.databaseId!,
          isNew: false,
          errors: { address: false, name: false, duplicate: false },
        }))
      : [
          {
            ...walletDefaulValue,
            id: Date.now(),
            errors: { address: false, name: false, duplicate: false },
          },
        ],
  );

  const validateAllWallets = async () => {
    let isValid = true;
    const updatedWallets = await Promise.all(
      wallets.map(async (wallet) => {
        const { addressError, nameError } = await validateWallet(wallet);
        const duplicateError =
          wallets.filter((w) => w.address === wallet.address).length > 1;

        if (addressError || nameError || duplicateError) isValid = false;

        return {
          ...wallet,
          errors: {
            address: addressError,
            name: nameError,
            duplicate: duplicateError,
          },
        };
      }),
    );

    setWallets(updatedWallets);
    return isValid;
  };

  const handleAddressChange = async (id: number, address: string) => {
    const network = await detectNetwork(address);
    setWallets((prev) =>
      prev.map((wallet) =>
        wallet.id === id ? { ...wallet, address, chain: network } : wallet,
      ),
    );
  };

  const handleDeleteWallet = (id: number) => {
    setWallets((prev) => prev.filter((wallet) => wallet.id !== id));
  };

  const handleSave = async () => {
    if (!(await validateAllWallets())) return;

    try {
      const newWallets = wallets.filter((wallet) => wallet.isNew);
      const existingWallets = wallets.filter((wallet) => !wallet.isNew);

      const removedWalletIds = userWallets
        .filter((savedWallet) =>
          wallets.every(
            (wallet) => wallet.databaseId !== savedWallet.databaseId,
          ),
        )
        .map((wallet) => wallet.databaseId!);

      if (newWallets.length) await createWallets(newWallets);
      if (existingWallets.length) await updateWallets(existingWallets);
      if (removedWalletIds.length) await deleteWallets(removedWalletIds);

      await reloadUserWallets();

      const hasMyWallet = wallets.some((wallet) => wallet.isMyWallet);
      const type = hasMyWallet ? MY_WALLETS : WATCHLIST;
      navigate(`/${PORTFOLIO}/${type}/${DEFAULT_TAB}/${DEFAULT_SUBTAB}`);
    } catch (error) {
      console.error("Error saving wallets:", error);
    }
  };

  return (
    <div className={walletManagerStyles.container}>
      <div className={walletManagerStyles.walletsList}>
        {wallets.map((wallet) => (
          <WalletCard
            chain={wallet.chain}
            key={wallet.id}
            address={wallet.address}
            name={wallet.name}
            isMyWallet={wallet.isMyWallet}
            isEditable={true}
            hasAddressError={wallet.errors.address || wallet.errors.duplicate}
            addressErrorText={
              wallet.errors.duplicate ? DUPLICATE_ERROR : ADDRESS_ERROR
            }
            hasNameError={wallet.errors.name}
            nameErrorText={
              wallet.errors.name
                ? wallet.name.length > NAME_MAX_LENGTH
                  ? NAME_LENGTH_ERROR
                  : NAME_EMPTY_ERROR
                : ""
            }
            onAddressChange={(value) => handleAddressChange(wallet.id, value)}
            onNameChange={(value) =>
              setWallets((prev) =>
                prev.map((w) =>
                  w.id === wallet.id ? { ...w, name: value } : w,
                ),
              )
            }
            onMyWalletChange={(checked) =>
              setWallets((prev) =>
                prev.map((w) =>
                  w.id === wallet.id ? { ...w, isMyWallet: checked } : w,
                ),
              )
            }
            onDelete={() => handleDeleteWallet(wallet.id)}
          />
        ))}
        {wallets.length < MAX_WALLETS_COUNT && (
          <SmallButton
            onClick={() =>
              setWallets([
                ...wallets,
                {
                  ...walletDefaulValue,
                  id: Date.now(),
                  errors: { address: false, name: false, duplicate: false },
                },
              ])
            }
          >
            Add another one
          </SmallButton>
        )}
      </div>
      <div
        className={walletManagerStyles.fixedSaveButton(
          isLayout ? NAVIGATION_BAR_HEIGHT : 0,
        )}
      >
        <div className={walletManagerStyles.buttonWrapper(32)}>
          <Button variant="light" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
