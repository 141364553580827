export const CantoLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0_13668_3610)">
        <rect
          x="-1.33325"
          y="-1.33337"
          width="34.6667"
          height="34.6667"
          fill="url(#pattern0_13668_3610)"
        />
      </g>
      <defs>
        <pattern
          id="pattern0_13668_3610"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_13668_3610" transform="scale(0.015625)" />
        </pattern>
        <clipPath id="clip0_13668_3610">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
        <image
          id="image0_13668_3610"
          width="64"
          height="64"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAIAAAAlC+aJAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAQKADAAQAAAABAAAAQAAAAABGUUKwAAAA4klEQVRoBe2WQQqEMAxFVYrnHHAxZ3IhOKeZOw0i464SRWqmTSfwXLWlTfPfT7BNwwcBCEAAAhCAAAQgAAEIQMApgTZL3v0y/h7nE56KIJ3izF8dQUBtO9w7EHQEr7s2sR2vgyQm5t4BBCQ6XWybeweUTbwHugzzfrqNw/QQK+WmGQSsr7fIz1KA+xJCgKge8ykOmCMXF+KAAGI+de9Ahh/Z8VXMc/pGIbovIQTccLvIVmUTizY99nGRZM+CKgWchYprlnrogci9zsi9A3WwcSsEIAABCEAAAhCAAAQgAIEMBL53OxeAji8SpgAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};
