export const PolygonLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#8247E5" />
      <path
        d="M21.536 12.4205C21.1461 12.1989 20.6448 12.1989 20.1992 12.4205L17.0799 14.2483L14.9633 15.4115L11.8998 17.2394C11.5099 17.461 11.0086 17.461 10.563 17.2394L8.16784 15.7993C7.77794 15.5777 7.49944 15.1346 7.49944 14.6361V11.8665C7.49944 11.4234 7.72224 10.9803 8.16784 10.7033L10.563 9.31857C10.9529 9.09701 11.4542 9.09701 11.8998 9.31857L14.2949 10.7587C14.6848 10.9803 14.9633 11.4234 14.9633 11.9219V13.7498L17.0799 12.5312V10.6479C17.0799 10.2048 16.8571 9.7617 16.4115 9.48474L11.9555 6.88138C11.5656 6.65982 11.0643 6.65982 10.6187 6.88138L6.05122 9.54014C5.60561 9.7617 5.38281 10.2048 5.38281 10.6479V15.8547C5.38281 16.2978 5.60561 16.7409 6.05122 17.0179L10.563 19.6212C10.9529 19.8428 11.4542 19.8428 11.8998 19.6212L14.9633 17.8487L17.0799 16.6301L20.1435 14.8576C20.5334 14.6361 21.0347 14.6361 21.4803 14.8576L23.8754 16.2424C24.2653 16.464 24.5438 16.9071 24.5438 17.4056V20.1752C24.5438 20.6183 24.321 21.0614 23.8754 21.3384L21.536 22.7231C21.1461 22.9447 20.6448 22.9447 20.1992 22.7231L17.804 21.3384C17.4141 21.1168 17.1356 20.6737 17.1356 20.1752V18.4026L15.019 19.6212V21.4491C15.019 21.8923 15.2418 22.3354 15.6874 22.6123L20.1992 25.2157C20.5891 25.4373 21.0904 25.4373 21.536 25.2157L26.0477 22.6123C26.4376 22.3908 26.7161 21.9477 26.7161 21.4491V16.187C26.7161 15.7439 26.4933 15.3008 26.0477 15.0238L21.536 12.4205Z"
        fill="white"
      />
    </svg>
  );
};
