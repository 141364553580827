import React from "react";
import { css } from "@emotion/css";
import { TokenInfo } from "./TokenInfo";
import { IToken } from "../shared/intefaces/IToken";
import { EmptyState } from "./EmptyState";
import { sumArray } from "../shared/utils/sumArray";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { TotalBalance } from "./TotalBalance";

const cryptocurrencyListStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: white;
    padding-bottom: 60px;
  `,
  valueContainer: css`
    display: flex;
    margin-bottom: 24px;
  `,
  valueText: css`
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: -0.43px;
    color: #c1c1c899;
    margin-right: 8px;
  `,
};

interface CryptocurrencyListProps {
  data: IToken[];
}

export const CryptocurrencyList: React.FC<CryptocurrencyListProps> = ({
  data,
}) => {
  return (
    <div>
      {data.length > 0 && (
        <div className={cryptocurrencyListStyles.valueContainer}>
          <div className={cryptocurrencyListStyles.valueText}>{"Value:"}</div>
          <TotalBalance
            value={getFormattedValue(sumArray(data.map((x) => x.value)), true)}
            size={"small"}
          />
        </div>
      )}
      <div className={cryptocurrencyListStyles.container}>
        {data.map((token, index) => (
          <TokenInfo
            tokenLogo={token.logoUrl}
            networkLogo={token.chainLogo}
            tag={token.walletName}
            tokenName={token.symbol}
            price={token.price}
            balance={token.amount}
            value={token.value}
            key={index}
          />
        ))}
        {data.length === 0 && <EmptyState />}
      </div>
    </div>
  );
};
