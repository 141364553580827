export const ShidenLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M21.0568 10.0019C19.2695 10.5059 16.6322 11.56 14.2165 13.7205C11.7887 15.9819 13.4369 17.9988 13.4539 18.0188C13.4539 18.0188 12.5642 16.3947 14.7161 14.3938C14.7434 14.3702 17.4903 12.0222 21.4852 10.8959C21.9946 10.7523 22.5095 10.6327 23.0157 10.5406L23.0509 10.5343L23.694 9.50256L23.5362 9.51362C23.5262 9.51445 22.5224 9.58868 21.0568 10.0019Z"
        fill="#4F2EA3"
      />
      <path
        d="M23.8069 7.11069C23.3954 6.76789 22.9844 6.49661 22.9802 6.4939L22.9554 6.47791L22.9262 6.48132C22.9152 6.48273 21.8062 6.61778 20.2789 7.04846C17.5462 7.81877 13.8711 9.39867 11.8811 12.5485C10.9359 13.9793 11.6967 15.6914 11.6967 15.6914L11.6737 15.2556C11.6713 15.2094 11.5407 10.5937 21.1001 7.89817C21.9369 7.66219 22.8355 7.44479 23.7716 7.25139L23.9355 7.21763L23.8069 7.11069Z"
        fill="#6F50BD"
      />
      <path
        d="M24.3844 7.7133C24.372 7.7146 23.1144 7.84706 21.3624 8.34091C19.5082 8.86382 16.8082 9.87776 14.4768 11.7379C13.1021 12.7949 12.232 14.0701 12.0896 15.2367C12.0197 15.8061 12.6939 17.0146 12.6939 17.0146L12.5521 16.5854C12.5456 16.5658 11.9432 14.6105 14.9961 12.3419C16.7067 11.0704 18.4894 10.2002 20.6059 9.60355C21.697 9.29592 22.7425 9.09381 23.8981 8.88006L23.9389 8.87253L23.9565 8.83524C24.0438 8.64996 24.4601 7.83859 24.4645 7.83047L24.532 7.69836L24.3844 7.7133Z"
        fill="#6F50BD"
      />
      <path
        d="M11.0235 13.6497C11.1386 11.8177 12.5404 10.125 15.1931 8.61694C16.591 7.75311 18.1536 7.07444 19.8374 6.59965C21.2654 6.19696 22.4725 6.07674 22.4725 6.07674L21.5018 5.38184C21.4928 5.38289 20.5981 5.49171 19.3194 5.85228C11.1205 8.16403 10.1045 12.1471 10.1952 13.7482C10.354 16.5397 13.6752 18.5248 13.6752 18.5248C13.6752 18.5248 10.8521 16.3794 11.0235 13.6497Z"
        fill="#B7A7DD"
      />
      <path
        d="M11.1254 22.0951C12.9127 21.5911 15.5499 20.5371 17.9657 18.3765C20.3934 16.1151 18.7453 14.0983 18.7282 14.0783C18.7282 14.0783 19.6179 15.7023 17.466 17.7032C17.4388 17.7269 14.6919 20.0749 10.6969 21.2012C10.1875 21.3448 9.67262 21.4643 9.16641 21.5564L9.13124 21.5628L8.4881 22.5945L8.64597 22.5834C8.65597 22.5826 9.65968 22.5084 11.1254 22.0951Z"
        fill="#4F2EA3"
      />
      <path
        d="M8.37523 24.9864C8.78674 25.3292 9.19777 25.6004 9.20189 25.6031L9.22671 25.6191L9.25589 25.6157C9.26694 25.6143 10.3759 25.4793 11.9033 25.0486C14.6359 24.2783 18.311 22.6984 20.301 19.5486C21.2462 18.1177 20.4855 16.4057 20.4855 16.4057L20.5084 16.8414C20.5109 16.8876 20.6414 21.5034 11.082 24.1989C10.2452 24.4349 9.34659 24.6523 8.41052 24.8457L8.24665 24.8794L8.37523 24.9864Z"
        fill="#6F50BD"
      />
      <path
        d="M7.79777 24.3837C7.81012 24.3824 9.0677 24.25 10.8197 23.7561C12.674 23.2332 15.3739 22.2193 17.7053 20.3592C19.08 19.3022 19.9501 18.0269 20.0926 16.8603C20.1625 16.2909 19.4883 15.0824 19.4883 15.0824L19.63 15.5117C19.6365 15.5312 20.2389 17.4865 17.186 19.7552C15.4754 21.0266 13.6927 21.8968 11.5763 22.4935C10.4851 22.8011 9.43967 23.0032 8.28398 23.217L8.24327 23.2245L8.22563 23.2618C8.13834 23.4471 7.72201 24.2585 7.71766 24.2666L7.65013 24.3987L7.79777 24.3837Z"
        fill="#6F50BD"
      />
      <path
        d="M21.1587 18.4473C21.0435 20.2794 19.6417 21.9721 16.989 23.4801C15.5911 24.3439 14.0285 25.0226 12.3447 25.4974C10.9167 25.9001 9.70958 26.0203 9.70958 26.0203L10.6803 26.7152C10.6893 26.7142 11.5841 26.6053 12.8627 26.2448C21.0616 23.933 22.0777 19.95 21.987 18.3489C21.8282 15.5574 18.5069 13.5722 18.5069 13.5722C18.5069 13.5722 21.3301 15.7176 21.1587 18.4473Z"
        fill="#B7A7DD"
      />
    </svg>
  );
};
