import React, { useState } from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { BackButton } from "./uiKit/BackButton";
import { BalanceInfo } from "./BalanceInfo";
import { IHyperLiquidSpotPosition } from "../shared/intefaces/IHyperLiquidPosition";
import { PriceChart } from "./PriceChart";
import { Tabs } from "./uiKit/Tabs";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { getFormattedPnlLikeValue } from "../shared/utils/getFormattedPnlLikeValue";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 24px;
    font-family: "Open Sans", sans-serif;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  statsWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  statsGrid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  `,
};

const timeFrames = ["4h", "7d", "30d"] as const;
type TimeFrame = (typeof timeFrames)[number];

export const HyperLiquidSpotPositionDetails: React.FC<
  IHyperLiquidSpotPosition
> = ({ coin, total, totalValue, pnl, roi, avgEntryPrice, currentPrice }) => {
  const navigate = useNavigate();
  const [activeTime, setActiveTime] = useState<TimeFrame>("4h");

  const check = pnl > 0;
  const zeroCheck = pnl === 0;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton onClick={() => navigate(-1)} />
        <Tabs
          tabs={timeFrames.map((label) => ({ label }))}
          activeIndex={timeFrames.indexOf(activeTime)}
          onTabChange={(index) => setActiveTime(timeFrames[index])}
          variant="secondary"
        />
      </div>

      <PriceChart type="spot" symbol={coin} timeframe={activeTime} />

      <div className={styles.statsWrapper}>
        <div className={styles.statsGrid}>
          <BalanceInfo title="Coin" value={coin} />
          <BalanceInfo
            title="Total balance"
            value={getFormattedPnlLikeValue(total, false) + coin}
          />
          <BalanceInfo
            title="USDC value"
            value={getFormattedPnlLikeValue(totalValue, true)}
          />
          <BalanceInfo
            title="PNL (ROI %)"
            value={getFormattedPnlLikeValue(pnl, true)}
            subValue={getFormattedPnlLikeValue(roi, false) + "%"}
            color={zeroCheck ? "white" : check ? "green" : "red"}
          />
          <BalanceInfo
            title="Price"
            value={getFormattedValue(currentPrice, true)}
          />
          <BalanceInfo
            title="Average purchase price"
            value={getFormattedValue(avgEntryPrice, true)}
          />
        </div>
      </div>
    </div>
  );
};
