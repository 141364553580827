import React from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { IHyperLiquidPerpPosition } from "../shared/intefaces/IHyperLiquidPosition";
import { BalanceInfo } from "./BalanceInfo";
import { PERP } from "../shared/constants/routes";
import { getFormattedPnlLikeValue } from "../shared/utils/getFormattedPnlLikeValue";

const styles = {
  card: css`
    background: #0a0a0a;
    border-radius: 12px;
    padding: 16px;
    cursor: pointer;
    transition: background 0.3s ease-out;
    border: 1px solid #7c7c7c52;

    &:hover {
      border: 1px solid #ffffff66;
    }
  `,
  grid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  `,
  name: css`
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.43px;
    margin-bottom: 12px;
    display: flex;
    gap: 4px;
    align-content: center;
  `,
  walletName: css`
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: #1c1c1c;
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
};

interface IHyperliquidPerpCardProps {
  position: IHyperLiquidPerpPosition;
  walletName: string;
}

export const HyperLiquidPerpCard: React.FC<IHyperliquidPerpCardProps> = ({
  position,
  walletName,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/position/${PERP}/${position.coin.toLowerCase()}`, {
      state: {
        coin: position.coin,
        roi: position.roi,
        currentPrice: position.currentPrice,
        leverage: position.leverage,
        direction: position.direction,
        size: position.size,
        positionValue: position.positionValue,
        entryPx: position.entryPx,
        liquidationPx: position.liquidationPx,
        unrealizedPnl: position.unrealizedPnl,
      },
    });
  };

  const check = position.unrealizedPnl > 0;
  const zeroCheck = position.unrealizedPnl === 0;

  return (
    <div className={styles.card} onClick={handleClick}>
      <div className={styles.name}>
        {position.coin.toUpperCase() +
          "USD" +
          " " +
          (position.direction === "short" ? "-" : "") +
          `${position.leverage}x`}
        <div className={styles.walletName}>{walletName}</div>
      </div>
      <div className={styles.grid}>
        <BalanceInfo
          title="Coin"
          value={position.coin + " " + position.leverage + "x"}
          color={position.direction === "long" ? "green" : "red"}
          showSign={false}
        />
        <BalanceInfo
          title="Position value"
          value={getFormattedPnlLikeValue(position?.positionValue, true)}
        />
        <BalanceInfo
          title="PNL (ROI %)"
          value={getFormattedPnlLikeValue(position.unrealizedPnl, true)}
          subValue={getFormattedPnlLikeValue(position.roi, false) + "%"}
          color={zeroCheck ? "white" : check ? "green" : "red"}
        />
      </div>
    </div>
  );
};
