export const TonLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13668_3291)">
        <path
          d="M16 32C24.8571 32 32 24.8571 32 16C32 7.14286 24.8571 0 16 0C7.14286 0 0 7.14286 0 16C0 24.8571 7.14286 32 16 32Z"
          fill="#0098EA"
        />
        <path
          d="M21.4856 8.91431H10.5142C8.5142 8.91431 7.25706 11.0857 8.22849 12.8572L14.9713 24.5714C15.4285 25.3143 16.5142 25.3143 16.9713 24.5714L23.7142 12.8572C24.7428 11.0857 23.4856 8.91431 21.4856 8.91431ZM15.0285 21.0286L13.5428 18.1714L9.99992 11.8286C9.77135 11.4286 10.0571 10.9143 10.5713 10.9143H15.0285V21.0286ZM21.9999 11.8286L18.4571 18.1714L16.9713 21.0286V10.9143H21.4285C21.9428 10.9143 22.2285 11.4286 21.9999 11.8286Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13668_3291">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
