export const WanchainLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#5DBBEF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 11.0121L9.70933 12.16V18.6139L16.1429 14.7668L22.6383 18.6139V12.16L25.2857 11.0121V23.1762L16.1429 17.7768L7 23.1762V11.0121ZM7.30781 10.5153L16.1429 5.33337L25.0399 10.5153L22.6383 11.5089L16.1429 7.7542L9.70933 11.5089L7.30781 10.5153ZM9.21663 22.524L11.2179 21.376L16.1733 24.2459L21.0678 21.376L23.13 22.524L16.1733 26.6667L9.21663 22.524Z"
        fill="white"
      />
    </svg>
  );
};
