import React from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { useUserWallets } from "./context/UserWalletContext";
import { NetworkInfo } from "./NetworkInfo";
import { getNetworks } from "./networks";
import { DownArrowIcon } from "./uiKit/DropDown";

const styles = {
  container: css`
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    background: #0a0a0a;
    padding: 8px 12px;
    border-radius: 8px;
  `,
  networkInfoWrapper: css`
    display: flex;
    align-items: flex-start;
    gap: 4px;
  `,
  text: css`
    color: #c1c1c899;
    text-align: center;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.43px;
  `,
  remainingCount: css`
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    align-items: center;
    color: #c1c1c899;
  `,
  arrow: css`
    width: 12px;
    height: 12px;
    transform: rotate(90deg);
    transition: transform 0.3s ease;
  `,
};

export const NetworkSelector: React.FC = () => {
  const navigate = useNavigate();
  const { selectedNetworks } = useUserWallets();

  const handleClick = () => {
    navigate("/select-network");
  };

  const allNetworks = getNetworks();
  const isAllNetworksSelected = selectedNetworks.length === allNetworks.length;

  return (
    <div className={styles.container} onClick={handleClick}>
      {selectedNetworks.length === 0 ? (
        <span className={styles.text}>Select chain</span>
      ) : isAllNetworksSelected ? (
        <span className={styles.text}>All chains</span>
      ) : selectedNetworks.length === 1 ? (
        <NetworkInfo chain={selectedNetworks[0]} isSelector={true} />
      ) : (
        <div className={styles.networkInfoWrapper}>
          <NetworkInfo chain={selectedNetworks[0]} isSelector={true} />
          <span className={styles.remainingCount}>
            +{selectedNetworks.length - 1}
          </span>
        </div>
      )}
      <DownArrowIcon />
    </div>
  );
};
