export const CeloLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="15" fill="#111214" />
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M18.2857 19.8604C21.6801 19.8604 24.4318 17.1087 24.4318 13.7143C24.4318 10.3199 21.6801 7.56829 18.2857 7.56829C14.8913 7.56829 12.1397 10.3199 12.1397 13.7143C12.1397 17.1087 14.8913 19.8604 18.2857 19.8604ZM18.2857 22.0953C13.6572 22.0953 9.90479 18.3428 9.90479 13.7143C9.90479 9.08581 13.6572 5.33337 18.2857 5.33337C22.9143 5.33337 26.6667 9.08581 26.6667 13.7143C26.6667 18.3428 22.9143 22.0953 18.2857 22.0953Z"
        fill="#35D07F"
      />
      <path
        d="M13.7142 24.4318C17.1086 24.4318 19.8602 21.6801 19.8602 18.2857C19.8602 14.8913 17.1086 12.1397 13.7142 12.1397C10.3198 12.1397 7.56817 14.8913 7.56817 18.2857C7.56817 21.6801 10.3198 24.4318 13.7142 24.4318ZM13.7142 26.6667C9.08568 26.6667 5.33325 22.9143 5.33325 18.2857C5.33325 13.6572 9.08568 9.90479 13.7142 9.90479C18.3427 9.90479 22.0952 13.6572 22.0952 18.2857C22.0952 22.9143 18.3427 26.6667 13.7142 26.6667Z"
        fill="#FBCC5C"
      />
      <path
        d="M18.4907 22.0953C19.0671 21.3968 19.4802 20.5783 19.6999 19.6998C20.5784 19.4801 21.3968 19.067 22.0953 18.4907C22.0634 19.508 21.8443 20.5106 21.449 21.4485C20.5111 21.8441 19.5083 22.0633 18.4909 22.0953H18.4907ZM12.3002 12.2998C11.4216 12.5195 10.6033 12.9326 9.90479 13.5089C9.93663 12.4916 10.1557 11.489 10.5511 10.5511C11.489 10.1557 12.4918 9.93658 13.5092 9.90479C12.9329 10.6032 12.5198 11.4214 12.3002 12.2998V12.2998Z"
        fill="#ECFF8F"
      />
    </svg>
  );
};
