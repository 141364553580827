export const ZoomIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="65"
      viewBox="0 0 63 65"
      fill="none"
    >
      <rect
        x="31.4141"
        y="36.2427"
        width="4"
        height="14"
        transform="rotate(-45 31.4141 36.2427)"
        fill="white"
      />
      <rect
        x="37.6727"
        y="46.7437"
        width="10"
        height="27.1497"
        rx="5"
        transform="rotate(-45 37.6727 46.7437)"
        fill="#CEBCFF"
        stroke="#0A0A0A"
        strokeWidth="2"
      />
      <path
        d="M49.5 48.5L57.9995 56.9998"
        stroke="#8165CB"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 40C31.4934 40 40 31.4934 40 21C40 10.5066 31.4934 2 21 2C10.5066 2 2 10.5066 2 21C2 31.4934 10.5066 40 21 40ZM21 35C28.732 35 35 28.732 35 21C35 13.268 28.732 7 21 7C13.268 7 7 13.268 7 21C7 28.732 13.268 35 21 35Z"
        fill="#CEBCFF"
      />
      <path
        d="M21 41C32.0457 41 41 32.0457 41 21C41 9.95431 32.0457 1 21 1C9.95431 1 1 9.95431 1 21C1 26.4877 3.21147 31.4608 6.78949 35.0734C10.4129 38.7319 15.4425 41 21 41Z"
        stroke="#0A0A0A"
        strokeWidth="2"
      />
    </svg>
  );
};
