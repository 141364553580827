import React from "react";
import {css} from "@emotion/css";
import {ZoomIcon} from "./icons/ZoomIcon";

const emptyStateStyles = {
    container: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #7C7C7C52;
        border-radius: 8px;
        padding: 24px;
    `,
    iconWrapper: css`
        margin-bottom: 8px;
    `,
    text: css`
        font-family: "Space Mono", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        letter-spacing: -0.43px;
        color: #ffffff;
    `,
};


export const EmptyState = () => {
    return (
        <div className={emptyStateStyles.container}>
            <div className={emptyStateStyles.iconWrapper}>
                <ZoomIcon/>
            </div>
            <span className={emptyStateStyles.text}>{"Probably nothing!"}</span>
        </div>
    );
};
