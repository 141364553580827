export const BaseLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13682_3319)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#0052FF"
        />
        <path
          d="M16.0708 27.1187C22.2723 27.1187 27.2993 22.1002 27.2993 15.9096C27.2993 9.71902 22.2723 4.70056 16.0708 4.70056C10.1873 4.70056 5.36071 9.21771 4.88135 14.9674H19.723V16.8518H4.88135C5.36071 22.6014 10.1873 27.1187 16.0708 27.1187Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13682_3319">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
