import React from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { BackButton } from "./uiKit/BackButton";
import { BalanceInfo } from "./BalanceInfo";
import { IHyperLiquidVaultPosition } from "../shared/intefaces/IHyperLiquidPosition";
import dayjs from "dayjs";
import { getFormattedPnlLikeValue } from "../shared/utils/getFormattedPnlLikeValue";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-family: "Open Sans", sans-serif;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  `,
  statsWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  statsGrid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  `,
  name: css`
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.43px;
    margin-bottom: 12px;
  `,
};

export const HyperLiquidVaultPositionDetails: React.FC<
  IHyperLiquidVaultPosition
> = ({
  name,
  vaultAddress,
  vaultEquity,
  pnl,
  allTimePnl,
  daysFollowing,
  vaultEntryTime,
  lockupUntil,
  roi,
}) => {
  const navigate = useNavigate();

  const check = pnl > 0;
  const zeroCheck = pnl === 0;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton onClick={() => navigate(-1)} />
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.statsWrapper}>
        <div className={styles.statsGrid}>
          <BalanceInfo
            title="Value"
            value={getFormattedPnlLikeValue(vaultEquity, true)}
          />
          <BalanceInfo
            title="Days following"
            value={getFormattedPnlLikeValue(daysFollowing, false)}
          />
          <BalanceInfo
            title="Vault entry time"
            value={dayjs(vaultEntryTime).format("DD/MM/YYYY HH:mm")}
          />
          <BalanceInfo
            title="Lockup until"
            value={dayjs(lockupUntil).format("DD/MM/YYYY HH:mm")}
          />
          <BalanceInfo
            title="PNL (ROI %)"
            value={getFormattedPnlLikeValue(pnl, false)}
            subValue={getFormattedPnlLikeValue(roi, false) + "%"}
            color={zeroCheck ? "white" : check ? "green" : "red"}
          />
        </div>
      </div>
    </div>
  );
};
