import React from "react";
import { css } from "@emotion/css";
import { ArrowIcon } from "../icons/ArrowIcon";

const backButtonStyles = {
  button: css`
    display: flex;
    gap: 8px;
    background: none;
    cursor: pointer;
    color: #c1c1c899;
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 590;
    line-height: 20px;

    &:hover {
      color: #ffffff;

      svg path {
        fill: #ffffff;
        fillopacity: 1;
      }
    }
  `,
  icon: css`
    padding-top: 2px;
  `,
};

interface BackButtonProps {
  onClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <div className={backButtonStyles.button} onClick={onClick}>
      <span className={backButtonStyles.icon}>
        <ArrowIcon />
      </span>
      {"Back"}
    </div>
  );
};
