import React from "react";
import { css } from "@emotion/css";
import { ProtocolInfo } from "./ProtocolInfo";
import { IProtocol } from "../shared/intefaces/IProtocol";
import { EmptyState } from "./EmptyState";
import { TabSumValue } from "./TabSumValue";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { sumArray } from "../shared/utils/sumArray";

const protocolListStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
  `,
};

interface ProtocolListProps {
  data: IProtocol[];
}

export const ProtocolList: React.FC<ProtocolListProps> = ({ data }) => {
  return (
    <div className={protocolListStyles.container}>
      {data.length > 0 && (
        <TabSumValue
          value={getFormattedValue(sumArray(data.map((x) => x.value)))}
        />
      )}
      {data.map((protocol, index) => (
        <ProtocolInfo
          protocolLogo={protocol.image}
          networkLogo={protocol.chainLogo}
          protocolName={protocol.name}
          value={protocol.value}
          tag={protocol.walletName}
          key={index}
        />
      ))}
      {data.length === 0 && <EmptyState message={"No protocols found"} />}
    </div>
  );
};
