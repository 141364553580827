export const GnosisLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13668_3294)">
        <circle cx="16" cy="16" r="16" fill="black" />
        <path
          d="M11.6847 17.4417C12.3088 17.4417 12.8828 17.2314 13.3434 16.8776L9.53865 13.0729C9.18153 13.5301 8.97461 14.1075 8.97461 14.7316C8.97461 16.2235 10.1895 17.4417 11.6847 17.4417Z"
          fill="white"
        />
        <path
          d="M22.9957 14.7283C22.9957 14.1042 22.7855 13.5302 22.4317 13.0696L18.627 16.871C19.0842 17.2281 19.6616 17.435 20.2857 17.435C21.7809 17.4417 22.9957 16.2235 22.9957 14.7283Z"
          fill="white"
        />
        <path
          d="M24.9112 10.5864L23.2291 12.2685C23.7865 12.936 24.1203 13.7904 24.1203 14.7249C24.1203 16.8409 22.3981 18.5631 20.2821 18.5631C19.351 18.5631 18.4932 18.226 17.8257 17.6719L15.9834 19.5143L14.1411 17.6719C13.4736 18.2293 12.6192 18.5631 11.6847 18.5631C9.56873 18.5631 7.84658 16.8409 7.84658 14.7249C7.84658 13.7938 8.18366 12.936 8.73769 12.2685L7.87661 11.4075L7.05893 10.5898C6.1044 12.1684 5.55371 14.0174 5.55371 15.9965C5.55371 21.7571 10.2229 26.4296 15.9868 26.4296C21.7506 26.4296 26.4198 21.7604 26.4198 15.9965C26.4165 14.014 25.8658 12.1684 24.9112 10.5864Z"
          fill="white"
        />
        <path
          d="M23.5297 8.79418C21.634 6.80503 18.9506 5.56348 15.9836 5.56348C13.0165 5.56348 10.3332 6.80503 8.43746 8.79418C8.18047 9.06786 7.93683 9.35155 7.70654 9.64858L15.9836 17.9323L24.2606 9.64858C24.037 9.34821 23.79 9.06786 23.5297 8.79418ZM15.9836 6.92852C18.4266 6.92852 20.6928 7.86969 22.3982 9.58517L15.9836 15.9999L9.56887 9.58517C11.2743 7.86636 13.5438 6.92852 15.9836 6.92852Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13668_3294">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
