export const OpbnbLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13706_3368)">
        <rect width="32" height="32" rx="16" fill="white" />
        <path
          d="M6.61582 15.1037C6.61584 14.9261 6.65096 14.7501 6.71919 14.586C6.78741 14.422 6.88734 14.273 7.0133 14.1476C7.13925 14.0223 7.28873 13.923 7.45314 13.8556C7.61755 13.7882 7.79365 13.7539 7.97136 13.7548L10.2187 13.7621C10.577 13.7621 10.9207 13.9044 11.1741 14.1578C11.4274 14.4112 11.5698 14.7549 11.5698 15.1132V23.6116C11.8229 23.5365 12.1468 23.4565 12.5033 23.3726C12.7502 23.3145 12.9703 23.1747 13.1279 22.9758C13.2854 22.7769 13.3712 22.5307 13.3712 22.277V11.7361C13.3712 11.3778 13.5135 11.0341 13.7669 10.7807C14.0203 10.5273 14.364 10.3849 14.7223 10.3848H16.9765C17.3348 10.3849 17.6785 10.5273 17.9318 10.7807C18.1852 11.0341 18.3276 11.3778 18.3276 11.7361V21.5197C18.3276 21.5197 18.8915 21.2915 19.4405 21.0597C19.6445 20.9734 19.8185 20.829 19.941 20.6444C20.0634 20.4598 20.1288 20.2433 20.129 20.0218V8.35829C20.129 8.00003 20.2713 7.65642 20.5246 7.40304C20.7779 7.14967 21.1215 7.00729 21.4798 7.00721H23.7316C24.0897 7.00758 24.433 7.15006 24.6861 7.40341C24.9392 7.65676 25.0814 8.00021 25.0814 8.35829V17.9628C27.0337 16.5479 29.0122 14.8462 30.5823 12.8C30.81 12.503 30.9608 12.1542 31.0211 11.7848C31.0812 11.4153 31.0492 11.0367 30.9275 10.6828C30.2007 8.59179 29.0452 6.67554 27.5355 5.05668C26.0256 3.43782 24.1944 2.15197 22.159 1.28142C20.1237 0.410883 17.929 -0.0251875 15.7155 0.00112353C13.502 0.0274345 11.3183 0.515546 9.30421 1.43422C7.29012 2.3529 5.49002 3.68191 4.01904 5.33621C2.54808 6.9905 1.43866 8.93367 0.761765 11.0413C0.0848729 13.149 -0.144577 15.3748 0.0880971 17.5762C0.320771 19.7776 1.01044 21.9063 2.11299 23.8258C2.30508 24.157 2.58768 24.4264 2.92761 24.6025C3.26751 24.7786 3.65062 24.8541 4.03195 24.8199C4.45791 24.7824 4.98828 24.7294 5.61883 24.6555C5.89325 24.6243 6.14662 24.4932 6.33065 24.2872C6.5147 24.0813 6.61658 23.8148 6.61687 23.5386V15.1037"
          fill="#12161C"
        />
        <path
          d="M6.56787 28.7329C8.94143 30.4596 11.7464 31.4962 14.6725 31.7275C17.5985 31.9591 20.5316 31.3765 23.1471 30.0446C25.7627 28.7127 27.9588 26.683 29.4924 24.1804C31.0261 21.6778 31.8376 18.7996 31.8371 15.8644C31.8371 15.4972 31.82 15.1358 31.7956 14.7754C25.9824 23.4456 15.2488 27.4988 6.56787 28.7329Z"
          fill="#F0B90B"
        />
      </g>
      <defs>
        <clipPath id="clip0_13706_3368">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
