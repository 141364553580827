export const KccLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#13192B" />
      <path
        d="M26.7994 14.6795L26.195 13.675L22.8862 8.15779H22.8711L22.7654 7.96289H21.5567L16.8579 16.1638L21.6322 24.3197H22.8409L22.8711 24.2747L26.8145 17.5731H24.382L22.2819 21.2762L19.2904 16.1488L22.2215 11.0214L24.3518 14.6645L26.7994 14.6795Z"
        fill="#76E4B2"
      />
      <path
        d="M8.41276 13.3452L10.6186 9.61207L9.87829 8.42767L9.3646 7.58809L5.58745 14.1998L5.48169 14.3797L6.08603 15.4292H15.5893L20.3788 7.28823L19.714 6.1488L13.0662 6.1638H12.9604L11.9028 6.1488L12.4619 7.19828L13.0209 8.24776L17.3722 8.23276L14.3807 13.3452H8.41276Z"
        fill="#76E4B2"
      />
      <path
        d="M13.1114 9.02734C12.9452 9.02734 12.7639 9.05733 12.5825 9.1173C12.3861 9.17727 12.1897 9.26722 12.0084 9.37217C11.4947 9.68701 11.1321 10.1368 11.0717 10.5566C11.0415 10.7515 11.0868 10.9164 11.1926 11.0363C11.2077 11.0663 11.2379 11.0813 11.253 11.1113C11.2681 11.1263 11.2832 11.1263 11.2983 11.1413C11.3134 11.1563 11.3134 11.1563 11.3285 11.1713C11.3588 11.1863 11.3739 11.2013 11.4041 11.2163C11.7214 11.3662 12.2199 11.3362 12.7034 11.1113L12.7185 11.0963C12.7639 11.0813 12.8092 11.0513 12.8545 11.0363C12.8998 11.0213 12.93 10.9914 12.9754 10.9614C13.0207 10.9314 13.066 10.9014 13.1114 10.8714C13.1567 10.8414 13.1869 10.8115 13.2322 10.7815L13.2624 10.7665C13.2927 10.7365 13.3229 10.7215 13.3531 10.6915C13.3682 10.6765 13.3682 10.6765 13.3833 10.6615C13.4135 10.6465 13.4286 10.6166 13.4437 10.6016C13.4588 10.5866 13.474 10.5716 13.4891 10.5416C13.5042 10.5116 13.5344 10.4966 13.5495 10.4666C13.5646 10.4516 13.5646 10.4516 13.5797 10.4366C13.5948 10.4067 13.625 10.3917 13.6402 10.3617L13.6704 10.3317C13.6855 10.3017 13.7006 10.2867 13.7157 10.2567C13.7157 10.2567 13.7157 10.2567 13.7157 10.2417L13.7308 10.2118C13.761 10.1818 13.7761 10.1368 13.7912 10.1068C13.8063 10.0768 13.8215 10.0318 13.8366 10.0019L13.8517 9.98686C13.8517 9.97187 13.8668 9.95688 13.8668 9.95688C13.8668 9.94189 13.8668 9.94189 13.8819 9.92689C13.8819 9.89691 13.897 9.88192 13.897 9.85193C13.897 9.82195 13.9121 9.82195 13.9121 9.80695C13.9121 9.79196 13.9121 9.77697 13.9121 9.77697C13.9121 9.74698 13.9121 9.73199 13.9121 9.717V9.68701C13.9121 9.65703 13.9121 9.64204 13.9121 9.61205C13.9121 9.59706 13.9121 9.59706 13.9121 9.58207C13.9121 9.56707 13.9121 9.55208 13.9121 9.55208C13.9121 9.53709 13.9121 9.53709 13.9121 9.5221V9.5071C13.9121 9.47712 13.897 9.46213 13.8819 9.43214C13.8668 9.40216 13.8517 9.38716 13.8366 9.35718C13.7308 9.13229 13.4437 9.02734 13.1114 9.02734Z"
        fill="#76E4B2"
      />
      <path
        d="M14.214 10.1218C14.214 10.1518 14.1989 10.1668 14.1838 10.1968C14.1838 10.2118 14.1838 10.2118 14.1687 10.2268C14.1536 10.2718 14.1384 10.3017 14.1082 10.3467C13.942 10.6616 13.6701 10.9614 13.3377 11.2013C13.2924 11.2313 13.247 11.2613 13.2017 11.2912C13.1564 11.3212 13.1111 11.3512 13.0657 11.3662H13.0506C13.0355 11.3812 13.0204 11.3812 13.0053 11.3962C12.9751 11.4112 12.96 11.4262 12.9298 11.4412H12.9146C12.8995 11.4412 12.8844 11.4562 12.8693 11.4562C12.8391 11.4712 12.8089 11.4861 12.7787 11.4861L12.7485 11.5011C12.7485 11.5011 12.7485 11.5011 12.7333 11.5011C12.7182 11.5011 12.7031 11.5161 12.688 11.5161C12.6578 11.5311 12.6276 11.5311 12.5974 11.5461L12.5672 11.5611H12.552C12.5218 11.5761 12.4916 11.5761 12.4614 11.5911C12.4463 11.5911 12.4312 11.6061 12.401 11.6061H12.3858C12.3556 11.6061 12.3254 11.6211 12.3103 11.6211C12.2952 11.6211 12.265 11.6211 12.2499 11.6361H12.2348C12.2197 11.6361 12.2045 11.6361 12.1894 11.6511C12.1743 11.6511 12.1592 11.6511 12.1441 11.6511C12.0988 11.6511 12.0384 11.6661 11.993 11.6661H11.9779C11.9326 11.6661 11.8873 11.6661 11.8419 11.6661C11.8268 11.6661 11.8117 11.6661 11.8117 11.6661H11.7966C11.7664 11.6661 11.7362 11.6661 11.706 11.6511C11.6909 11.6511 11.6757 11.6511 11.6606 11.6361C11.6304 11.6361 11.6002 11.6211 11.57 11.6211C11.5549 11.6211 11.5398 11.6061 11.5247 11.6061L12.3556 12.9404L14.1384 12.9254L15.0601 11.3062L14.2291 9.95691C14.2291 9.9719 14.2291 9.98689 14.214 10.0019C14.214 10.0169 14.214 10.0169 14.214 10.0319C14.214 10.0469 14.1989 10.0619 14.1989 10.0918C14.214 10.1068 14.214 10.1218 14.214 10.1218Z"
        fill="#76E4B2"
      />
      <path
        d="M23.6874 14.9945C23.7781 15.1294 23.8385 15.2943 23.8839 15.4892C23.9292 15.6841 23.9594 15.894 23.9594 16.1039C23.9594 16.7036 23.763 17.2433 23.4306 17.5132C23.2795 17.6332 23.1133 17.6931 22.9471 17.6631C22.9169 17.6631 22.8867 17.6481 22.8565 17.6481C22.8414 17.6481 22.8263 17.6332 22.8111 17.6332C22.796 17.6332 22.7809 17.6182 22.7658 17.6182C22.7356 17.6032 22.7054 17.5882 22.6903 17.5732C22.3881 17.3783 22.1615 16.9435 22.101 16.4188V16.4038C22.101 16.3588 22.0859 16.3138 22.0859 16.2538C22.0859 16.2089 22.0859 16.1639 22.0859 16.1189C22.0859 16.0589 22.0859 16.014 22.0859 15.954C22.0859 15.909 22.0859 15.864 22.101 15.8191V15.7891C22.101 15.7591 22.1162 15.7141 22.1162 15.6841C22.1162 15.6691 22.1162 15.6541 22.1162 15.6391C22.1162 15.6092 22.1313 15.5792 22.1313 15.5492C22.1313 15.5192 22.1464 15.5042 22.1464 15.4742C22.1615 15.4442 22.1615 15.4143 22.1766 15.3843C22.1766 15.3693 22.1917 15.3543 22.1917 15.3393C22.2068 15.3093 22.2068 15.2793 22.2219 15.2493L22.237 15.2044C22.2521 15.1744 22.2672 15.1594 22.2672 15.1294C22.2672 15.1294 22.2672 15.1294 22.2672 15.1144L22.2823 15.0844C22.2975 15.0394 22.3277 15.0095 22.3428 14.9795C22.3579 14.9495 22.3881 14.9195 22.4032 14.8895L22.4183 14.8745C22.4334 14.8595 22.4334 14.8595 22.4485 14.8445C22.4637 14.8296 22.4637 14.8296 22.4637 14.8146C22.4788 14.7996 22.4939 14.7846 22.509 14.7696C22.5241 14.7546 22.5392 14.7396 22.5392 14.7396C22.5543 14.7246 22.5543 14.7246 22.5694 14.7096C22.5845 14.6946 22.5996 14.6796 22.6147 14.6646L22.645 14.6496C22.6601 14.6346 22.6903 14.6197 22.7054 14.6047C22.7205 14.6047 22.7205 14.6047 22.7356 14.5897C22.7507 14.5897 22.7658 14.5747 22.7658 14.5747C22.7658 14.5747 22.7809 14.5747 22.7809 14.5597H22.796C22.8263 14.5597 22.8414 14.5447 22.8716 14.5447C22.9018 14.5447 22.932 14.5447 22.9622 14.5447C23.2795 14.5297 23.5061 14.7096 23.6874 14.9945Z"
        fill="#76E4B2"
      />
      <path
        d="M22.1618 14.6345C22.1467 14.6495 22.1316 14.6795 22.1165 14.6945C22.1165 14.7095 22.1014 14.7095 22.1014 14.7244C22.0712 14.7544 22.0561 14.7994 22.0258 14.8294C21.8294 15.1292 21.7237 15.519 21.6935 15.9238C21.6935 15.9838 21.6935 16.0288 21.6935 16.0888C21.6935 16.1337 21.6935 16.1937 21.6935 16.2387V16.2537C21.6935 16.2687 21.6935 16.2987 21.6935 16.3137C21.6935 16.3436 21.6934 16.3736 21.7086 16.4036V16.4186C21.7086 16.4336 21.7086 16.4486 21.7086 16.4636C21.7086 16.4936 21.7237 16.5235 21.7237 16.5535V16.5835C21.7237 16.5835 21.7237 16.5835 21.7237 16.5985C21.7237 16.6135 21.7237 16.6285 21.7388 16.6435C21.7388 16.6735 21.7539 16.7035 21.7539 16.7334L21.769 16.7634V16.7784C21.769 16.8084 21.7841 16.8384 21.7992 16.8684C21.7992 16.8834 21.8143 16.8984 21.8143 16.9134V16.9283C21.8294 16.9583 21.8294 16.9733 21.8445 17.0033C21.8445 17.0183 21.8596 17.0333 21.8748 17.0633V17.0783C21.8748 17.0933 21.8899 17.1083 21.8899 17.1232C21.905 17.1382 21.905 17.1532 21.905 17.1532C21.9201 17.1982 21.9503 17.2432 21.9805 17.2882L21.9956 17.3032C22.0258 17.3481 22.0409 17.3781 22.0712 17.4081C22.0712 17.4231 22.0863 17.4231 22.0863 17.4381L22.1014 17.4531C22.1165 17.4831 22.1467 17.4981 22.1618 17.528C22.1769 17.543 22.1769 17.558 22.192 17.558C22.2223 17.588 22.2374 17.603 22.2525 17.633C22.2676 17.648 22.2827 17.648 22.2827 17.663H20.6963L19.7747 16.1487L20.6812 14.5295H22.2676C22.2525 14.5445 22.2525 14.5445 22.2374 14.5595L22.2223 14.5745C22.2071 14.5895 22.192 14.6045 22.1618 14.6345C22.1618 14.6345 22.1769 14.6345 22.1618 14.6345Z"
        fill="#76E4B2"
      />
      <path
        d="M13.1114 23.2552C12.9452 23.2552 12.7639 23.2252 12.5825 23.1652C12.3861 23.1053 12.1897 23.0153 12.0084 22.9104C11.4947 22.5955 11.1321 22.1457 11.0717 21.7259C11.0415 21.531 11.0868 21.3661 11.1926 21.2462C11.2077 21.2162 11.2379 21.2012 11.253 21.1712C11.2681 21.1562 11.2832 21.1562 11.2983 21.1412C11.3134 21.1262 11.3134 21.1262 11.3285 21.1113C11.3588 21.0963 11.3739 21.0813 11.4041 21.0663C11.7214 20.9164 12.2199 20.9463 12.7034 21.1712L12.7185 21.1862C12.7639 21.2012 12.8092 21.2312 12.8545 21.2462C12.8998 21.2612 12.93 21.2912 12.9754 21.3212C13.0207 21.3511 13.066 21.3811 13.1114 21.4111C13.1567 21.4411 13.1869 21.4711 13.2322 21.5011L13.2624 21.5161C13.2927 21.546 13.3229 21.561 13.3531 21.591C13.3682 21.606 13.3682 21.606 13.3833 21.621C13.4135 21.636 13.4286 21.666 13.4437 21.681C13.4588 21.696 13.474 21.711 13.4891 21.7409C13.5042 21.7709 13.5344 21.7859 13.5495 21.8159C13.5646 21.8309 13.5646 21.8309 13.5797 21.8459C13.5948 21.8759 13.625 21.8909 13.6402 21.9209L13.6704 21.9508C13.6855 21.9808 13.7006 21.9958 13.7157 22.0258V22.0408L13.7308 22.0708C13.761 22.1008 13.7761 22.1457 13.7912 22.1757C13.8063 22.2057 13.8215 22.2507 13.8366 22.2807L13.8517 22.2957C13.8517 22.3107 13.8668 22.3256 13.8668 22.3256C13.8668 22.3406 13.8668 22.3406 13.8819 22.3556C13.8819 22.3856 13.897 22.4006 13.897 22.4306C13.897 22.4606 13.9121 22.4606 13.9121 22.4756C13.9121 22.4906 13.9121 22.5056 13.9121 22.5056C13.9121 22.5355 13.9121 22.5505 13.9121 22.5655V22.5955C13.9121 22.6255 13.9121 22.6405 13.9121 22.6705C13.9121 22.6855 13.9121 22.6855 13.9121 22.7005C13.9121 22.7155 13.9121 22.7304 13.9121 22.7304C13.9121 22.7454 13.9121 22.7454 13.9121 22.7604V22.7754C13.9121 22.8054 13.897 22.8204 13.8819 22.8504C13.8668 22.8804 13.8517 22.8954 13.8366 22.9254C13.7308 23.1502 13.4437 23.2702 13.1114 23.2552Z"
        fill="#76E4B2"
      />
      <path
        d="M14.214 22.1608C14.214 22.1308 14.1989 22.1158 14.1838 22.0858C14.1838 22.0708 14.1838 22.0708 14.1687 22.0558C14.1536 22.0108 14.1384 21.9808 14.1082 21.9359C13.942 21.621 13.6701 21.3212 13.3377 21.0813C13.2924 21.0513 13.247 21.0213 13.2017 20.9913C13.1564 20.9614 13.1111 20.9314 13.0657 20.9164H13.0506C13.0355 20.9014 13.0204 20.9014 13.0053 20.8864C12.9751 20.8714 12.96 20.8564 12.9298 20.8414H12.9146C12.8995 20.8414 12.8844 20.8264 12.8693 20.8264C12.8391 20.8114 12.8089 20.7964 12.7787 20.7964L12.7485 20.7814C12.7485 20.7814 12.7485 20.7814 12.7333 20.7814C12.7182 20.7814 12.7031 20.7665 12.688 20.7665C12.6578 20.7515 12.6276 20.7515 12.5974 20.7365L12.5672 20.7215H12.552C12.5218 20.7065 12.4916 20.7065 12.4614 20.6915C12.4463 20.6915 12.4312 20.6765 12.401 20.6765H12.3858C12.3556 20.6765 12.3254 20.6615 12.3103 20.6615C12.2952 20.6615 12.265 20.6615 12.2499 20.6465H12.2348C12.2197 20.6465 12.2045 20.6465 12.1894 20.6315C12.1743 20.6315 12.1592 20.6315 12.1441 20.6315C12.0988 20.6315 12.0384 20.6165 11.993 20.6165H11.9779C11.9326 20.6165 11.8873 20.6165 11.8419 20.6165C11.8268 20.6165 11.8117 20.6165 11.8117 20.6165H11.7966C11.7664 20.6165 11.7362 20.6165 11.706 20.6315C11.6909 20.6315 11.6757 20.6315 11.6606 20.6465C11.6304 20.6465 11.6002 20.6615 11.57 20.6615C11.5549 20.6615 11.5398 20.6765 11.5247 20.6765L12.3556 19.3422L14.1384 19.3572L15.0601 20.9763L14.2291 22.3257C14.2291 22.3107 14.2291 22.2957 14.214 22.2807C14.214 22.2657 14.214 22.2657 14.214 22.2507C14.214 22.2357 14.1989 22.2207 14.1989 22.1907C14.214 22.1757 14.214 22.1608 14.214 22.1608Z"
        fill="#76E4B2"
      />
      <path
        d="M17.3722 24.0499L13.0209 24.0349L12.3561 25.2793L11.9028 26.1339L13.0662 26.1189V26.1339L19.714 26.1489L20.3788 25.0094L15.5893 16.8685L6.08603 16.8535L5.48169 17.903L5.60256 18.0979H5.58745L9.3646 24.6946L10.6186 22.6706L8.41276 18.9525H14.3807L17.3722 24.0499Z"
        fill="#76E4B2"
      />
    </svg>
  );
};
