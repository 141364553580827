export const EvmLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13672_3278)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#627EEA"
        />
        <path
          d="M16 4V12.87L23.497 16.22L16 4Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path d="M16.498 4L9 16.22L16.498 12.87V4Z" fill="white" />
        <path
          d="M16 22.352V28.379L23.502 18L16 22.352Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path d="M16.498 28.379V22.351L9 18L16.498 28.379Z" fill="white" />
        <path
          d="M16 20.701L23.497 16.348L16 13V20.701Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M9 16.348L16.498 20.701V13L9 16.348Z"
          fill="white"
          fillOpacity="0.602"
        />
      </g>
      <defs>
        <clipPath id="clip0_13672_3278">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
