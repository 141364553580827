export const ArbitrumLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#2F3749" />
      <path
        d="M19.1286 14.8323L21.2004 11.3168L26.7846 20.0148L26.7872 21.684L26.769 10.1975C26.7558 9.91671 26.6067 9.65984 26.3688 9.50837L16.3151 3.72506C16.08 3.60949 15.7817 3.61072 15.547 3.72865C15.5153 3.74458 15.4854 3.76183 15.457 3.78065L15.422 3.80272L5.66319 9.45812L5.62528 9.47528C5.5766 9.49769 5.5274 9.52615 5.48118 9.55924C5.29619 9.69197 5.17327 9.88817 5.13353 10.1083C5.12757 10.1416 5.12319 10.1756 5.12109 10.2098L5.13641 19.5703L10.3379 11.5079C10.9927 10.4388 12.4196 10.0944 13.7441 10.1132L15.2987 10.1542L6.1391 24.8443L7.21883 25.466L16.4882 10.1691L20.5853 10.1542L11.3398 25.8371L15.1926 28.0532L15.6529 28.318C15.8476 28.3971 16.0772 28.4011 16.2735 28.3302L26.4686 22.4218L24.5194 23.5513L19.1286 14.8323ZM19.919 26.2174L16.0276 20.1097L18.403 16.0786L23.5136 24.1341L19.919 26.2174Z"
        fill="#2D374B"
      />
      <path
        d="M16.0281 20.1109L19.9195 26.2187L23.5142 24.1354L18.4036 16.0798L16.0281 20.1109Z"
        fill="#28A0F0"
      />
      <path
        d="M26.7876 21.6823L26.785 20.0132L21.2007 11.3151L19.1289 14.8306L24.5197 23.5496L26.4689 22.4201C26.6601 22.2649 26.7758 22.037 26.7879 21.7911L26.7876 21.6823Z"
        fill="#28A0F0"
      />
      <path
        d="M3.38681 23.2577L6.13931 24.8437L15.2989 10.1537L13.7443 10.1127C12.4198 10.0939 10.993 10.4383 10.3381 11.5074L5.13662 19.5697L3.38672 22.2585V23.2577H3.38681Z"
        fill="white"
      />
      <path
        d="M20.5851 10.1533L16.4881 10.1682L7.21875 25.4651L10.4586 27.3306L11.3397 25.8362L20.5851 10.1533Z"
        fill="white"
      />
      <path
        d="M28.514 10.133C28.4797 9.27614 28.0158 8.49167 27.2891 8.035L17.1036 2.17736C16.3848 1.81533 15.4867 1.81489 14.7667 2.17709C14.6816 2.21999 4.86146 7.91558 4.86146 7.91558C4.72559 7.98072 4.5947 8.05838 4.47152 8.14646C3.82279 8.61144 3.42734 9.33392 3.38672 10.1278V22.259L5.13662 19.5702L5.1213 10.2098C5.1234 10.1756 5.12769 10.1419 5.13373 10.1087C5.17322 9.88838 5.29622 9.69192 5.48139 9.55919C5.52761 9.5261 15.5154 3.74453 15.5472 3.7286C15.782 3.61067 16.0803 3.60944 16.3153 3.72501L26.369 9.50832C26.6069 9.65979 26.756 9.91666 26.7692 10.1974V21.7927C26.7571 22.0386 26.6599 22.2664 26.4687 22.4218L24.5195 23.5513L23.5139 24.1341L19.9191 26.2174L16.2737 28.3302C16.0773 28.4011 15.8477 28.3972 15.653 28.3179L11.3399 25.8371L10.4588 27.3314L14.3349 29.5632C14.4631 29.636 14.5773 29.7006 14.671 29.7533C14.8162 29.8348 14.915 29.8891 14.9499 29.906C15.2254 30.0398 15.6218 30.1177 15.979 30.1177C16.3065 30.1177 16.6258 30.0576 16.928 29.9392L27.5164 23.8068C28.1242 23.336 28.4817 22.6258 28.514 21.8565V10.133V10.133Z"
        fill="#96BEDC"
      />
    </svg>
  );
};
