export const BItTorrentLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="15.25"
        fill="white"
        stroke="black"
        strokeWidth="1.5"
      />
      <mask
        id="mask0_13668_3442"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="26"
        height="26"
      >
        <circle cx="16" cy="16" r="12.8" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13668_3442)">
        <path
          d="M20 10.4002C15.6001 9.20001 8.80005 10.7755 8.80005 18.8011C8.80005 26.0001 17.6 28.4001 26.4 27.2001"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          d="M17.2 7.19923C11.6 7.19923 6 10.7746 6 18.8001C6 25.9992 15.2 30.8001 23.6 29.6001"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          d="M21.6001 15.6005C20.4001 12.0005 11.6001 11.2001 11.6001 18.8003C11.6001 25.2002 20.8001 26.0002 29.2001 24.8002"
          stroke="black"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
