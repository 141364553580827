export const PalmLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13696_3327)">
        <mask
          id="mask0_13696_3327"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path d="M32 0H0V32H32V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_13696_3327)">
          <path d="M32 0H0V32H32V0Z" fill="#171717" />
          <path
            d="M22.3701 10.7556C22.6458 10.7556 22.8816 10.849 23.0684 11.0358C23.2552 11.2227 23.3487 11.4584 23.3487 11.7342V16.9563C23.3487 17.2188 23.2552 17.4456 23.0684 17.6413C22.8816 17.8371 22.6458 17.9349 22.3701 17.9349H8.99002V18.3264C8.99002 18.5399 9.12791 18.7845 9.40815 19.0603L11.5388 21.191C12.0281 21.6803 12.2728 22.263 12.2728 22.9302C12.2728 23.8376 11.5388 24.5716 10.6314 24.5716H10.3556C9.4126 24.5716 8.64307 23.8065 8.64307 22.859V16.0133V15.8932C8.64307 14.9858 9.37701 14.2518 10.2844 14.2518H14.7459C15.4309 14.2518 16.027 14.5054 16.5341 15.0125L18.7137 17.2188C18.9895 17.4945 19.2386 17.6369 19.4476 17.6369H19.719L19.7457 11.0937H10.3556C9.4126 11.0937 8.64307 10.3286 8.64307 9.38112V9.12758C8.64307 8.18457 9.40815 7.41504 10.3556 7.41504H14.7237C15.4754 7.41504 16.0804 7.65969 16.5385 8.14898L18.6959 10.3286C18.9717 10.6044 19.2252 10.7467 19.4565 10.7467H22.3745L22.3701 10.7556Z"
            fill="#F5F5F5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_13696_3327">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
