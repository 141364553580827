export const ArbtrumNovaLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M12.7341 8.7467H10.7803C10.6454 8.7467 10.525 8.83108 10.4787 8.9578L5.34311 23.0365C5.30492 23.1413 5.38247 23.2521 5.49393 23.2521H7.44775C7.58268 23.2521 7.70311 23.1677 7.7494 23.0411L12.885 8.96228C12.9232 8.85753 12.8456 8.7467 12.7341 8.7467ZM15.1106 14.6458C15.0593 14.5051 14.8603 14.5051 14.8089 14.6458L13.796 17.423C13.7701 17.494 13.7701 17.572 13.796 17.643L15.7651 23.041C15.8113 23.1677 15.9318 23.252 16.0667 23.252H18.0205C18.132 23.252 18.2096 23.1412 18.1714 23.0364L15.1106 14.6458ZM20.0914 17.3641C20.1427 17.5048 20.3416 17.5048 20.3929 17.3641L23.4577 8.96228C23.4959 8.85753 23.4183 8.7467 23.3068 8.7467H21.353C21.2181 8.7467 21.0977 8.83097 21.0514 8.9578L19.0784 14.3668C19.0524 14.4378 19.0524 14.5157 19.0784 14.5867L20.0914 17.3641ZM16.239 8.9563C16.1932 8.83044 16.0736 8.7467 15.9397 8.7467H13.9793C13.8444 8.7467 13.724 8.83097 13.6777 8.95769L8.54225 23.0365C8.50407 23.1413 8.58161 23.2521 8.69308 23.2521H10.647C10.7819 23.2521 10.9024 23.1678 10.9487 23.0411L14.8806 12.2616C14.9064 12.1909 15.0064 12.1909 15.0321 12.2616L18.9641 23.0411C19.0103 23.1678 19.1308 23.2521 19.2658 23.2521H21.2197C21.3312 23.2521 21.4087 23.1413 21.3705 23.0366L16.239 8.9563ZM26.506 8.7467H24.552C24.4171 8.7467 24.2966 8.83097 24.2504 8.95769L20.6778 18.7518C20.6519 18.8229 20.6519 18.9007 20.6778 18.9718L21.6909 21.7492C21.7423 21.8898 21.9412 21.8898 21.9925 21.7492L25.529 12.0539L26.6568 8.96217C26.695 8.85753 26.6175 8.7467 26.506 8.7467Z"
        fill="#EF8220"
      />
    </svg>
  );
};
