import React from "react";
import { css } from "@emotion/css";

const balanceStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: "Open Sans", sans-serif;
  `,
  title: css`
    color: #c1c1c899;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.43px;
  `,
  value: (color: string) => css`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.43px;
    color: ${color};
  `,
};

interface BalanceInfoProps {
  title: string;
  value: string;
  subValue?: string;
  color?: "green" | "red" | "white";
  showSign?: boolean;
}

export const BalanceInfo: React.FC<BalanceInfoProps> = ({
  title,
  value,
  subValue,
  color = "white",
  showSign = true,
}) => {
  const colorMap = {
    green: "#8DE9C6",
    red: "#FFB7B7",
    white: "#FFFFFF",
  };

  const adding = {
    white: "",
    red: "-",
    green: "+",
  };

  return (
    <div className={balanceStyles.container}>
      <div className={balanceStyles.title}>{title}</div>
      <div className={balanceStyles.value(colorMap[color])}>
        {(showSign ? adding[color] : "") +
          value.replace("-", "") +
          (subValue
            ? "(" +
              (showSign ? adding[color] : "") +
              subValue.replace("-", "") +
              ")"
            : "")}
      </div>
    </div>
  );
};
