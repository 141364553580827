export const TelosLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13668_3392)">
        <g filter="url(#filter0_ii_13668_3392)">
          <circle cx="16" cy="16" r="16" fill="#8865D3" />
        </g>
        <path
          d="M7.88657 16.9664C7.88657 18.9415 8.9557 21.8836 9.83295 24.9585C9.90037 25.1948 10.1373 25.3384 10.3762 25.2808C11.9495 24.9014 17.4209 23.531 18.5968 22.5511C19.7425 21.5963 20.3586 20.8005 20.5333 20.56C20.5679 20.5123 20.6179 20.4783 20.6755 20.4653L23.1887 19.8999C23.4646 19.8378 23.6745 19.6134 23.7179 19.334L24.163 16.4729C24.1749 16.3962 24.1606 16.3177 24.1222 16.2501L21.0459 10.83C20.9967 10.7432 20.9875 10.6392 21.0208 10.5451L22.1191 7.44426C22.1946 7.23085 22.0472 7.00383 21.8215 6.98612L18.5467 6.72928C18.4343 6.72047 18.3247 6.76724 18.2533 6.85449L17.0936 8.27199C17.0282 8.3519 16.9304 8.39825 16.8271 8.39825H10.4685C10.3824 8.39825 10.2994 8.43053 10.2359 8.48873L7.94051 10.5928C7.72491 10.7905 7.6577 11.1028 7.77292 11.3716L8.67992 13.4879C8.71003 13.5582 8.70374 13.6383 8.66645 13.705C8.47235 14.0523 7.88657 15.2431 7.88657 16.9664Z"
          stroke="white"
          strokeWidth="1.14753"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_13668_3392"
          x="-6"
          y="0"
          width="38"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-6" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.211765 0 0 0 0 0.184314 0 0 0 0 0.52549 0 0 0 0.7 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_13668_3392"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.578697 0 0 0 0 0.458646 0 0 0 0 0.925 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_13668_3392"
            result="effect2_innerShadow_13668_3392"
          />
        </filter>
        <clipPath id="clip0_13668_3392">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
