import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs } from "./uiKit/Tabs";
import { Dropdown } from "./uiKit/DropDown";
import { BalanceInfo } from "./BalanceInfo";
import {
  HYPERLIQUID,
  PERP,
  PORTFOLIO,
  SPOT,
  TOTAL,
  VAULT,
} from "../shared/constants/routes";
import { TotalBalance } from "./TotalBalance";
import { EmptyState } from "./EmptyState";
import { IHyperLiquidData } from "../shared/intefaces/IHyperLiquidPosition";
import { HyperLiquidSpotCard } from "./HyperLiquidSpotCard";
import { HyperLiquidPerpCard } from "./HyperLiquidPerpCard";
import { HyperLiquidVaultCard } from "./HyperLiquidVaultCard";
import { DEFAULT_HL_STATS_VALUE } from "../shared/utils/fetchHyperLiquidData";
import { CountHyperLiquidStats } from "../shared/utils/CountHyperLiquidStats";
import { getFormattedPnlLikeValue } from "../shared/utils/getFormattedPnlLikeValue";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
  `,
  statsWrapper: css`
    background: #1c1c1c;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #7c7c7c52;
    padding: 12px 16px;
  `,
  statsGrid: css`
    display: grid;
    padding: 16px;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  `,
  tabContainer: css`
    margin-top: 16px;
  `,
  listContainer: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
};

interface IHyperliquidListProps {
  data: IHyperLiquidData[];
}

export const HyperliquidList: React.FC<IHyperliquidListProps> = ({ data }) => {
  const { type, subtab } = useParams();
  const navigate = useNavigate();
  const [selectedStats, setSelectedStats] = useState(TOTAL);
  const [countedStats, setCountedStats] = useState<IHyperLiquidData>(
    DEFAULT_HL_STATS_VALUE,
  );

  useEffect(() => {
    const stats = CountHyperLiquidStats(data);
    if (stats !== null) {
      setCountedStats(stats);
    }
  }, [data]);

  const tabs = [
    { label: "Spot", value: SPOT },
    { label: "Perp", value: PERP },
    { label: "Vaults", value: VAULT },
  ];

  const activeTabIndex = tabs.findIndex((t) => t.value === subtab);
  const activeTab = activeTabIndex >= 0 ? activeTabIndex : 0;

  const handleTabChange = (newIndex: number) => {
    const newSubtab = tabs[newIndex].value;
    navigate(`/${PORTFOLIO}/${type}/${HYPERLIQUID}/${newSubtab}`);
  };

  const filterOptions = [
    { label: "Total", value: TOTAL },
    { label: "Spot", value: SPOT },
    { label: "Perp", value: PERP },
    { label: "Vaults", value: VAULT },
  ];

  if (data.every((x) => x.generalAnalytics.totalBalance === 0)) {
    return <EmptyState />;
  }
  return (
    <div className={styles.container}>
      {selectedStats === TOTAL && (
        <div className={styles.statsWrapper}>
          <div className={styles.header}>
            <TotalBalance
              value={getFormattedPnlLikeValue(
                countedStats.generalAnalytics.totalBalance,
                true,
              )}
              size="medium"
            />
            <Dropdown
              options={filterOptions}
              selectedValue={selectedStats}
              onChange={setSelectedStats}
            />
          </div>
          <div className={styles.statsGrid}>
            <BalanceInfo
              title="Total value"
              value={getFormattedPnlLikeValue(
                countedStats.generalAnalytics.totalBalance,
                true,
              )}
            />
            <BalanceInfo
              title="Positions value"
              value={getFormattedPnlLikeValue(
                countedStats.generalAnalytics.totalInPositions,
                true,
              )}
            />
            <BalanceInfo
              title="USDC balance"
              value={getFormattedPnlLikeValue(
                countedStats.generalAnalytics.freeBalance,
                false,
              )}
            />
            <BalanceInfo
              title="PNL"
              value={getFormattedPnlLikeValue(
                countedStats.generalAnalytics.pnl,
                true,
              )}
              color={
                countedStats.generalAnalytics.pnl === 0
                  ? "white"
                  : countedStats.generalAnalytics.pnl > 0
                    ? "green"
                    : "red"
              }
            />
            <BalanceInfo
              title="Win rate"
              value={
                getFormattedPnlLikeValue(
                  (countedStats.generalAnalytics.profitDealsCount /
                    countedStats.generalAnalytics.dealsCount) *
                    100 || 0,
                  false,
                ) + "%"
              }
            />
          </div>
        </div>
      )}

      {selectedStats === SPOT && (
        <div className={styles.statsWrapper}>
          <div className={styles.header}>
            <TotalBalance
              value={getFormattedPnlLikeValue(
                countedStats.spot.totalBalance,
                true,
              )}
              size="medium"
            />
            <Dropdown
              options={filterOptions}
              selectedValue={selectedStats}
              onChange={setSelectedStats}
            />
          </div>
          <div className={styles.statsGrid}>
            <BalanceInfo
              title="Spot value"
              value={getFormattedPnlLikeValue(
                countedStats.spot.totalBalance,
                true,
              )}
            />
            <BalanceInfo
              title="Positions value"
              value={getFormattedPnlLikeValue(
                countedStats.spot.totalInPositions,
                true,
              )}
            />
            <BalanceInfo
              title="USDC balance"
              value={getFormattedPnlLikeValue(
                countedStats.spot.freeBalance,
                false,
              )}
            />
            <BalanceInfo
              title="PNL(ROI %)"
              value={getFormattedPnlLikeValue(countedStats.spot.pnl, true)}
              subValue={
                getFormattedPnlLikeValue(countedStats.spot.roi, false) + "%"
              }
              color={
                countedStats.spot.pnl === 0
                  ? "white"
                  : countedStats.spot.pnl > 0
                    ? "green"
                    : "red"
              }
            />
            <BalanceInfo
              title="Volume"
              value={getFormattedPnlLikeValue(countedStats.spot.volume, true)}
            />
            <BalanceInfo
              title="Win rate"
              value={
                getFormattedPnlLikeValue(
                  (countedStats.spot.profitDealsCount /
                    countedStats.spot.dealsCount) *
                    100 || 0,
                  false,
                ) + "%"
              }
            />
          </div>
        </div>
      )}

      {selectedStats === PERP && (
        <div className={styles.statsWrapper}>
          <div className={styles.header}>
            <TotalBalance
              value={getFormattedPnlLikeValue(
                countedStats.perp.totalBalance,
                true,
              )}
              size="medium"
            />
            <Dropdown
              options={filterOptions}
              selectedValue={selectedStats}
              onChange={setSelectedStats}
            />
          </div>
          <div className={styles.statsGrid}>
            <BalanceInfo
              title="Perp value"
              value={getFormattedPnlLikeValue(
                countedStats.perp.totalBalance,
                true,
              )}
            />
            <BalanceInfo
              title="Positions value"
              value={getFormattedPnlLikeValue(
                countedStats.perp.totalInPositions,
                true,
              )}
            />
            <BalanceInfo
              title="USDC balance"
              value={getFormattedPnlLikeValue(
                countedStats.perp.freeBalance,
                false,
              )}
            />
            <BalanceInfo
              title="PNL(ROI %)"
              value={getFormattedPnlLikeValue(countedStats.perp.pnl, true)}
              subValue={
                getFormattedPnlLikeValue(countedStats.perp.roi, false) + "%"
              }
              color={
                countedStats.perp.pnl === 0
                  ? "white"
                  : countedStats.perp.pnl > 0
                    ? "green"
                    : "red"
              }
            />
            <BalanceInfo
              title="Volume"
              value={getFormattedPnlLikeValue(countedStats.perp.volume, true)}
            />
            <BalanceInfo
              title="Win rate"
              value={
                getFormattedPnlLikeValue(
                  (countedStats.perp.profitDealsCount /
                    countedStats.perp.dealsCount) *
                    100 || 0,
                  false,
                ) + "%"
              }
            />
          </div>
        </div>
      )}

      {selectedStats === VAULT && (
        <div className={styles.statsWrapper}>
          <div className={styles.header}>
            <TotalBalance
              value={getFormattedPnlLikeValue(
                countedStats.vault.totalBalance,
                true,
              )}
              size="medium"
            />
            <Dropdown
              options={filterOptions}
              selectedValue={selectedStats}
              onChange={setSelectedStats}
            />
          </div>
          <div className={styles.statsGrid}>
            <BalanceInfo
              title="Total value"
              value={getFormattedPnlLikeValue(
                countedStats.vault.totalBalance,
                true,
              )}
            />
            <BalanceInfo
              title="PNL(ROI %)"
              value={getFormattedPnlLikeValue(countedStats.vault.pnl, true)}
              subValue={
                getFormattedPnlLikeValue(countedStats.vault.roi, false) + "%"
              }
              color={
                countedStats.vault.pnl === 0
                  ? "white"
                  : countedStats.vault.pnl > 0
                    ? "green"
                    : "red"
              }
            />
          </div>
        </div>
      )}

      <div className={styles.tabContainer}>
        <Tabs
          tabs={tabs}
          activeIndex={activeTab}
          onTabChange={handleTabChange}
          variant="primary"
        />
      </div>

      <div className={styles.listContainer}>
        {activeTab === 0 &&
          (data.some((item) => item.spot.positions.length > 0) ? (
            data.flatMap((item) =>
              item.spot.positions.map((position, index) => (
                <HyperLiquidSpotCard
                  key={index}
                  position={position}
                  walletName={item.walletName}
                />
              )),
            )
          ) : (
            <EmptyState />
          ))}

        {activeTab === 1 &&
          (data.some((item) => item.perp.positions.length > 0) ? (
            data.flatMap((item) =>
              item.perp.positions.map((position, index) => (
                <HyperLiquidPerpCard
                  key={index}
                  position={position}
                  walletName={item.walletName}
                />
              )),
            )
          ) : (
            <EmptyState />
          ))}

        {activeTab === 2 &&
          (data.some((item) => item.vault.positions.length > 0) ? (
            data.flatMap((item) =>
              item.vault.positions.map((position, index) => (
                <HyperLiquidVaultCard
                  key={index}
                  position={position}
                  walletName={item.walletName}
                />
              )),
            )
          ) : (
            <EmptyState />
          ))}
      </div>
    </div>
  );
};
