import React from "react";
import { css } from "@emotion/css";

const totalBalanceStyles = {
  container: css`
    display: flex;
    //background: #1c1c1c;
    //border-radius: 0 0 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    //padding: 24px 16px 16px;
  `,
  value: (size: "big" | "medium" | "small") => css`
    font-family: "Space Mono", sans-serif;
    font-style: normal;
    font-weight: 700;
    ${size === "big"
      ? "font-size: 24px; line-height: 30px; color: #CEBCFF;"
      : ""}
    ${size === "medium" ? "font-size: 20px; color: #CEBCFF;" : ""}
        ${size === "small"
      ? "line-height: 20px; font-size: 16px; color: #FFF;"
      : ""}
  `,
};

interface TotalBalanceProps {
  value: string;
  loading?: boolean;
  size: "big" | "medium" | "small";
}

export const TotalBalance: React.FC<TotalBalanceProps> = ({
  value,
  loading = false,
  size,
}) => {
  return (
    <div className={totalBalanceStyles.container}>
      <div className={totalBalanceStyles.value(size)}>
        {loading ? "⏳" : `${value}`}
      </div>
    </div>
  );
};
