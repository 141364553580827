import { IUserWallet } from "../intefaces/IUserWallet";

export const walletDefaulValue: IUserWallet = {
  databaseId: undefined,
  id: Date.now(),
  chain: null,
  address: "",
  name: "",
  isMyWallet: true,
  isNew: true,
  errors: { address: false, name: false, duplicate: false },
};
