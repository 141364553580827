export const AUTHORIZATION = "authorization";

export const PORTFOLIO = "portfolio";

export const MANAGE_WALLETS = "manage-wallets";

export const WALLETS_FILTER = "wallets-filter";

export const MY_WALLETS = "my-wallets";

export const WATCHLIST = "watchlist";

export const CRYPTOCURRENCY = "cryptocurrency";

export const NFT = "nft";

export const PROTOCOLS = "protocols";

export const HYPERLIQUID = "hyperliquid";

export const TOTAL = "total";

export const SPOT = "spot";

export const PERP = "perp";

export const VAULT = "vault";

export const DEFAULT_TAB = CRYPTOCURRENCY;

export const DEFAULT_SUBTAB = "content";

export const DEFAULT_HYPERLIQUID_SUBTAB = SPOT;
