import React from "react";
import { css } from "@emotion/css";
import { NetworkInfo } from "../NetworkInfo";

export const InputStyles = {
  inputWrapper: (hasError: boolean) => css`
    width: 100%;
    gap: 16px;
    display: flex;
    justify-content: space-between;
    padding: 14px 16px;
    font-size: 16px;
    border: 1px solid ${hasError ? "#F7C5C5" : "#1C1C1C"};
    font-family: "Open Sans", sans-serif;
    border-radius: 8px;
    background: #1c1c1c;
    font-weight: 400;
    color: #ffffff;
    outline: none;
    box-sizing: border-box;
    transition: border-color 200ms ease-out;
    align-items: center;

    &::placeholder {
      color: #c1c1c899;
    }

    &:hover {
      border-color: ${hasError ? "#F7C5C5" : "#FFFFFF66"};
    }
  `,
  input: css`
    width: 100%;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #ffffff;
    box-sizing: border-box;
    background: transparent;
    border: none;
    outline: none;
    transition: border-color 200ms ease-out;
    text-overflow: ellipsis;

    &::placeholder {
      color: #c1c1c899;
    }
  `,
  networkContainer: css``,
  errorText: css`
    font-family: "Open Sans", sans-serif;
    color: #f7c5c5;
    padding-top: 8px;
    padding-left: 16px;
    font-size: 14px;
  `,
};

type InputProps = {
  chain?: string | null;
  value: string;
  placeholder?: string;
  hasError: boolean;
  errorText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input: React.FC<InputProps> = ({
  chain = null,
  value,
  placeholder,
  hasError,
  errorText,
  onChange,
}) => {
  return (
    <div>
      <div className={InputStyles.inputWrapper(hasError)}>
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          className={InputStyles.input}
        />
        {chain && (
          <div className={InputStyles.networkContainer}>
            <NetworkInfo chain={chain} />
          </div>
        )}
      </div>
      {hasError && errorText && (
        <div className={InputStyles.errorText}>{errorText}</div>
      )}
    </div>
  );
};
