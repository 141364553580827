import React from "react";
import {css} from "@emotion/css";

const tabsStyles = {
    container: (variant: "primary" | "secondary") => css`
        display: flex;
        ${variant === "primary" ? "gap: 20px;" : "gap: 4px;"}`,
    primaryTab: (isActive: boolean) => css`
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.43px;
        cursor: pointer;
        padding-bottom: 8px;
        color: ${isActive ? "#ffffff" : "#C1C1C899"};
        border-bottom: ${isActive ? "2px solid #CEBCFF" : "2px solid transparent"};
        transition: color 0.3s ease-out, border-bottom 0.3s ease-out;
    `,
    secondaryTab: (isActive: boolean) => css`
        display: inline-flex;
        align-items: center;
        padding: 8px 12px;
        border-radius: 8px;
        font-family: "Open Sans", sans-serif;
        cursor: pointer;
        background-color: ${isActive ? "#262626" : "transparent"};
        color: ${isActive ? "#ffffff" : "rgba(193, 193, 200, 0.60)"};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.43px;
        transition: background-color 0.3s ease-out, color 0.3s ease-out;
    `,
};

interface ITab {
    label: React.ReactNode;
}

interface ITabsProps {
    tabs: ITab[];
    activeIndex: number;
    onTabChange: (index: number) => void;
    variant?: "primary" | "secondary";
}

export const Tabs: React.FC<ITabsProps> = ({
                                               tabs,
                                               activeIndex,
                                               onTabChange,
                                               variant = "primary",
                                           }) => {
    return (
        <div className={tabsStyles.container(variant)}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={
                        variant === "primary"
                            ? tabsStyles.primaryTab(activeIndex === index)
                            : tabsStyles.secondaryTab(activeIndex === index)
                    }
                    onClick={() => onTabChange(index)}
                >
                    {tab.label}
                </div>
            ))}
        </div>
    );
};