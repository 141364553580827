export const KavaLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#FF433E" />
      <path d="M11.2535 6.66663H8V25.3307H11.2535V6.66663Z" fill="white" />
      <path
        d="M20.1623 25.3335L13.0247 15.9988L20.1623 6.66943H24.2462L17.2084 15.9988L24.2462 25.3335H20.1623Z"
        fill="white"
      />
    </svg>
  );
};
