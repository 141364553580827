export const ConfluxLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_13668_3596)" />
      <path
        d="M20.5532 18.0422L16.0025 22.5929L13.4699 20.0602L18.0205 15.5096L15.9967 13.4857L9.40007 20.0821L15.9785 26.6603L22.5749 20.0639L20.5532 18.0422Z"
        fill="white"
      />
      <path
        d="M24.6141 13.9285L16.0191 5.33337L7.3999 13.9526L7.42739 17.9812L15.9825 9.42633L24.5997 18.0438L24.6141 13.9285Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13668_3596"
          x1="0"
          y1="7.33333"
          x2="32"
          y2="23.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#305398" />
          <stop offset="1" stopColor="#53AD76" />
        </linearGradient>
      </defs>
    </svg>
  );
};
