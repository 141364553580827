import React from "react";
import { css } from "@emotion/css";
import { ProtocolInfo } from "./ProtocolInfo";
import { IProtocol } from "../shared/intefaces/IProtocol";
import { EmptyState } from "./EmptyState";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { sumArray } from "../shared/utils/sumArray";
import { TotalBalance } from "./TotalBalance";

const protocolListStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: white;
    padding-bottom: 60px;
  `,
  valueContainer: css`
    display: flex;
    margin-bottom: 24px;
  `,
  valueText: css`
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: -0.43px;
    color: #c1c1c899;
    margin-right: 8px;
  `,
};

interface ProtocolListProps {
  data: IProtocol[];
}

export const ProtocolList: React.FC<ProtocolListProps> = ({ data }) => {
  return (
    <div>
      {data.length > 0 && (
        <div className={protocolListStyles.valueContainer}>
          <div className={protocolListStyles.valueText}>{"Value:"}</div>
          <TotalBalance
            value={getFormattedValue(sumArray(data.map((x) => x.value)), true)}
            size={"small"}
          />
        </div>
      )}
      <div className={protocolListStyles.container}>
        {data.map((protocol, index) => (
          <ProtocolInfo
            protocolLogo={protocol.image}
            positions={protocol.positions}
            networkLogo={protocol.chainLogo}
            protocolName={protocol.name}
            value={protocol.value}
            tag={protocol.walletName}
            key={index}
          />
        ))}
        {data.length === 0 && <EmptyState />}
      </div>
    </div>
  );
};
