import { BACKEND_API } from "../constants/urls";
import { IToken } from "../intefaces/IToken";

const tokenTypeConversion = (tokenObject: any, walletName: string): IToken => {
  return {
    address: tokenObject.address,
    symbol: (tokenObject.symbol || "unknown").toUpperCase(),
    price: tokenObject.price,
    amount: tokenObject.amount,
    value: tokenObject.amount * tokenObject.price,
    logoUrl: tokenObject.logo_url,
    chainLogo: tokenObject.chain_logo,
    chain: tokenObject.chain_name,
    walletName,
  };
};

export const fetchTokens = async (
  address: string,
  chain: string | null,
  token: string,
  walletName: string,
): Promise<IToken[]> => {
  try {
    const response = await fetch(`${BACKEND_API}/tokens/${chain}/${address}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return (
      data.result.map((x: any) => tokenTypeConversion(x, walletName)) || []
    );
  } catch (error) {
    return [];
  }
};
