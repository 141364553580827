export const MoonRiverLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M15.8724 5.38184C13.7765 5.38184 11.7664 6.22224 10.2844 7.71818C8.80233 9.21412 7.96973 11.2431 7.96973 13.3586C7.96987 13.8626 8.01672 14.3654 8.10967 14.8605L9.13064 14.1557C10.9256 12.9131 13.8366 12.9131 15.6315 14.1557L16.7028 14.8964L17.2847 15.2993C18.9024 16.418 21.5231 16.418 23.1394 15.2993L23.6157 14.9701C23.851 13.8107 23.8279 12.613 23.5482 11.4637C23.2685 10.3144 22.7391 9.24224 21.9983 8.3249C21.2575 7.40755 20.3239 6.66795 19.2651 6.15965C18.2062 5.65135 17.0487 5.38708 15.8762 5.38595L15.8724 5.38184Z"
        fill="#E9B840"
      />
      <path
        d="M20.3139 18.1507C19.1849 18.1702 18.0757 17.8503 17.1273 17.2318L15.4767 16.0899C14.5784 15.534 13.5451 15.2397 12.4914 15.2397C11.4376 15.2397 10.4044 15.534 9.50599 16.0899L8.43876 16.8274C8.38853 16.8621 8.33203 16.8865 8.27247 16.8991C8.21291 16.9118 8.15145 16.9125 8.09162 16.9012C8.03179 16.8899 7.97475 16.8668 7.92376 16.8333C7.87277 16.7997 7.82882 16.7563 7.79443 16.7056C7.76004 16.6549 7.73589 16.5979 7.72334 16.5378C7.71079 16.4777 7.71009 16.4156 7.72129 16.3552C7.73249 16.2948 7.75536 16.2373 7.78861 16.1858C7.82185 16.1343 7.86481 16.09 7.91504 16.0553L8.98198 15.3178C10.0351 14.656 11.2508 14.3052 12.4914 14.3052C13.7319 14.3052 14.9476 14.656 16.0007 15.3178L17.6513 16.4597C18.4527 16.9547 19.374 17.2166 20.3136 17.2166C21.2531 17.2166 22.1745 16.9547 22.9759 16.4597L23.6026 16.0271C23.6528 15.9924 23.7093 15.968 23.7689 15.9553C23.8284 15.9426 23.8899 15.9419 23.9497 15.9533C24.0095 15.9646 24.0666 15.9876 24.1176 16.0212C24.1686 16.0547 24.2125 16.0981 24.2469 16.1488C24.2813 16.1995 24.3055 16.2565 24.318 16.3167C24.3306 16.3768 24.3312 16.4388 24.32 16.4992C24.3088 16.5596 24.286 16.6172 24.2527 16.6686C24.2195 16.7201 24.1765 16.7645 24.1263 16.7992L23.4999 17.2321C22.5517 17.8506 21.4427 18.1703 20.3139 18.1507Z"
        fill="#74C8C7"
      />
      <path
        d="M12.4582 21.5005V16.9939C12.4548 16.8991 12.4588 16.8041 12.4702 16.7099C12.4841 16.5859 12.5427 16.4714 12.635 16.3882C12.7273 16.3051 12.8467 16.2593 12.9703 16.2594C13.0901 16.2627 13.2045 16.3105 13.2916 16.3936C13.3787 16.4767 13.4324 16.5893 13.4423 16.7099C13.4612 16.8664 13.4667 17.0242 13.4586 17.1816V26.1684C13.461 26.2383 13.4496 26.308 13.4251 26.3735C13.4006 26.4389 13.3635 26.4988 13.3159 26.5497C13.2683 26.6006 13.2111 26.6414 13.1477 26.6698C13.0844 26.6982 13.0161 26.7136 12.9468 26.7152C12.8773 26.7113 12.8094 26.6932 12.7471 26.6619C12.6848 26.6306 12.6295 26.5869 12.5845 26.5333C12.5395 26.4798 12.5058 26.4175 12.4855 26.3504C12.4651 26.2832 12.4585 26.2126 12.4661 26.1428V23.1472L12.4582 21.5005Z"
        fill="#74C8C7"
      />
      <path
        d="M11.5188 19.2321V21.4717C11.5188 21.8535 11.3152 22.1087 11.0292 22.0996C10.9547 22.0919 10.8825 22.0694 10.8167 22.0334C10.7509 21.9974 10.6927 21.9486 10.6457 21.8898C10.5986 21.831 10.5636 21.7635 10.5425 21.6909C10.5214 21.6184 10.5148 21.5424 10.5229 21.4673V16.9895C10.5229 16.5971 10.7493 16.3325 11.0437 16.3478C11.3382 16.3631 11.5142 16.6004 11.5157 16.9922C11.5203 17.739 11.5188 18.4855 11.5188 19.2321Z"
        fill="#74C8C7"
      />
      <path
        d="M14.4013 19.229V17.6546C14.4013 17.2895 14.5931 17.0537 14.8791 17.0537C14.9512 17.0571 15.0219 17.0752 15.087 17.1069C15.152 17.1385 15.2101 17.1831 15.2576 17.238C15.3051 17.2928 15.3412 17.3568 15.3637 17.4261C15.3861 17.4953 15.3945 17.5685 15.3883 17.6411C15.3991 18.7128 15.3991 19.784 15.3883 20.8546C15.3976 20.9272 15.3915 21.0011 15.3704 21.0711C15.3492 21.1412 15.3134 21.2059 15.2655 21.2609C15.2176 21.3159 15.1586 21.36 15.0925 21.3901C15.0263 21.4203 14.9546 21.4359 14.882 21.4358C14.589 21.4329 14.4016 21.2088 14.3984 20.8349V19.2276L14.4013 19.229Z"
        fill="#74C8C7"
      />
      <path
        d="M8.58767 19.5578V17.9833C8.58767 17.6186 8.7797 17.3824 9.06542 17.3824C9.13755 17.3859 9.20825 17.4039 9.27329 17.4356C9.33833 17.4672 9.39637 17.5118 9.44392 17.5667C9.49147 17.6215 9.52755 17.6855 9.55001 17.7548C9.57246 17.8241 9.58083 17.8972 9.5746 17.9698C9.58429 19.0416 9.58429 20.1127 9.5746 21.1833C9.58381 21.2559 9.5776 21.3297 9.55637 21.3997C9.53515 21.4697 9.49941 21.5344 9.45152 21.5893C9.40363 21.6443 9.34468 21.6884 9.27861 21.7186C9.21254 21.7488 9.14086 21.7644 9.06833 21.7645C8.77738 21.7616 8.58767 21.5375 8.58447 21.1637V19.5563L8.58767 19.5578Z"
        fill="#74C8C7"
      />
      <path
        d="M16.823 18.2164C16.9529 18.2165 17.0774 18.2687 17.1693 18.3614C17.2611 18.4541 17.3127 18.5799 17.3127 18.711V19.5671C17.3127 19.6982 17.2611 19.8239 17.1692 19.9166C17.0774 20.0093 16.9528 20.0614 16.823 20.0614V20.0614C16.6931 20.0614 16.5685 20.0093 16.4766 19.9166C16.3847 19.8239 16.3331 19.6982 16.333 19.5671V18.711C16.333 18.5798 16.3846 18.4541 16.4765 18.3613C16.5684 18.2686 16.693 18.2164 16.823 18.2164V18.2164Z"
        fill="#74C8C7"
      />
      <path
        d="M18.7641 18.9663C18.894 18.9664 19.0185 19.0185 19.1104 19.1113C19.2022 19.204 19.2538 19.3298 19.2538 19.4609V24.2632C19.2538 24.3943 19.2022 24.52 19.1104 24.6127C19.0185 24.7054 18.894 24.7575 18.7641 24.7575V24.7575C18.6998 24.7575 18.6361 24.7448 18.5767 24.7199C18.5172 24.6951 18.4632 24.6587 18.4178 24.6128C18.3723 24.5669 18.3362 24.5124 18.3116 24.4524C18.287 24.3924 18.2744 24.3281 18.2744 24.2632V19.4609C18.2744 19.3959 18.287 19.3316 18.3116 19.2716C18.3362 19.2116 18.3723 19.1571 18.4177 19.1112C18.4632 19.0653 18.5172 19.0288 18.5766 19.004C18.6361 18.9791 18.6998 18.9663 18.7641 18.9663V18.9663Z"
        fill="#74C8C7"
      />
      <path
        d="M20.6928 19.1525C20.8227 19.1525 20.9473 19.2045 21.0392 19.2972C21.131 19.3899 21.1827 19.5156 21.1828 19.6467V23.729C21.1827 23.7939 21.17 23.8581 21.1454 23.9181C21.1208 23.9781 21.0846 24.0325 21.0391 24.0784C20.9936 24.1243 20.9397 24.1606 20.8802 24.1854C20.8208 24.2102 20.7571 24.223 20.6928 24.223C20.6285 24.223 20.5648 24.2102 20.5054 24.1854C20.446 24.1606 20.392 24.1242 20.3466 24.0784C20.3011 24.0325 20.265 23.978 20.2404 23.9181C20.2158 23.8581 20.2031 23.7939 20.2031 23.729V19.6467C20.2031 19.5156 20.2547 19.3899 20.3466 19.2972C20.4384 19.2046 20.5629 19.1525 20.6928 19.1525Z"
        fill="#74C8C7"
      />
      <path
        d="M22.6459 18.687C22.7758 18.687 22.9004 18.7391 22.9922 18.8318C23.084 18.9245 23.1356 19.0502 23.1356 19.1813V20.9416C23.1356 21.0727 23.084 21.1984 22.9922 21.2911C22.9004 21.3838 22.7758 21.4359 22.6459 21.4359V21.4359C22.5161 21.4359 22.3915 21.3838 22.2997 21.2911C22.2078 21.1984 22.1563 21.0727 22.1562 20.9416V19.1813C22.1563 19.0502 22.2078 18.9245 22.2997 18.8318C22.3915 18.7391 22.5161 18.687 22.6459 18.687V18.687Z"
        fill="#74C8C7"
      />
    </svg>
  );
};
