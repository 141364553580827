import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs } from "./uiKit/Tabs";
import { TotalBalance } from "./TotalBalance";
import { useUserWallets } from "./context/UserWalletContext";
import { CryptocurrencyList } from "./CryptocurrencyList";
import { ProtocolList } from "./ProtocolList";
import { NFTList } from "./NFTList";

import { getFormattedValue } from "../shared/utils/getFormattedValue";
import {
  CRYPTOCURRENCY,
  DEFAULT_HYPERLIQUID_SUBTAB,
  DEFAULT_SUBTAB,
  HYPERLIQUID,
  NFT,
  PORTFOLIO,
  PROTOCOLS,
} from "../shared/constants/routes";
import { capitalize } from "../shared/utils/capitalize";
import { IUserWallet } from "../shared/intefaces/IUserWallet";
import { fetchProtocols } from "../shared/utils/fetchProtocols";
import { fetchTokens } from "../shared/utils/fetchTokens";
import { fetchNFTs } from "../shared/utils/fetchNFTs";
import { fetchHyperLiquidData } from "../shared/utils/fetchHyperLiquidData";
import { GreenPoint } from "./icons/GreenPoint";
import { IHyperLiquidData } from "../shared/intefaces/IHyperLiquidPosition";
import { IProtocol } from "../shared/intefaces/IProtocol";
import { IToken } from "../shared/intefaces/IToken";
import { INFT } from "../shared/intefaces/INFT";
import { HyperliquidList } from "./HyperLiquidList";
import { NetworkSelector } from "./NetworkSelector";

const assetsStyles = {
  container: css``,
  totalBalanceContainer: css`
    display: flex;
    flex-direction: row;
    background: #1c1c1c;
    border-radius: 0 0 24px 24px;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px 16px;
  `,
  tabsWrapper: css`
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  tabsContainer: css`
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 16px;
  `,
  loading: css`
    font-family: "Space Mono", sans-serif;
    margin-top: 25vh;
    text-align: center;
  `,
  hpBlock: css`
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 4px;
  `,
  pointContainer: css`
    padding-top: 4px;
  `,
  contentWrapper: css`
    padding-left: 16px;
    padding-right: 16px;
  `,
};

interface AssetsProps {
  wallets: IUserWallet[];
}

const sortByValueDescending = (items: any[]) =>
  items.sort((a, b) => (b.value || 0) - (a.value || 0));

export const Assets: React.FC<AssetsProps> = ({ wallets }) => {
  const { token, selectedNetworks } = useUserWallets();
  const { type, tab } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(0);

  const [cryptocurrencyData, setCryptocurrencyData] = useState<IToken[]>([]);
  const [protocolsData, setProtocolsData] = useState<IProtocol[]>([]);
  const [nftData, setNftData] = useState<INFT[]>([]);
  const [hyperliquidData, setHyperliquidData] = useState<IHyperLiquidData[]>(
    [],
  );
  const [totalValue, setTotalValue] = useState<number>(0);

  const [balanceLoading, setBalanceLoading] = useState<boolean>(false);
  const [cryptoLoading, setCryptoLoading] = useState<boolean>(false);
  const [protocolsLoading, setProtocolsLoading] = useState<boolean>(false);
  const [nftLoading, setNftLoading] = useState<boolean>(false);
  const [hyperliquidLoading, setHyperliquidLoading] = useState<boolean>(false);

  const filterBySelectedNetworks = (data: any[]) =>
    data.filter((item) => selectedNetworks.includes(item.chain));

  useEffect(() => {
    const tabIndex = [CRYPTOCURRENCY, PROTOCOLS, NFT, HYPERLIQUID].indexOf(
      tab || "",
    );
    setActiveTab(tabIndex >= 0 ? tabIndex : 0);
  }, [tab]);

  useEffect(() => {
    setTotalValue(0);
    setCryptocurrencyData([]);
    setNftData([]);
    setProtocolsData([]);
    setHyperliquidData([]);

    if (token) {
      setBalanceLoading(true);
      setCryptoLoading(true);
      setProtocolsLoading(true);
      setNftLoading(true);
      setHyperliquidLoading(true);

      Promise.all(
        wallets.map((wallet) =>
          fetchTokens(wallet.address, wallet.chain, token, wallet.name),
        ),
      )
        .then((results: any[]) => {
          const data = results.flat();
          setCryptocurrencyData(data);
        })
        .finally(() => {
          setCryptoLoading(false);
          setBalanceLoading(false);
        });

      Promise.all(
        wallets.map((wallet) =>
          fetchProtocols(wallet.address, wallet.chain, token, wallet.name),
        ),
      )
        .then((results: any[]) => {
          const data = results.flat();
          setProtocolsData(data);
        })
        .finally(() => {
          setProtocolsLoading(false);
          setBalanceLoading(false);
        });

      Promise.all(
        wallets.map((wallet) =>
          fetchNFTs(wallet.address, wallet.chain, token, wallet.name),
        ),
      )
        .then((results: any[]) => {
          const data = results.flat();
          setNftData(data);
        })
        .finally(() => {
          setNftLoading(false);
          setBalanceLoading(false);
        });

      Promise.all(
        wallets
          .filter((x) => x.chain === "evm")
          .map((wallet) =>
            fetchHyperLiquidData(wallet.address, token, wallet.name),
          ),
      )
        .then((results: IHyperLiquidData[]) => {
          setHyperliquidData(results);
        })
        .finally(() => {
          setHyperliquidLoading(false);
          setBalanceLoading(false);
        });
    }
  }, [wallets, token]);

  useEffect(() => {
    let total = 0;

    const filterBySelectedNetworks = (data: any[]) =>
      data.filter((item) => selectedNetworks.includes(item.chain));

    if (!cryptoLoading) {
      total += filterBySelectedNetworks(cryptocurrencyData).reduce(
        (sum, data) => sum + (data.value || 0),
        0,
      );
      setCryptocurrencyData(filterBySelectedNetworks(cryptocurrencyData));
    }
    if (!protocolsLoading) {
      total += filterBySelectedNetworks(protocolsData).reduce(
        (sum, data) => sum + (data.value || 0),
        0,
      );
      setProtocolsData(filterBySelectedNetworks(protocolsData));
    }
    if (!nftLoading) {
      total += filterBySelectedNetworks(nftData).reduce(
        (sum, data) => sum + (data.value || 0),
        0,
      );
      setNftData(filterBySelectedNetworks(nftData));
    }
    if (!hyperliquidLoading) {
      total += hyperliquidData.reduce(
        (sum, pos) => sum + (pos.generalAnalytics.totalBalance || 0),
        0,
      );
      setHyperliquidData(hyperliquidData);
    }

    setTotalValue(total);
  }, [
    cryptoLoading,
    protocolsLoading,
    nftLoading,
    hyperliquidLoading,
    selectedNetworks,
  ]);

  const handleTabChange = (newIndex: number) => {
    const newTab = [CRYPTOCURRENCY, PROTOCOLS, NFT, HYPERLIQUID][newIndex];
    if (newTab === HYPERLIQUID) {
      navigate(`/${PORTFOLIO}/${type}/${newTab}/${DEFAULT_HYPERLIQUID_SUBTAB}`);
    } else {
      navigate(`/${PORTFOLIO}/${type}/${newTab}/${DEFAULT_SUBTAB}`);
    }
  };

  return (
    <div className={assetsStyles.container}>
      <div className={assetsStyles.totalBalanceContainer}>
        <TotalBalance
          value={getFormattedValue(totalValue, true)}
          loading={balanceLoading}
          size={"big"}
        />
        <NetworkSelector></NetworkSelector>
      </div>
      <div className={assetsStyles.tabsWrapper}>
        <div className={assetsStyles.tabsContainer}>
          <Tabs
            tabs={[
              { label: capitalize(CRYPTOCURRENCY) },
              { label: capitalize(PROTOCOLS) },
              { label: NFT.toUpperCase() },
              {
                label: (
                  <span className={assetsStyles.hpBlock}>
                    {capitalize(HYPERLIQUID)}
                    <div className={assetsStyles.pointContainer}>
                      <GreenPoint />
                    </div>
                  </span>
                ),
              },
            ]}
            activeIndex={activeTab}
            onTabChange={handleTabChange}
            variant="secondary"
          />
        </div>
      </div>
      <div className={assetsStyles.contentWrapper}>
        {activeTab === 0 &&
          (cryptoLoading ? (
            <div className={assetsStyles.loading}>Loading...</div>
          ) : (
            <CryptocurrencyList
              data={sortByValueDescending(cryptocurrencyData)}
            />
          ))}
        {activeTab === 1 &&
          (protocolsLoading ? (
            <div className={assetsStyles.loading}>Loading...</div>
          ) : (
            <ProtocolList data={sortByValueDescending(protocolsData)} />
          ))}
        {activeTab === 2 &&
          (nftLoading ? (
            <div className={assetsStyles.loading}>Loading...</div>
          ) : (
            <NFTList data={sortByValueDescending(nftData)} />
          ))}
        {activeTab === 3 &&
          (hyperliquidLoading ? (
            <div className={assetsStyles.loading}>Loading...</div>
          ) : (
            <HyperliquidList data={hyperliquidData} />
          ))}
      </div>
    </div>
  );
};
