import { isAddress } from "viem";
import { IUserWallet } from "../intefaces/IUserWallet";
import { PublicKey } from "@solana/web3.js";
import validate from "bitcoin-address-validation";
import { BACKEND_API } from "../constants/urls";

export interface WalletValidationResult {
  addressError: boolean;
  nameError: boolean;
}

const checkEvmAddress = (address: string) => {
  return isAddress(address);
};

const checkTonAddress = async (address: string): Promise<boolean> => {
  try {
    if (address.length) {
      const response = await fetch(
        `${BACKEND_API}/check_address/ton/${address}`,
      );
      const data = await response.json();
      return data.result;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const checkSolanaAddress = (address: string) => {
  try {
    new PublicKey(address);
    return PublicKey.isOnCurve(new PublicKey(address).toBuffer());
  } catch (e) {
    return false;
  }
};

const checkBtcAddress = (address: string) => {
  return validate(address);
};

export const validateWallet = async (
  wallet: IUserWallet,
): Promise<WalletValidationResult> => {
  const addressError =
    !checkEvmAddress(wallet.address) &&
    !(await checkTonAddress(wallet.address)) &&
    !checkSolanaAddress(wallet.address) &&
    !checkBtcAddress(wallet.address);
  const nameError = wallet.name.trim() === "" || wallet.name.trim().length > 30;
  return { addressError, nameError };
};

export const detectNetwork = async (
  address: string,
): Promise<"evm" | "ton" | "solana" | "bitcoin" | null> => {
  if (checkEvmAddress(address)) return "evm";
  if (await checkTonAddress(address)) return "ton";
  if (checkSolanaAddress(address)) return "solana";
  if (checkBtcAddress(address)) return "bitcoin";
  return null;
};

export const isWalletValid = async (wallet: IUserWallet): Promise<boolean> => {
  const { addressError, nameError } = await validateWallet(wallet);
  return !addressError && !nameError;
};
