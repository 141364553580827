import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HyperLiquidSpotPositionDetails } from "./HyperLiquidSpotPositionDetails";
import { EmptyState } from "./EmptyState";
import { PERP, SPOT, VAULT } from "../shared/constants/routes";
import { HyperLiquidPerpPositionDetails } from "./HyperLiquidPerpPoisitionDetails";
import { HyperLiquidVaultPositionDetails } from "./HyperLiquidVaultPositionsDetails";

export const HyperLiquidPositionDetailsWrapper: React.FC = () => {
  const { type } = useParams<{ type: string }>(); // Получаем тип позиции из URL
  const location = useLocation();
  const navigate = useNavigate();
  const positionData = location.state;

  if (!positionData) {
    navigate(-1);
    return null;
  }

  switch (type) {
    case VAULT:
      return <HyperLiquidVaultPositionDetails {...positionData} />;
    case PERP:
      return <HyperLiquidPerpPositionDetails {...positionData} />;
    case SPOT:
      return <HyperLiquidSpotPositionDetails {...positionData} />;
    default:
      return <EmptyState />;
  }
};
