export const MikomedaLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M24.0212 14.1864L25.3333 16.4579L18.4218 20.4488C18.4218 20.4488 14.189 23.007 11.1458 17.7327L13.2979 16.4909C13.2979 16.4909 14.6964 19.5822 17.1203 18.1783C19.5442 16.7745 24.0212 14.1864 24.0212 14.1864Z"
        fill="white"
      />
      <path
        d="M7.97891 17.7169L6.66675 15.4453L13.7254 11.3703C13.7254 11.3703 17.9582 8.81205 21.0004 14.0863L18.8482 15.3281C18.8482 15.3281 17.4498 12.2369 15.0258 13.6407C12.6019 15.0446 7.97891 17.7169 7.97891 17.7169Z"
        fill="white"
      />
      <path
        d="M16.1333 17.6614C17.0859 17.6614 17.858 16.8892 17.858 15.9367C17.858 14.9842 17.0859 14.212 16.1333 14.212C15.1808 14.212 14.4087 14.9842 14.4087 15.9367C14.4087 16.8892 15.1808 17.6614 16.1333 17.6614Z"
        fill="#F1973F"
      />
      <path
        d="M10.0435 20.354C10.541 20.354 10.9443 19.9508 10.9443 19.4533C10.9443 18.9559 10.541 18.5526 10.0435 18.5526C9.54609 18.5526 9.14282 18.9559 9.14282 19.4533C9.14282 19.9508 9.54609 20.354 10.0435 20.354Z"
        fill="white"
      />
      <path
        d="M22.0662 13.4117C22.5637 13.4117 22.967 13.0084 22.967 12.5109C22.967 12.0135 22.5637 11.6102 22.0662 11.6102C21.5688 11.6102 21.1655 12.0135 21.1655 12.5109C21.1655 13.0084 21.5688 13.4117 22.0662 13.4117Z"
        fill="white"
      />
    </svg>
  );
};
