export const BobaLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M12.4788 20.1652C12.0785 20.1652 11.7405 19.884 11.6732 19.4953L9.39469 6.32045C9.35784 6.10799 9.40739 5.89553 9.53447 5.72057C9.66155 5.5456 9.84962 5.42938 10.0644 5.39313C10.5091 5.3194 10.9323 5.61434 11.0085 6.05175L13.287 19.2266C13.3239 19.4391 13.2743 19.6516 13.1472 19.8265C13.0202 20.0015 12.8321 20.1177 12.6173 20.154C12.5729 20.1615 12.5258 20.1652 12.4788 20.1652Z"
        fill="white"
      />
      <path
        d="M12.4788 20.1652C12.0785 20.1652 11.7405 19.884 11.6732 19.4953L9.39469 6.32045C9.35784 6.10799 9.40739 5.89553 9.53447 5.72057C9.66155 5.5456 9.84962 5.42938 10.0644 5.39313C10.5091 5.3194 10.9323 5.61434 11.0085 6.05175L13.287 19.2266C13.3239 19.4391 13.2743 19.6516 13.1472 19.8265C13.0202 20.0015 12.8321 20.1177 12.6173 20.154C12.5729 20.1615 12.5258 20.1652 12.4788 20.1652Z"
        fill="#D7FD51"
      />
      <path
        d="M16.2975 14.0889C14.995 14.0889 13.7839 14.4725 12.7711 15.1312L13.0469 16.7258C13.9174 15.9797 15.0547 15.5261 16.2975 15.5261C19.0309 15.5261 21.2547 17.7132 21.2547 20.4014C21.2547 23.0896 19.0309 25.2767 16.2975 25.2767C13.5641 25.2767 11.3403 23.0896 11.3403 20.4014C11.3403 20.0427 11.3809 19.694 11.4559 19.3578L11.0162 16.8196C10.2995 17.8381 9.87891 19.0729 9.87891 20.4026C9.87891 23.8832 12.7584 26.7151 16.2975 26.7151C19.8366 26.7151 22.7161 23.8832 22.7161 20.4026C22.7161 16.9221 19.8378 14.0889 16.2975 14.0889Z"
        fill="#D7FD51"
      />
      <path
        d="M15.947 24.0859C16.4299 24.0859 16.8213 23.7009 16.8213 23.226C16.8213 22.7512 16.4299 22.3662 15.947 22.3662C15.4642 22.3662 15.0728 22.7512 15.0728 23.226C15.0728 23.7009 15.4642 24.0859 15.947 24.0859Z"
        fill="#63D3CD"
      />
      <path
        d="M18.6775 23.0849C19.1604 23.0849 19.5518 22.6999 19.5518 22.2251C19.5518 21.7502 19.1604 21.3652 18.6775 21.3652C18.1947 21.3652 17.8032 21.7502 17.8032 22.2251C17.8032 22.6999 18.1947 23.0849 18.6775 23.0849Z"
        fill="#63D3CD"
      />
      <path
        d="M16.5305 21.2413C17.0134 21.2413 17.4048 20.8563 17.4048 20.3814C17.4048 19.9066 17.0134 19.5216 16.5305 19.5216C16.0477 19.5216 15.6562 19.9066 15.6562 20.3814C15.6562 20.8563 16.0477 21.2413 16.5305 21.2413Z"
        fill="#63D3CD"
      />
      <path
        d="M13.8169 22.1199C14.2998 22.1199 14.6912 21.735 14.6912 21.2601C14.6912 20.7852 14.2998 20.4003 13.8169 20.4003C13.3341 20.4003 12.9426 20.7852 12.9426 21.2601C12.9426 21.735 13.3341 22.1199 13.8169 22.1199Z"
        fill="#63D3CD"
      />
      <path
        d="M19.2376 20.3604C19.7204 20.3604 20.1118 19.9754 20.1118 19.5006C20.1118 19.0257 19.7204 18.6407 19.2376 18.6407C18.7547 18.6407 18.3633 19.0257 18.3633 19.5006C18.3633 19.9754 18.7547 20.3604 19.2376 20.3604Z"
        fill="#63D3CD"
      />
      <path
        d="M17.115 18.397C17.5979 18.397 17.9893 18.0121 17.9893 17.5372C17.9893 17.0623 17.5979 16.6774 17.115 16.6774C16.6322 16.6774 16.2407 17.0623 16.2407 17.5372C16.2407 18.0121 16.6322 18.397 17.115 18.397Z"
        fill="#63D3CD"
      />
      <path
        d="M14.3755 19.3969C14.8584 19.3969 15.2498 19.0119 15.2498 18.5371C15.2498 18.0622 14.8584 17.6772 14.3755 17.6772C13.8926 17.6772 13.5012 18.0622 13.5012 18.5371C13.5012 19.0119 13.8926 19.3969 14.3755 19.3969Z"
        fill="#63D3CD"
      />
    </svg>
  );
};
