export const getFormattedValue = (value: number, usd: boolean): string => {
  if (value === 0 || value === null) {
    return usd ? "$0" : "0";
  } else if (value < 0.000001) {
    return usd ? "<$0.000001" : "<0.000001";
  } else if (value < 1) {
    return usd ? "$" + value.toFixed(6) : value.toFixed(6);
  } else if (value >= 1000000000) {
    return usd
      ? "$" + `${(value / 1000000000).toFixed(1)}B`
      : `${(value / 1000000000).toFixed(1)}B`;
  } else if (value >= 1000000) {
    return usd
      ? "$" + `${(value / 1000000).toFixed(1)}M`
      : `${(value / 1000000).toFixed(1)}M`;
  } else {
    return usd
      ? "$" +
          new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(value)
      : new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(value);
  }
};
