import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import dayjs from "dayjs";
import { fetchHyperLiquidPricesHistory } from "../shared/utils/fetchHyperLiquidPricesHistory";
import { useUserWallets } from "./context/UserWalletContext";
import { IHyperLiquidPriceHistory } from "../shared/intefaces/IHyperLiquidPriceHistory";

const styles = {
  chartContainer: css`
    background: #181818;
    padding-top: 12px;
    padding-bottom: 4px;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 240px;
  `,
  tooltip: css`
    background: #0a0a0a;
    color: #fff;
    padding: 6px 12px;
    border-radius: 12px;
    font-size: 14px;
  `,
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <div>
          {dayjs.unix(payload[0].payload.timestamp).format("DD/MM HH:mm")}
        </div>
        <div>${payload[0].value.toFixed(2)}</div>
      </div>
    );
  }
  return null;
};

interface PriceChartProps {
  type: "spot" | "perp";
  symbol: string;
  timeframe: string;
}

export const PriceChart: React.FC<PriceChartProps> = ({
  type,
  symbol,
  timeframe,
}) => {
  const { token } = useUserWallets();
  const [chartData, setChartData] = useState<IHyperLiquidPriceHistory[]>([]);

  useEffect(() => {
    if (token) {
      fetchHyperLiquidPricesHistory(type, symbol, timeframe, token)
        .then((data) => {
          setChartData(data || []);
        })
        .catch(() => setChartData([]));
    }
  }, [token, type, symbol, timeframe]);

  return (
    <div className={styles.chartContainer}>
      <ResponsiveContainer>
        <LineChart
          data={chartData}
          margin={{ top: 0, right: -8, left: 0, bottom: 0 }}
        >
          <CartesianGrid stroke="#7C7C7C52" vertical={false} />
          <XAxis
            padding={{ left: 16, right: 4 }}
            dataKey={"timestamp"}
            tickFormatter={(timestamp) =>
              timeframe === "4h"
                ? dayjs.unix(timestamp).format("HH:mm")
                : dayjs.unix(timestamp).format("DD.MM")
            }
            tick={{ fill: "#C1C1C899", fontSize: 10 }}
            tickCount={7}
            tickMargin={8}
            domain={["auto", "auto"]}
            axisLine={false}
            tickLine={false}
            interval={"preserveStartEnd"}
          />
          <YAxis
            dataKey={"price"}
            tickFormatter={(value) => `$${value}`}
            tick={{ fill: "#C1C1C899", fontSize: 10 }}
            domain={["auto", "auto"]}
            tickCount={7}
            axisLine={false}
            tickLine={false}
            interval={"preserveStartEnd"}
            orientation="right"
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="price"
            stroke="#8DE9C6"
            strokeWidth={2}
            dot={{ r: 2, fill: "#8DE9C6" }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
