import { BACKEND_API } from "../constants/urls";
import { IPosition, IProtocol, IProtocolToken } from "../intefaces/IProtocol";
import { capitalize } from "./capitalize";

export const ProtocolTypeMap: Record<string, string> = {
  liquidity_pool: "Liquidity Pool",
  yield: "Yield",
  deposit: "Deposit",
  staked: "Staked",
  locked: "Locked",
  farming: "Farming",
  leveraged_farming: "Leveraged Farming",
  lending: "Lending",
  vesting: "Vesting",
  rewards: "Rewards",
  options_seller: "Options Seller",
  options_buyer: "Options Buyer",
  insurance_seller: "Insurance Seller",
  insurance_buyer: "Insurance Buyer",
  investment: "Investment",
  governance: "Governance",
  perpetuals: "Perpetuals",
  airdrop: "Airdrop",
  nft_staked: "NFT Staked",
  nft_liquidity_pool: "NFT Liquidity Pool",
  nft_lending: "NFT Lending",
  nft_fraction: "NFT Fraction",
  nft_p2p_lender: "NFT P2P Lender",
  nft_p2p_borrower: "NFT P2P Borrower",
};

export const protocolTypeConversion = (
  protocol: any,
  walletName: string,
): IProtocol => {
  return {
    name: capitalize(protocol.protocol_name || "unknown"),
    value: protocol.net_usd_value ?? 0,
    image: protocol.logo_url ?? null,
    positions: (protocol.positions ?? []).map((pos: IPosition) => ({
      ...pos,
      type: ProtocolTypeMap[pos.type!] || pos.type, // 🔹 Преобразование типа
      tokens: (pos.tokens ?? []).map((token: IProtocolToken) => ({
        ...token,
        symbol: token.symbol?.toUpperCase() ?? null, // 🔹 Символ токена
      })),
      loans: (pos.loans ?? []).map((loan: IProtocolToken) => ({
        ...loan,
        symbol: loan.symbol?.toUpperCase() ?? null, // 🔹 Символ займа
      })),
      rewards: (pos.rewards ?? []).map((reward) => ({
        ...reward,
        symbol: reward.symbol?.toUpperCase() ?? null, // 🔹 Символ награды
      })),
    })),
    chainLogo: protocol.chain_logo,
    chain: protocol.chain_name,
    walletName,
  };
};

export const fetchProtocols = async (
  address: string,
  chain: string | null,
  token: string,
  walletName: string,
): Promise<IProtocol[]> => {
  try {
    const response = await fetch(
      `${BACKEND_API}/protocols/${chain}/${address}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();
    return (
      data.result.map((x: any) => protocolTypeConversion(x, walletName)) || []
    );
  } catch (error) {
    return [];
  }
};
