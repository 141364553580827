export const LineaLogo = () => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13706_3351)">
        <path d="M30.907 0H0V31.942H30.907V0Z" fill="#61DFFF" />
        <mask
          id="mask0_13706_3351"
          maskUnits="userSpaceOnUse"
          x="7"
          y="8"
          width="17"
          height="16"
        >
          <path
            d="M23.1324 8.00208H7.70361V23.9985H23.1324V8.00208Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_13706_3351)">
          <path
            d="M20.5173 23.9985H7.70361V10.5978H10.6354V21.4014H20.5173V23.9971V23.9985Z"
            fill="#121212"
          />
          <path
            d="M20.5175 13.1935C21.9618 13.1935 23.1327 12.0314 23.1327 10.5978C23.1327 9.16421 21.9618 8.00208 20.5175 8.00208C19.0731 8.00208 17.9023 9.16421 17.9023 10.5978C17.9023 12.0314 19.0731 13.1935 20.5175 13.1935Z"
            fill="#121212"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_13706_3351">
          <rect width="31" height="32" rx="15.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
