export const MoonBeamLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M24.5233 14.0713C24.7024 14.0713 24.8508 13.9306 24.8508 13.7516V13.7516C24.8508 10.0681 21.8647 7.08203 18.1812 7.08203C14.4978 7.08203 11.5117 10.0681 11.5117 13.7516V13.7516C11.5117 13.9306 11.6601 14.0713 11.8391 14.0713H24.5233Z"
        fill="#74C8C7"
      />
      <circle cx="9.04541" cy="15.6703" r="0.411135" fill="#CE347A" />
      <rect
        x="10.0952"
        y="15.2592"
        width="16.217"
        height="0.82227"
        rx="0.411135"
        fill="#CE347A"
      />
      <ellipse
        cx="5.89307"
        cy="17.4974"
        rx="0.411135"
        ry="0.411135"
        fill="#CE347A"
      />
      <rect
        x="6.94434"
        y="17.0863"
        width="12.1056"
        height="0.822269"
        rx="0.411135"
        fill="#CE347A"
      />
      <ellipse
        cx="9.04541"
        cy="19.3247"
        rx="0.411135"
        ry="0.411135"
        fill="#CE347A"
      />
      <rect
        x="10.0952"
        y="18.9136"
        width="14.3897"
        height="0.822269"
        rx="0.411135"
        fill="#CE347A"
      />
      <ellipse
        cx="6.71582"
        cy="21.152"
        rx="0.411135"
        ry="0.411136"
        fill="#CE347A"
      />
      <ellipse
        cx="15.9424"
        cy="22.9791"
        rx="0.411135"
        ry="0.411136"
        fill="#CE347A"
      />
      <ellipse
        cx="11.6953"
        cy="24.8522"
        rx="0.411135"
        ry="0.411136"
        fill="#CE347A"
      />
      <rect
        x="16.9937"
        y="22.568"
        width="7.7202"
        height="0.822271"
        rx="0.411136"
        fill="#CE347A"
      />
      <rect
        x="7.76562"
        y="20.7408"
        width="6.16702"
        height="0.822271"
        rx="0.411136"
        fill="#CE347A"
      />
      <rect
        x="12.7451"
        y="24.441"
        width="7.7202"
        height="0.822271"
        rx="0.411136"
        fill="#CE347A"
      />
      <rect
        x="14.7554"
        y="20.7408"
        width="12.06"
        height="0.822271"
        rx="0.411136"
        fill="#CE347A"
      />
    </svg>
  );
};
