export const RskLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0_13668_3602)">
        <rect
          x="-1.33325"
          y="-1.33337"
          width="34.6667"
          height="34.6667"
          fill="url(#pattern0_13668_3602)"
        />
      </g>
      <defs>
        <pattern
          id="pattern0_13668_3602"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_13668_3602" transform="scale(0.015625)" />
        </pattern>
        <clipPath id="clip0_13668_3602">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
        <image
          id="image0_13668_3602"
          width="64"
          height="64"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAIAAAAlC+aJAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAQKADAAQAAAABAAAAQAAAAABGUUKwAAAEnklEQVRoBe2XS0skVxTHq7q6qx+23b7FCKMxwxidpW6TQDbBEJgEl0KyykJwr1s/gFsXQsYPMLOLcZaiG1eRkSAu4wNhsIP2tG3Zz6r89MKlsF9V3pEeoe6iOHXuOeee8/+fc6tbdxxHe8or9JSTv809KKDdDAYMBAwoIhC0kCKAyu4BA8oQKgYIGFAEUNk9YEAZQsUAAQOKACq7BwwoQ6gY4CEMVKtVxVM/obu/AqrXxUK5uvnXn/mcVSlb2mfwd1r396fe0VLJ5NVoOJbJX5xX4p8QyYeGCnt3rFQqejhsPU9pP42WvkklErpjtZ8CHy0UDod742nj26/MQjV243z9x4+b7zbbPg8+CoCrj6ZlG3ZJD9nJ6odk/udfXnkn8JEsfRRwOy3JaJS2eft3+aqYNUOJaNy27UfKzGNYHzNAxHim6MQimm2YIbvTSQw9+9IwDI8nPZKZDwZo99dv3qQKhuaETMvJ/Pru/T/7oZCPCHVrEByurq7W3W2tpDF8LNtJplOhmBnqiNk+3JqZ7uzsJO5WM6PGez7x07Vc9mPcjOY//KfXgMMpxWJRqvP5fKlUkq9SkBdX8/mp6yuDSMFnAZpG3oatfTH67N4ngOy7u7s7Ozvj8dvvG8nNzs729fWVy2WU6XT6+vo6k8n09PQkk8lUKoVyY2ND5uEWOu4WrLiVDeXG5NTZIQnTNIGZJ7K0KDiOEdX137/rXvs+0qEJPRmQCamLDuE7GIvF0ODb1dVFGSws3S2EjfAiXRm8ueCJAcuytra2CEQ2c3Nz4Lq4uDg8PDw/P8+Rp6enWrGQsI2XL3qsrX9f/fCbRAuXtbW1XC5HtXDCxLOo//Ly8upuSUshDAwM4DI2NkbYe1sNX3FouYANG3gXloBENsi0KVvHx8dEpyrK293dldEElsJSKOkulIODg7wyCbgjCAZIHXeYoQ8LBRj1ujwxwGHLy8u1qMzMzOi6Pj4+vr6+Tn9ns9np6Wk3VLiQiNTs7e0hY8YAsCKRiNy6uLhApiQhSH1rwUulQAL8NBJPIKd36Z/+/n6aAXdSIfvaOIAN5G49QPB6eHhIHHZJjlc5AygJBT/n5+dur+ay11nhPAJRQzQaJW/GUfaG2Ko9prYAYYMji5+GojO3t7eFJXQxachs1UZrpPHUQkwhVwfZcwlydm9vL6+cxPhSDK9c+S25Bn4yPjk5wZLGo9cJJWTx5FfJ1NQUWzAMQGTcMiYGngoYGhoaGRlhTG9ubmhTDqaPkZeWliiJK2V/f//eYcBJxmQj9ch8EyYmJnCheC6hlZUVuSvSpYXE549iFhYW5G4zoRE1bj1nkxA9KpQcTw3IBwcHUIEwOTnpthcyNmJIxCsyHCJTmLh/MKAksev+qghN3bkSW+6np7+UHMn9TRlcOEdHR3QCGkoCRfSEI5V2/Sz1VIBkkFy5WGCZvj87O6NZ5Va7BH8FiCzFt4YZaFfS7nMfUgCfBW4Jd5Q2yg8poI3p1h7t6Rqtdft8NEEB7eYiYCBgQBGBoIUUAVR2DxhQhlAxQMCAIoDK7gEDyhAqBggYUARQ2T1gQBlCxQBPnoH/AZn2CVKFBx4jAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};
