export const FuseLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13668_3541)">
        <circle cx="16" cy="16" r="16" fill="#081827" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9486 22.8041C15.2226 22.8041 14.548 22.6519 13.9973 22.3639L8.06753 19.2637L6.29067 20.3315C5.44608 20.8388 5.46083 21.6166 6.32358 22.0678L14.525 26.3561C14.9233 26.5642 15.4326 26.6667 15.9486 26.6667C16.5512 26.6667 17.1626 26.5272 17.6177 26.2539L25.6466 21.4289C26.4912 20.9212 26.4764 20.1434 25.6137 19.6925L23.9086 18.8007L18.2041 22.229C17.5868 22.5997 16.7859 22.8041 15.9486 22.8041Z"
          fill="url(#paint0_linear_13668_3541)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.6665 14.5586L24.2428 13.8032L20.9274 15.8247L20.3582 16.1717L19.7893 16.5187L18.2373 17.4651C17.6184 17.8428 16.8151 18.0504 15.9759 18.0504C15.248 18.0504 14.5716 17.8956 14.0194 17.6029L12.2413 16.6594L11.6528 16.3475L11.0642 16.0353L7.78785 14.2967L6.29246 15.2086C5.44563 15.725 5.46014 16.5161 6.32517 16.9751L8.03504 17.8821L8.62358 18.1943L9.21183 18.5065L14.5485 21.3377C14.9476 21.5494 15.4585 21.6534 15.9759 21.6534C16.5798 21.6534 17.1931 21.5118 17.6491 21.2337L22.7798 18.1053L23.349 17.7583L23.9179 17.4113L25.6995 16.3251C26.5463 15.8089 26.5315 15.0176 25.6665 14.5586Z"
          fill="url(#paint1_linear_13668_3541)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.6665 9.93035L17.4431 5.64383C17.044 5.43554 16.5334 5.33337 16.0157 5.33337C15.4118 5.33337 14.7985 5.4728 14.3426 5.746L6.29246 10.5693C5.44563 11.0765 5.46014 11.8538 6.32517 12.3048L7.74888 13.047L8.33741 13.3538L8.92595 13.6606L12.2023 15.3687L12.7909 15.6752L13.3794 15.9822L14.5485 16.5919C14.9476 16.7999 15.4585 16.902 15.9759 16.902C16.5798 16.902 17.1931 16.7626 17.6491 16.4894L18.6123 15.9122L19.1814 15.5713L19.7504 15.2304L23.0657 13.244L23.6349 12.9031L24.2038 12.5622L25.6995 11.6661C26.5463 11.1589 26.5315 10.3814 25.6665 9.93035Z"
          fill="url(#paint2_linear_13668_3541)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_13668_3541"
          x1="15.9686"
          y1="6.01927"
          x2="15.9686"
          y2="26.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3F9BA" />
          <stop offset="1" stopColor="#F6FB8C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13668_3541"
          x1="15.9959"
          y1="6.03538"
          x2="15.9959"
          y2="26.1917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B1FFBF" />
          <stop offset="1" stopColor="#FFF16D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_13668_3541"
          x1="15.9959"
          y1="5.33337"
          x2="15.9959"
          y2="26.2499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3F9BA" />
          <stop offset="1" stopColor="#F6FB8C" />
        </linearGradient>
        <clipPath id="clip0_13668_3541">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
