export const OecLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <ellipse cx="16" cy="21.1267" rx="4.20657" ry="4.20657" fill="#0D74F5" />
      <ellipse cx="16" cy="10.8732" rx="4.20657" ry="4.20657" fill="#7ABDF7" />
      <ellipse
        cx="10.873"
        cy="16"
        rx="4.20657"
        ry="4.20657"
        transform="rotate(90 10.873 16)"
        fill="#005CF4"
      />
      <ellipse
        cx="21.1264"
        cy="16"
        rx="4.20657"
        ry="4.20657"
        transform="rotate(90 21.1264 16)"
        fill="#4494F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9469 17.0531C14.5662 18.5302 13.4033 19.693 11.9263 20.0737C12.307 18.5967 13.4698 17.4338 14.9469 17.0531Z"
        fill="#0729F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0738 11.9263C19.6931 13.4033 18.5302 14.5661 17.0532 14.9468C17.434 13.4698 18.5968 12.307 20.0738 11.9263Z"
        fill="#186EF9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9468 14.9469C13.4697 14.5661 12.3069 13.4033 11.9262 11.9263C13.4032 12.307 14.5661 13.4698 14.9468 14.9469Z"
        fill="#0046F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0737 20.0737C18.5967 19.693 17.4338 18.5302 17.0531 17.0531C18.5302 17.4338 19.693 18.5967 20.0737 20.0737Z"
        fill="#0146F2"
      />
    </svg>
  );
};
