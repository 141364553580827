import { IHyperLiquidData } from "../intefaces/IHyperLiquidPosition";

export const CountHyperLiquidStats = (
  data: IHyperLiquidData[],
): IHyperLiquidData | null => {
  try {
    return data.reduce<IHyperLiquidData>(
      (acc, wallet) => {
        acc.spot.positions = [...acc.spot.positions, ...wallet.spot.positions];
        acc.spot.totalBalance += wallet.spot.totalBalance;
        acc.spot.totalInPositions += wallet.spot.totalInPositions;
        acc.spot.pnl += wallet.spot.pnl;
        acc.spot.volume += wallet.spot.volume;
        acc.spot.freeBalance += wallet.spot.freeBalance;
        acc.spot.dealsCount += wallet.spot.dealsCount;
        acc.spot.profitDealsCount += wallet.spot.profitDealsCount;
        acc.spot.roi = (acc.spot.pnl / acc.spot.volume) * 100 || 0;

        acc.perp.positions = [...acc.perp.positions, ...wallet.perp.positions];
        acc.perp.totalBalance += wallet.perp.totalBalance;
        acc.perp.totalInPositions += wallet.perp.totalInPositions;
        acc.perp.freeBalance += wallet.perp.freeBalance;
        acc.perp.pnl += wallet.perp.pnl;
        acc.perp.roi += wallet.perp.roi;
        acc.perp.volume += wallet.perp.volume;
        acc.perp.dealsCount += wallet.perp.dealsCount;
        acc.perp.profitDealsCount += wallet.perp.profitDealsCount;
        acc.perp.roi = (acc.perp.pnl / acc.perp.volume) * 100 || 0;

        acc.vault.positions = [
          ...acc.vault.positions,
          ...wallet.vault.positions,
        ];
        acc.vault.totalBalance += wallet.vault.totalBalance;
        acc.vault.totalInPositions += wallet.vault.totalInPositions;
        acc.vault.pnl += wallet.vault.pnl;
        acc.vault.roi += wallet.vault.roi;
        acc.vault.roi = (acc.vault.pnl / acc.vault.totalInPositions) * 100 || 0;

        acc.generalAnalytics.totalBalance +=
          wallet.generalAnalytics.totalBalance;
        acc.generalAnalytics.totalInPositions +=
          wallet.generalAnalytics.totalInPositions;
        acc.generalAnalytics.freeBalance += wallet.generalAnalytics.freeBalance;
        acc.generalAnalytics.pnl += wallet.generalAnalytics.pnl;
        acc.generalAnalytics.dealsCount += wallet.generalAnalytics.dealsCount;
        acc.generalAnalytics.profitDealsCount +=
          wallet.generalAnalytics.profitDealsCount;

        return acc;
      },
      {
        spot: {
          positions: [],
          totalBalance: 0,
          totalInPositions: 0,
          pnl: 0,
          roi: 0,
          volume: 0,
          freeBalance: 0,
          dealsCount: 0,
          profitDealsCount: 0,
        },
        perp: {
          positions: [],
          totalBalance: 0,
          totalInPositions: 0,
          freeBalance: 0,
          pnl: 0,
          roi: 0,
          volume: 0,
          dealsCount: 0,
          profitDealsCount: 0,
        },
        vault: {
          positions: [],
          totalBalance: 0,
          totalInPositions: 0,
          pnl: 0,
          roi: 0,
        },
        generalAnalytics: {
          totalBalance: 0,
          totalInPositions: 0,
          freeBalance: 0,
          pnl: 0,
          dealsCount: 0,
          profitDealsCount: 0,
        },
        walletName: "Aggregated",
      },
    );
  } catch (error) {
    return null;
  }
};
