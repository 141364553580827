import React, { useState } from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { useUserWallets } from "../context/UserWalletContext";
import { Button } from "../uiKit/Button";
import { AllNetworksIcon } from "../icons/networks/AllNetworksIcon";
import { getNetworkDetails, getNetworks } from "../networks";
import { Checkbox } from "../uiKit/CheckBox";
import { BackButton } from "../uiKit/BackButton";

const styles = {
  container: css`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    gap: 24px;
    margin-bottom: 140px;
    color: white;
    font-family: "Open Sans", sans-serif;
    overflow-y: auto;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE и Edge */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari */
    }
  `,
  title: css`
    font-family: "Space Mono", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
  `,
  option: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 16px;
  `,
  networks: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  networkContent: css`
    display: flex;
    align-items: center;
  `,
  fixedSaveButton: (height: number) => css`
    position: fixed;
    width: 100%;
    bottom: ${height}px;
    background: #0a0a0a;
    border-top: 1px solid #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10000;
  `,
  buttonWrapper: (height: number) => css`
    padding: 16px 16px ${height}px 16px;
  `,
  checkBoxWrapper: css`
    margin-right: 16px;
  `,
  imageWrapper: css`
    margin-right: 12px;
  `,
};

export const SelectNetworksPage = () => {
  const navigate = useNavigate();
  const { selectedNetworks, setSelectedNetworks } = useUserWallets();
  const [localSelection, setLocalSelection] = useState(selectedNetworks);

  const isAllSelected = localSelection.length === getNetworks().length;

  const toggleNetwork = (network: string) => {
    setLocalSelection((prev) =>
      prev.includes(network)
        ? prev.filter((n) => n !== network)
        : [...prev, network],
    );
  };

  const toggleAllNetworks = () => {
    if (isAllSelected) {
      setLocalSelection([]);
    } else {
      setLocalSelection(getNetworks());
    }
  };

  const saveSelection = () => {
    setSelectedNetworks(localSelection);
    navigate(-1);
  };

  const backButton = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={styles.container}>
        <BackButton onClick={backButton} />
        <div className={styles.title}>Select chain</div>

        <div className={styles.networks}>
          <div className={styles.option} onClick={toggleAllNetworks}>
            <div className={styles.networkContent}>
              <div className={styles.checkBoxWrapper}>
                <Checkbox
                  isChecked={isAllSelected}
                  onChange={toggleAllNetworks}
                />
              </div>
              <div className={styles.imageWrapper}>
                <AllNetworksIcon />
              </div>
              <span>All chains</span>
            </div>
          </div>

          {getNetworks().map((chainId) => {
            const { name, icon } = getNetworkDetails(chainId);
            const isChecked = localSelection.includes(chainId);

            return (
              <div
                key={chainId}
                className={styles.option}
                onClick={() => toggleNetwork(chainId)}
              >
                <div className={styles.networkContent}>
                  <div className={styles.checkBoxWrapper}>
                    <Checkbox isChecked={isChecked} onChange={() => {}} />
                  </div>
                  <div className={styles.imageWrapper}>{icon}</div>
                  <span>{name}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.fixedSaveButton(0)}>
        <div className={styles.buttonWrapper(32)}>
          <Button variant="light" onClick={saveSelection}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
