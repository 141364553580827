export const NoPic = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13668_3276)">
        <rect width="40" height="40" rx="4" fill="#181818" />
        <path
          d="M10.9774 9C11.5711 9 12.1515 9.18433 12.6452 9.52967C13.1389 9.87501 13.5237 10.3659 13.7509 10.9401C13.9781 11.5144 14.0376 12.1463 13.9217 12.756C13.8059 13.3657 13.52 13.9257 13.1001 14.3652C12.6803 14.8047 12.1454 15.1041 11.563 15.2253C10.9807 15.3466 10.3771 15.2844 9.82855 15.0465C9.28 14.8086 8.81114 14.4058 8.48127 13.8889C8.1514 13.3721 7.97534 12.7645 7.97534 12.1429C7.97534 11.3093 8.29162 10.5099 8.85461 9.92052C9.41761 9.33112 10.1812 9 10.9774 9ZM34.8436 30.12C34.7198 30.3845 34.5275 30.6072 34.2887 30.7628C34.0498 30.9184 33.774 31.0006 33.4927 31H6.47432C6.1914 30.9992 5.91446 30.9146 5.67544 30.7561C5.43642 30.5977 5.24505 30.3717 5.1234 30.1043C5.01794 29.8491 4.97905 29.5692 5.01069 29.2931C5.04234 29.017 5.14338 28.7547 5.30352 28.5329L11.3076 20.6757C11.434 20.5096 11.5912 20.3721 11.7698 20.2714C11.9484 20.1707 12.1446 20.1089 12.3466 20.0898C12.5486 20.0708 12.7523 20.0947 12.9452 20.1603C13.1381 20.2259 13.3163 20.3317 13.4691 20.4714L15.9007 22.7029L21.7847 14.3429C21.9245 14.1477 22.1058 13.9893 22.3143 13.8802C22.5227 13.7711 22.7525 13.7143 22.9855 13.7143C23.2173 13.7124 23.4463 13.7668 23.6547 13.8731C23.863 13.9795 24.045 14.1349 24.1864 14.3271L34.6935 28.47C34.8629 28.7031 34.9667 28.981 34.9932 29.2726C35.0198 29.5641 34.9679 29.8576 34.8436 30.12Z"
          fill="#C1C1C8"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_13668_3276">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
