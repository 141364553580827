export const BscLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13670_3665)">
        <rect width="32" height="32" rx="16" fill="white" />
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#F3BA2F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1497 17.7015L22.5633 20.1081L16.0024 26.6621L9.44839 20.1081L11.8619 17.7015L16.0024 21.842L20.1497 17.7015ZM16.0024 13.5542L18.4506 16.0024L16.0024 18.4506L13.5611 16.0093V16.0024L13.9911 15.5724L14.1992 15.3643L16.0024 13.5542ZM7.74922 13.5888L10.1627 16.0024L7.74922 18.4089L5.33569 15.9954L7.74922 13.5888ZM24.2555 13.5888L26.669 16.0024L24.2555 18.4089L21.842 15.9954L24.2555 13.5888ZM16.0024 5.33569L22.5563 11.8897L20.1428 14.3032L16.0024 10.1558L11.8619 14.2962L9.44839 11.8897L16.0024 5.33569Z"
          fill="#131415"
        />
      </g>
      <defs>
        <clipPath id="clip0_13670_3665">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
