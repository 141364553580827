import { BACKEND_API } from "../constants/urls";
import { IHyperLiquidPriceHistory } from "../intefaces/IHyperLiquidPriceHistory";

export const fetchHyperLiquidPricesHistory = async (
  type: string,
  symbol: string,
  timeframe: string,
  token: string,
): Promise<IHyperLiquidPriceHistory[]> => {
  try {
    const response = await fetch(
      `${BACKEND_API}/hyperliquid_price_history/${type}/${symbol}?timeframe=${timeframe}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();
    return data.result || [];
  } catch (error) {
    return [];
  }
};
