import React from "react";
import { css } from "@emotion/css";
import { getNetworkDetails, getNetworks } from "../../networks";

const styles = {
  container: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 колонки */
    gap: 2px;
  `,
  iconWrapper: css`
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

export const AllNetworksIcon: React.FC = () => {
  // 🔹 Берём первые 4 сети (или меньше, если их мало)
  const icons = getNetworks()
    .slice(0, 4)
    .map((network) => {
      const { icon } = getNetworkDetails(network);
      return (
        <div key={network} className={styles.iconWrapper}>
          {icon}
        </div>
      );
    });

  return <div className={styles.container}>{icons}</div>;
};
