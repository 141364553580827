export const IotexLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#1C2437" />
      <path
        d="M16.5352 7.89099V11.8239L19.9579 9.85997L16.5352 7.89099Z"
        fill="#80F9B4"
      />
      <path
        d="M19.957 9.85986V13.7928L23.379 11.8238L19.957 9.85986Z"
        fill="#80F7B5"
        fill-opacity="0.9"
      />
      <path
        d="M16.5352 11.8237V15.7566L19.9579 13.7927L16.5352 11.8237ZM19.9579 13.7934V17.7256L23.3799 15.7566L19.9579 13.7934Z"
        fill="#80F7B5"
        fill-opacity="0.8"
      />
      <path
        d="M16.5352 15.7568V19.6898L19.9579 17.7258L16.5352 15.7568Z"
        fill="#80F7B5"
        fill-opacity="0.8"
      />
      <path
        d="M19.957 17.7258V21.6587L23.379 19.6898L19.957 17.7258Z"
        fill="#80F9B4"
      />
      <path
        d="M7.68896 11.6913V15.6242L11.1117 13.6552L7.68896 11.6913Z"
        fill="#80F7B5"
        fill-opacity="0.4"
      />
      <path
        d="M12.0063 13.1536V17.0865L15.4233 15.1225L12.0063 13.1536Z"
        fill="#80F7B5"
        fill-opacity="0.2"
      />
      <path
        d="M9.47021 15.6409V19.5738L12.8922 17.6048L9.47021 15.6409Z"
        fill="#80F7B5"
        fill-opacity="0.3"
      />
      <path
        d="M11.7251 18.6086V22.5416L15.142 20.5726L11.7251 18.6086Z"
        fill="#80F7B5"
        fill-opacity="0.9"
      />
      <path
        d="M16.5063 20.3015V24.2351L19.9233 22.2654L16.5063 20.3015Z"
        fill="#80F7B5"
        fill-opacity="0.7"
      />
      <path
        d="M12.5767 11.2113V15.1449L15.9936 13.181L12.5767 11.2113Z"
        fill="#80F7B5"
        fill-opacity="0.9"
      />
      <path
        d="M16.5345 7.89099V11.8239L13.1118 9.85997L16.5345 7.89099Z"
        fill="#80F7B5"
        fill-opacity="0.8"
      />
      <path
        d="M12.5758 9.56299V13.4952L9.15234 11.5262L12.5758 9.56299Z"
        fill="#80F7B5"
        fill-opacity="0.6"
      />
      <path
        d="M15.9994 11.5088V15.4424L12.5767 13.4727L15.9994 11.5088Z"
        fill="#80F7B5"
        fill-opacity="0.6"
      />
      <path
        d="M11.9916 13.1536V17.0865L8.56885 15.1225L11.9916 13.1536Z"
        fill="#80F7B5"
        fill-opacity="0.95"
      />
      <path
        d="M16.5341 15.7568V19.6898L13.1172 17.7258L16.5341 15.7568Z"
        fill="#80F7B5"
        fill-opacity="0.6"
      />
      <path
        d="M9.46959 16.6727V20.6056L6.04688 18.6359L9.46959 16.6727Z"
        fill="#80F7B5"
        fill-opacity="0.55"
      />
      <path
        d="M23.379 11.8237V15.7566L19.957 13.7927L23.379 11.8237Z"
        fill="#80F9B4"
      />
      <path
        d="M19.9579 13.7935V17.7257L16.5352 15.7567L19.9579 13.7935Z"
        fill="#80F7B5"
        fill-opacity="0.95"
      />
      <path
        d="M23.379 15.7568V19.6898L19.957 17.7258L23.379 15.7568Z"
        fill="#80F7B5"
        fill-opacity="0.9"
      />
      <path
        d="M19.9579 17.7258V21.6587L16.5352 19.6898L19.9579 17.7258Z"
        fill="#80F7B5"
        fill-opacity="0.7"
      />
      <path
        d="M15.7775 19.3646V23.2975L12.3555 21.3336L15.7775 19.3646Z"
        fill="#80F7B5"
        fill-opacity="0.4"
      />
      <path
        d="M19.9579 9.85986V13.7928L16.5352 11.8238L19.9579 9.85986Z"
        fill="#80F9B4"
      />
    </svg>
  );
};
