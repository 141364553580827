import { IHyperLiquidData } from "../intefaces/IHyperLiquidPosition";
import { BACKEND_API } from "../constants/urls";

export const GetEmptyValue = (walletName: string): IHyperLiquidData => {
  return {
    spot: {
      positions: [],
      totalBalance: 0,
      totalInPositions: 0,
      pnl: 0,
      roi: 0,
      volume: 0,
      freeBalance: 0,
      dealsCount: 0,
      profitDealsCount: 0,
    },
    perp: {
      positions: [],
      totalBalance: 0,
      totalInPositions: 0,
      freeBalance: 0,
      pnl: 0,
      roi: 0,
      volume: 0,
      dealsCount: 0,
      profitDealsCount: 0,
    },
    vault: {
      positions: [],
      totalBalance: 0,
      totalInPositions: 0,
      pnl: 0,
      roi: 0,
    },
    generalAnalytics: {
      totalBalance: 0,
      totalInPositions: 0,
      freeBalance: 0,
      pnl: 0,
      dealsCount: 0,
      profitDealsCount: 0,
    },
    walletName,
  };
};

export const DEFAULT_HL_STATS_VALUE = GetEmptyValue("");

const hyperLiquidTypeConversion = (
  data: any,
  walletName: string,
): IHyperLiquidData => {
  return {
    spot: {
      positions: data.spot.positions.map((pos: any) => ({
        coin: pos.coin,
        total: pos.total,
        totalValue: pos.totalValue,
        pnl: pos.pnl,
        roi: pos.roi,
        avgEntryPrice: pos.avgEntryPrice,
        currentPrice: pos.currentPrice,
      })),
      totalBalance: data.spot.totalBalance,
      totalInPositions: data.spot.totalInPositions,
      pnl: data.spot.pnl,
      roi: data.spot.roi,
      volume: data.spot.volume,
      freeBalance: data.spot.freeBalance,
      dealsCount: data.spot.dealsCount,
      profitDealsCount: data.spot.profitDealsCount,
    },
    perp: {
      positions: data.perp.positions.map((pos: any) => ({
        coin: pos.coin,
        leverage: pos.leverage,
        direction: pos.direction,
        size: pos.size,
        positionValue: pos.positionValue,
        entryPx: pos.entryPx,
        currentPrice: pos.currentPrice,
        liquidationPx: pos.liquidationPx,
        unrealizedPnl: pos.unrealizedPnl,
        pnl: pos.unrealizedPnl,
        roi: pos.roi,
      })),
      totalBalance: data.perp.totalBalance,
      totalInPositions: data.perp.totalInPositions,
      freeBalance: data.perp.freeBalance,
      pnl: data.perp.pnl,
      roi: data.perp.roi,
      volume: data.perp.volume,
      dealsCount: data.perp.dealsCount,
      profitDealsCount: data.perp.profitDealsCount,
    },
    vault: {
      positions: data.vault.positions.map((vault: any) => ({
        name: vault.name,
        vaultAddress: vault.vaultAddress,
        vaultEquity: vault.vaultEquity,
        pnl: vault.pnl,
        allTimePnl: vault.allTimePnl,
        daysFollowing: vault.daysFollowing,
        vaultEntryTime: vault.vaultEntryTime,
        lockupUntil: vault.lockupUntil,
        roi: vault.roi,
      })),
      totalBalance: data.vault.totalBalance,
      totalInPositions: data.vault.totalInPositions,
      pnl: data.vault.pnl,
      roi: data.vault.roi,
    },
    generalAnalytics: {
      totalBalance: data.generalAnalytics.totalBalance,
      totalInPositions: data.generalAnalytics.totalInPositions,
      freeBalance: data.generalAnalytics.freeBalance,
      pnl: data.generalAnalytics.pnl,
      dealsCount: data.generalAnalytics.dealsCount,
      profitDealsCount: data.generalAnalytics.profitDealsCount,
    },
    walletName,
  };
};

export const fetchHyperLiquidData = async (
  address: string,
  token: string,
  walletName: string,
): Promise<IHyperLiquidData> => {
  try {
    const response = await fetch(`${BACKEND_API}/hyperliquid_data/${address}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (!data.result) {
      return GetEmptyValue(walletName);
    }

    return hyperLiquidTypeConversion(data.result, walletName);
  } catch (error) {
    console.error("Error fetching HyperLiquid data:", error);
    return GetEmptyValue(walletName);
  }
};
