export const SolanaLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_13676_3286)" />
      <circle cx="16" cy="16" r="16" fill="url(#paint1_linear_13676_3286)" />
      <path
        d="M8.8488 20.4359C8.97753 20.3075 9.15453 20.2327 9.34226 20.2327H26.3664C26.6775 20.2327 26.8331 20.607 26.6132 20.8262L23.2502 24.1788C23.1214 24.3071 22.9444 24.3819 22.7567 24.3819H5.73253C5.42144 24.3819 5.26589 24.0077 5.4858 23.7884L8.8488 20.4359Z"
        fill="white"
      />
      <path
        d="M8.8488 7.9184C8.98289 7.79007 9.15989 7.71521 9.34226 7.71521H26.3664C26.6775 7.71521 26.8331 8.0895 26.6132 8.30873L23.2502 11.6613C23.1214 11.7896 22.9444 11.8645 22.7567 11.8645H5.73253C5.42144 11.8645 5.26589 11.4902 5.4858 11.271L8.8488 7.9184Z"
        fill="white"
      />
      <path
        d="M23.2502 14.137C23.1214 14.0087 22.9444 13.9338 22.7567 13.9338H5.73253C5.42144 13.9338 5.26589 14.3081 5.4858 14.5274L8.8488 17.8799C8.97753 18.0083 9.15453 18.0831 9.34226 18.0831H26.3664C26.6775 18.0831 26.8331 17.7088 26.6132 17.4896L23.2502 14.137Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13676_3286"
          x1="25.3333"
          y1="2.66667"
          x2="9.33333"
          y2="29.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC8A04" />
          <stop offset="0.453367" stopColor="#F72E1E" />
          <stop offset="0.692128" stopColor="#D70B3C" />
          <stop offset="1" stopColor="#7D0101" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13676_3286"
          x1="25.1475"
          y1="-73.8095"
          x2="-47.2533"
          y2="-46.7534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
