import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs } from "../uiKit/Tabs";
import { useUserWallets } from "../context/UserWalletContext";
import { WalletsContent } from "../WalletsContent";
import { IUserWallet } from "../../shared/intefaces/IUserWallet";
import {
  AUTHORIZATION,
  MY_WALLETS,
  PORTFOLIO,
  WATCHLIST,
} from "../../shared/constants/routes";
import { EmptyState } from "../EmptyState";

const portfolioPageStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    color: #fff;
  `,
  tabsContainer: css`
    background: #1c1c1c;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
  `,
  loadingMessage: css`
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-top: 40vh;
  `,
  emptyWrapper: css`
    background: #1c1c1c;
    border-radius: 0 0 24px 24px;
    padding: 24px 16px;
  `,
};

export const PortfolioPage: React.FC = () => {
  const {
    userWallets,
    loading,
    mainWallet,
    anotherWallet,
    setMainWallet,
    setAnotherWallet,
  } = useUserWallets();
  const navigate = useNavigate();
  const { type, tab, subtab } = useParams();

  const isMyWallets = type === MY_WALLETS;
  const activeTabFromPath = isMyWallets ? 0 : 1;

  const [myWallets, setMyWallets] = useState<IUserWallet[]>([]);
  const [anotherWallets, setAnotherWallets] = useState<IUserWallet[]>([]);

  useEffect(() => {
    if (!loading && userWallets.length === 0) {
      navigate(`/${AUTHORIZATION}`);
    }
  }, [loading, userWallets, navigate]);

  useEffect(() => {
    if (userWallets.length) {
      const myWalletsData = userWallets.filter((wallet) => wallet.isMyWallet);
      if (myWalletsData.length) {
        setMyWallets(mainWallet === null ? myWalletsData : [mainWallet]);
      }
      if (mainWallet === null && myWalletsData.length === 1) {
        setMainWallet(myWalletsData[0]);
      }
      const anotherWalletData = userWallets.filter(
        (wallet) => !wallet.isMyWallet,
      );
      if (anotherWalletData.length) {
        setAnotherWallets(
          anotherWallet === null
            ? anotherWalletData.slice(0, 1)
            : [anotherWallet],
        );
        if (anotherWallet === null) {
          setAnotherWallet(anotherWalletData[0]);
        }
      }
    }
  }, [userWallets, mainWallet, anotherWallet]);

  const handleTabChange = (newIndex: number) => {
    const newType = newIndex === 0 ? MY_WALLETS : WATCHLIST;
    navigate(`/${PORTFOLIO}/${newType}/${tab}/${subtab}`);
  };

  if (loading) {
    return (
      <div className={portfolioPageStyles.loadingMessage}>
        Loading wallets...
      </div>
    );
  }

  return (
    <div className={portfolioPageStyles.container}>
      <div className={portfolioPageStyles.tabsContainer}>
        <Tabs
          tabs={[{ label: "My wallets" }, { label: "Watchlist" }]}
          activeIndex={activeTabFromPath}
          onTabChange={handleTabChange}
        />
      </div>
      {activeTabFromPath === 0 &&
        (myWallets.length > 0 ? (
          <WalletsContent wallets={myWallets} selectedWallet={mainWallet} />
        ) : (
          <div className={portfolioPageStyles.emptyWrapper}>
            <EmptyState />
          </div>
        ))}

      {activeTabFromPath === 1 &&
        (anotherWallets.length > 0 ? (
          <WalletsContent
            wallets={anotherWallets}
            selectedWallet={anotherWallet}
          />
        ) : (
          <div className={portfolioPageStyles.emptyWrapper}>
            <EmptyState />
          </div>
        ))}
    </div>
  );
};
