export const DogeChainLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_13668_3467)" />
      <path
        d="M6.40847 8.0838C6.07339 9.7927 6.26885 12.1745 6.40847 13.1519C6.40847 13.1519 5.65454 15.3299 5.65454 17.2566C5.65454 21.3194 9.17233 27.8116 16.3771 27.8116C21.7802 27.8116 24.6702 23.0088 25.3823 20.5655C25.7453 19.9372 26.4294 18.1529 26.2619 16.0419C26.0524 13.4032 23.8744 11.644 22.7854 10.5131C21.6964 9.38223 22.6598 5.6126 21.4451 4.98433C20.4734 4.48171 18.4713 6.4503 17.5917 7.49742C17.1868 7.39968 16.1928 7.20422 15.4556 7.20422C14.5341 7.20422 14.6146 7.45353 13.6127 7.37176C11.5603 7.20424 10.4713 3.79695 8.46082 4.14662C7.49748 4.31418 6.82731 5.94767 6.40847 8.0838Z"
        fill="url(#paint1_linear_13668_3467)"
      />
      <path
        d="M8.58652 15.6647C6.94463 16.0668 6.29681 18.7223 6.24097 19.9788C7.20432 24.1673 10.5132 27.6437 17.0054 27.6856C22.1991 27.7191 24.7261 23.1202 25.3404 20.8165C25.7034 20.3697 26.4378 18.9485 26.4713 16.8375C26.5048 14.7265 25.0333 12.8305 24.2933 12.1464C24.0839 13.3331 23.7488 15.7903 22.9949 15.7903C22.1572 15.7903 22.6598 15.5809 20.8169 15.5809C18.7628 15.5809 18.8483 16.4605 17.5918 17.0469C16.3352 17.6333 15.6651 17.5495 14.8274 17.5495C13.9897 17.5495 13.6608 16.7499 12.2724 16.0835C11.2253 15.5809 10.3457 15.2339 8.58652 15.6647Z"
        fill="white"
      />
      <path
        d="M14.7017 25.4238C12.3227 25.6248 8.7122 23.0503 7.20435 21.7379C7.66508 24.1254 11.1834 27.1411 14.5342 27.8112C17.885 28.4814 21.4452 26.0939 22.618 25.4238C23.5562 24.8877 24.6843 22.0451 25.131 20.6908C24.2654 21.5704 22.3164 23.3798 21.4452 23.5808C20.3562 23.8322 19.2672 23.4133 19.2672 23.4133C18.1782 24.4185 17.6756 25.1725 14.7017 25.4238Z"
        fill="#F8ECE0"
      />
      <path
        d="M16.6283 11.1411C16.6283 10.7222 15.8325 10.2196 15.4136 10.2196C14.8691 10.2196 14.199 10.8898 14.199 11.4343C14.199 11.8531 15.0786 11.8531 15.4974 11.8531C15.9163 11.8531 16.6283 11.5599 16.6283 11.1411Z"
        fill="#FEE0A0"
      />
      <path
        d="M20.3562 9.92639C20.063 9.92639 19.7698 10.1777 19.7698 10.6384C19.7698 11.1411 20.3562 11.3505 20.5237 11.3505C20.6912 11.3505 21.3614 11.3505 21.3614 10.806C21.3614 10.2615 20.6494 9.92639 20.3562 9.92639Z"
        fill="#FEE0A0"
      />
      <path
        d="M6.40847 8.0838C6.07339 9.7927 6.26885 12.1745 6.40847 13.1519C6.40847 13.1519 5.65454 15.3299 5.65454 17.2566C5.65454 21.3194 9.17233 27.8116 16.3771 27.8116C21.7802 27.8116 24.6702 23.0088 25.3823 20.5655C25.7453 19.9372 26.4294 18.1529 26.2619 16.0419C26.0524 13.4032 23.8744 11.644 22.7854 10.5131C21.6964 9.38223 22.6598 5.6126 21.4451 4.98433C20.4734 4.48171 18.4713 6.4503 17.5917 7.49742C17.1868 7.39968 16.1928 7.20422 15.4556 7.20422C14.5341 7.20422 14.6146 7.45353 13.6127 7.37176C11.5603 7.20424 10.4713 3.79695 8.46082 4.14662C7.49748 4.31418 6.82731 5.94767 6.40847 8.0838Z"
        stroke="black"
        strokeWidth="0.376963"
      />
      <path
        d="M8.16765 14.1987L8.04199 13.3192L27.309 11.0155L27.3928 11.9789L26.5132 12.0207L26.5551 12.9841L25.7174 13.0679L25.8012 13.9893L24.8797 14.0731L24.9635 15.0783L21.864 15.3715L21.7802 14.4919L20.775 14.5338L20.6912 13.6542L19.8116 13.738L19.686 12.8584L17.843 13.0679L17.9268 13.9474L17.0053 14.0731L17.0891 14.9946L16.1676 15.0783L16.2514 16.0836L13.2357 16.4186L13.1519 15.4972L12.1886 15.5809L12.1048 14.6595L11.1834 14.7433L11.0577 13.8637L8.16765 14.1987Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7069 17.2983C23.7488 17.9266 22.6598 19.5182 21.9896 19.5601C21.0681 19.6857 19.5603 18.513 19.4346 17.5915C19.309 16.67 20.5236 16.0417 21.6545 16.0417C22.7854 16.0417 23.665 16.67 23.7069 17.2983ZM20.5229 21.9053C20.9991 21.9849 21.8083 22.0359 22.3247 21.8638C22.8921 21.6746 23.2693 20.964 23.3933 20.5899C23.7525 20.4956 24.146 20.3109 24.4326 19.8941C24.6742 19.5427 24.8407 19.1949 24.8885 18.8068C24.9364 18.4176 24.862 18.0113 24.6629 17.5468C24.6151 17.4351 24.4858 17.3834 24.3742 17.4313C24.2626 17.4791 24.2109 17.6084 24.2587 17.72C24.4366 18.135 24.4879 18.4617 24.452 18.7531C24.416 19.0457 24.2893 19.3262 24.0702 19.6449C23.8412 19.978 23.5162 20.1156 23.1827 20.1881C23.0427 20.2186 22.908 20.2363 22.7775 20.2535L22.696 20.2643C22.5549 20.2834 22.3907 20.3074 22.2683 20.3686L22.2401 20.3827L22.217 20.4042C21.6386 20.9412 20.8964 21.5601 19.686 21.5601C18.5174 21.5601 17.9473 20.6778 17.7976 20.2787C17.7551 20.1653 17.675 20.0724 17.5946 19.9998C17.5122 19.9253 17.4152 19.8596 17.3193 19.8036C17.1277 19.6919 16.9185 19.6065 16.7817 19.5609C16.6665 19.5225 16.5419 19.5848 16.5035 19.7C16.4651 19.8152 16.5274 19.9397 16.6426 19.9781C16.7571 20.0163 16.9375 20.0901 17.0977 20.1835C17.1777 20.2302 17.2472 20.2786 17.2998 20.3262C17.3545 20.3755 17.3781 20.4124 17.3858 20.4331C17.568 20.919 18.2576 21.9999 19.686 21.9999C19.9897 21.9999 20.2677 21.9652 20.5229 21.9053ZM19.5833 23.1789C20.032 23.2902 20.6427 23.4417 21.11 23.3716C21.9477 23.2459 22.576 22.9109 22.953 22.5339C23.1688 22.3181 23.3265 22.0336 23.4394 21.8298C23.5237 21.6777 23.5831 21.5705 23.6231 21.5705C23.8326 21.5705 23.5854 22.6214 23.2462 22.9946C22.8273 23.4554 22.0734 24.0836 21.1519 24.0418C20.2305 23.9999 18.9739 23.5391 18.9739 23.1203C18.9739 23.0277 19.2277 23.0907 19.5833 23.1789Z"
        fill="black"
      />
      <path
        d="M9.71728 9.96828C9.44922 10.1358 8.82374 10.5686 8.5445 10.7641C8.4747 10.4151 8.31833 9.48242 8.25131 8.5442C8.18429 7.60598 8.41885 6.28242 8.5445 5.73792C8.71204 6.12884 9.13927 7.06985 9.50785 7.7065C9.87644 8.34315 10.6108 9.11662 10.9319 9.42378C10.6387 9.53547 9.98534 9.80074 9.71728 9.96828Z"
        fill="black"
      />
      <rect
        x="15.1624"
        y="14.2408"
        width="0.921466"
        height="0.921466"
        transform="rotate(-5.23823 15.1624 14.2408)"
        fill="white"
      />
      <rect
        x="16"
        y="13.2356"
        width="0.921466"
        height="0.921466"
        transform="rotate(-5.23822 16 13.2356)"
        fill="white"
      />
      <rect
        x="23.8745"
        y="13.2356"
        width="0.921465"
        height="0.921466"
        transform="rotate(-5.23823 23.8745 13.2356)"
        fill="white"
      />
      <rect
        x="24.7122"
        y="12.2305"
        width="0.921465"
        height="0.921466"
        transform="rotate(-5.23823 24.7122 12.2305)"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13668_3467"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD38FF" />
          <stop offset="1" stopColor="#7872F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13668_3467"
          x1="15.9712"
          y1="4.12146"
          x2="15.9712"
          y2="27.8116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAF276" />
          <stop offset="0.516698" stopColor="#F0AD2F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
