export const EvmosLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13668_3464)">
        <circle cx="16" cy="16" r="16" fill="#2D2925" />
        <path
          d="M13.6316 9.77633C9.94469 11.1975 9.60694 14.837 8.54974 16.5367C7.48002 18.2566 5.02934 19.2056 5.36446 20.0821C5.69958 20.9585 8.15318 20.0172 10.0943 20.5764C12.0125 21.129 14.6889 23.607 18.3758 22.1858C20.254 21.462 21.6141 19.9719 22.2395 18.2069C22.3067 18.0175 22.181 17.8149 21.9814 17.7962C21.8575 17.7845 21.7384 17.8497 21.6825 17.9614C21.1168 19.0927 20.1463 20.0245 18.8763 20.5139C16.7796 21.322 14.4855 20.6875 13.0767 19.0962C12.7567 18.7349 12.483 18.3236 12.267 17.869C12.2076 17.7438 12.1511 17.6167 12.101 17.4851C12.0506 17.3536 12.0078 17.2211 11.9685 17.0881C13.0767 16.5695 14.3555 16.0201 15.8052 15.4614C17.2265 14.9136 18.5203 14.4692 19.672 14.1137C20.4512 13.8734 21.1654 13.6734 21.8102 13.51C21.857 13.4983 21.9029 13.4866 21.9486 13.4752C22.0461 13.4509 22.1463 13.5033 22.1823 13.5974L22.183 13.5991C22.2042 13.655 22.2226 13.7111 22.2424 13.7673C22.3699 14.1295 22.4655 14.4955 22.5285 14.8624C22.5562 15.0229 22.7309 15.1103 22.8742 15.0331C23.4036 14.7478 23.8878 14.4695 24.3203 14.2017C25.9326 13.2048 26.8264 12.3593 26.6429 11.8801C26.4598 11.4006 25.2323 11.3732 23.3697 11.7152C22.7778 11.824 22.1216 11.9701 21.4144 12.1511C21.292 12.1824 21.1682 12.2148 21.0431 12.2482C20.4479 12.4066 19.8202 12.5882 19.1666 12.7914C17.951 13.1694 16.6469 13.6222 15.3005 14.1412C14.041 14.6268 12.8473 15.1302 11.7525 15.6319C11.7391 13.5027 13.0211 11.4898 15.1177 10.6817C16.3874 10.1923 17.7297 10.2324 18.9043 10.6934C19.0201 10.739 19.152 10.7075 19.2368 10.6154C19.3724 10.4677 19.3308 10.2327 19.1546 10.1368C17.5137 9.24599 15.5099 9.05246 13.6316 9.77633Z"
          fill="#FAF1E4"
        />
      </g>
      <defs>
        <clipPath id="clip0_13668_3464">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
